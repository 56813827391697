import { ECEasyFormFieldType, FieldTypes } from 'app/components/ECForm';
import { EditForm2 } from '../EditForm2';
import { ECBox } from 'app/components/ECBox';
import { ECIconButton } from 'app/components/ECIconButton';
import { Close, Delete, Download, Edit, FileCopy } from '@mui/icons-material';
import { ECToggleButton } from 'app/components/ECToggleButton';
import { ECTypography } from 'app/components/ECTypography';
import { PDFObject } from 'react-pdfobject';
import { ECModal } from 'app/components/ECModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Attachment, AttachmentCategory } from 'types/Attachment';
import { ECImage } from 'app/components/ECImage';
import {
  useDeleteAttachmentMutation,
  useGetAttachmentDocumentTypesQuery,
  useLazyDownloadAttachmentQuery,
  useUpdateAttachmentMutation,
} from 'services/attachmentApi';
import moment from 'moment';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import { useCustomerUser } from 'app/hooks';
import * as _ from 'lodash';
import { ECAttachmentDeleteAlert } from 'app/components/ECAttachmentDeleteAlert';

interface Props {
  moduleName: string;
  moduleId: string;
  idAttributeName?: string;
  attachment: Attachment;
  isOpen?: boolean;
  isCustomerRequiredDocs?: boolean;
  isSpRequiredDocs?: boolean;
  isCustomerOwnRequiredDocs?: boolean;
  fieldsValueDocTypeIsReqDocAndExpires?: boolean;
  deleteAlertTitle?: string;
  deleteAlertText?: string;
  onCloseModal: () => void;
}

export const ECAttachmentDetailsModal = ({
  moduleName,
  moduleId,
  idAttributeName = 'id',
  attachment,
  isOpen,
  isCustomerRequiredDocs = false,
  isSpRequiredDocs = false,
  isCustomerOwnRequiredDocs = false,
  fieldsValueDocTypeIsReqDocAndExpires = false,
  deleteAlertTitle,
  deleteAlertText,
  onCloseModal,
}: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldsValues, setFieldsValues] = useState<any>({});
  const [deletingAttachmentId, setDeletingAttachmentId] = useState<any>(null);

  const isCustomer = useCustomerUser();

  const handleFieldChange = (fieldName: string) => (value: any) => {
    setFieldsValues(prevValues => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const { data: documentTypes } = useGetAttachmentDocumentTypesQuery(
    moduleName,
    { skip: !moduleName },
  );

  // moduleData is only used to get the module ID
  // so this query is only necessary if idAttributeName is provided (since it's going to look for another field different than the ID)
  const { data: moduleData } = useGetModuleDetailsByIdQuery(
    {
      id: moduleId || '',
      moduleName: `${
        isCustomer || moduleName === 'spcustomer' || moduleName === 'user'
          ? ''
          : 'sp/'
      }${moduleName || ''}`,
    },
    { skip: !moduleId || idAttributeName === 'id' },
  );

  const [
    doDeleteAttachment,
    {
      data: deleteAttachmentResponse,
      isError: isDeleteAttachmentError,
      isSuccess: isDeleteAttachmentSuccess,
      reset: resetDeleteAttachment,
    },
  ] = useDeleteAttachmentMutation();

  const [
    doUpdateAttachment,
    {
      isError: isUpdateAttachmentError,
      isSuccess: isUpdateAttachmentSuccess,
      isLoading: isUpdateAttachmentLoading,
      error: updateAttachmentError,
      reset: resetUpdateAttachment,
    },
  ] = useUpdateAttachmentMutation();

  const isAssetDocumentTypeSelected = useMemo(() => {
    if (!fieldsValues.documentCoreType) {
      return attachment?.isRestricted;
    }
    const assetDocumentTypeIds = documentTypes
      ?.filter(type => type.private === 1)
      ?.map(assetDocumentType => assetDocumentType.id);

    return (
      assetDocumentTypeIds?.includes(fieldsValues.documentCoreType) ?? false
    );
  }, [documentTypes, fieldsValues, attachment?.isRestricted]);

  const isExpiresOnFieldVisible = useMemo(() => {
    // the expiresOn field is visible if the selected document type has expires === 1, this is set directly in the database.
    if (!fieldsValues.documentCoreType) {
      return attachment?.expiresOn;
    }
    const expiresOnDocumentTypeIds = documentTypes
      ?.filter(type => type.expires === 1)
      ?.map(expiresOnDocumentType => expiresOnDocumentType.id);

    return (
      expiresOnDocumentTypeIds?.includes(fieldsValues.documentCoreType) ?? false
    );
  }, [documentTypes, fieldsValues, attachment?.expiresOn]);

  const toggleEditMode = () => setIsEditMode(prevIsEdit => !prevIsEdit);

  useEffect(() => {
    setFieldsValues(prev => ({
      fileName: attachment?.fileName,
      documentCoreType:
        attachment?.documentCoreType?.id ||
        (isEditMode && documentTypes?.find(docTp => docTp.default === 1)?.id) ||
        documentTypes?.[0]?.id ||
        '',
      description: attachment?.description,
      uploadedBy: attachment?.createdByUserEmail,
      uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
      isRestricted: attachment?.isRestricted,
      originalName: attachment?.originalName?.split('.')[0],
      name: attachment?.originalName?.split('.')[0],
      fileType: attachment?.originalName?.split('.')?.pop(),
      expires: attachment?.expires,
      moduleId: attachment?.moduleId,
    }));
  }, [attachment, isEditMode]);

  const [triggerDownload, { isLoading: isLoadingDownloadAttachment }] =
    useLazyDownloadAttachmentQuery();

  const handleDownloadAttachment = useCallback(() => {
    if (attachment?.id) {
      triggerDownload({
        module: isCustomerOwnRequiredDocs ? 'document-required' : moduleName,
        moduleId: attachment.id,
        category: AttachmentCategory.Photo,
        fileName: attachment.originalName || attachment.fileName,
      });
    }
  }, [
    triggerDownload,
    moduleName,
    attachment?.id,
    attachment?.fileName,
    attachment?.originalName,
    isCustomerOwnRequiredDocs,
  ]);

  const fields = useMemo(() => {
    return [
      {
        fieldName: 'fileName',
        type: FieldTypes.Text,
        label: 'File Name',
        placeholder: 'File Name',
        variant: 'standard',
        required: false,
        visible: false,
        readOnly: !isEditMode,
        value: fieldsValues?.fileName,
        onChange: (output: ECEasyFormFieldType[], value: string) => {
          handleFieldChange('fileName')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'Uploaded by',
        type: FieldTypes.Text,
        label: 'Uploaded by',
        placeholder: 'Uploaded by',
        variant: 'standard',
        visible: !!attachment?.id,
        required: false,
        readOnly: true,
        value: fieldsValues?.uploadedBy,
        maxLength: 200,
        overrideRowValue: true,
      },
      {
        fieldName: 'Upload Date',
        type: FieldTypes.Text,
        label: 'Upload Date',
        placeholder: 'Upload Date',
        variant: 'standard',
        visible: !!attachment?.id,
        required: false,
        readOnly: true,
        value: fieldsValues?.uploadDate,
        maxLength: 200,
        overrideRowValue: true,
      },
      {
        fieldName: 'documentCoreType',
        type: !isEditMode ? FieldTypes.Text : FieldTypes.Select,
        label: 'Document Type',
        placeholder: 'Document Type',
        variant: 'standard',
        required: true,
        options: documentTypes?.map(type => ({
          id: type?.id,
          label: type?.name,
        })),
        optionValues: documentTypes?.map(type => type?.id),
        readOnly: !isEditMode,
        value: fieldsValues?.documentCoreType ?? attachment?.documentCoreType,
        onChange: (output: ECEasyFormFieldType[], value) => {
          handleFieldChange('documentCoreType')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'expiresOn',
        type: FieldTypes.DatePicker,
        label: 'Expiration Date',
        placeholder: 'Expiration Date',
        variant: 'standard',
        required: isSpRequiredDocs && fieldsValueDocTypeIsReqDocAndExpires,
        readOnly: !isEditMode,
        // TODO: It should be visible only if document type is "Worker's Comp COI", "Certificate of Insurance (COI)" or "Permit"
        // See https://ecotrak.atlassian.net/browse/DEV-10551
        visible:
          !isCustomerRequiredDocs &&
          ((isSpRequiredDocs && fieldsValueDocTypeIsReqDocAndExpires) ||
            (isExpiresOnFieldVisible && !fieldsValueDocTypeIsReqDocAndExpires)),
        value: fieldsValues?.expiresOn ?? attachment?.expiresOn,
        onChange: (output: ECEasyFormFieldType[], value) => {
          handleFieldChange('expiresOn')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'description',
        type: FieldTypes.MultiLineText,
        label: 'Description',
        placeholder: 'Description',
        variant: 'standard',
        visible: true,
        required: false,
        readOnly: !isEditMode,
        value: fieldsValues?.description,
        maxLength: 200,
        onChange: (output: ECEasyFormFieldType[], value: string) => {
          handleFieldChange('description')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'isRestricted',
        type: FieldTypes.Checkbox,
        options: ['Restricted Document'],
        disabled: !isEditMode,
        visible:
          isAssetDocumentTypeSelected &&
          !isCustomerRequiredDocs &&
          !isSpRequiredDocs,
        value: fieldsValues.isRestricted ? ['Restricted Document'] : [],
        onChange: (output, value) => {
          handleFieldChange('isRestricted')(value.length > 0);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'isReqDocExpirationDate',
        type: FieldTypes.Checkbox,
        options: ['Require Doc Expiration Date'],
        disabled: !isEditMode,
        visible: isCustomerRequiredDocs && isExpiresOnFieldVisible,
        value: fieldsValues.expires ? ['Require Doc Expiration Date'] : [],
        onChange: (output, value) => {
          handleFieldChange('expires')(value.length > 0);
        },
        overrideRowValue: true,
      },
    ];
  }, [
    isEditMode,
    fieldsValues,
    attachment,
    isAssetDocumentTypeSelected,
    isCustomerRequiredDocs,
    isSpRequiredDocs,
    isExpiresOnFieldVisible,
    fieldsValueDocTypeIsReqDocAndExpires,
    documentTypes,
  ]);

  const formConfig = useMemo(() => {
    return {
      cols: 1,
      submitTitle: 'Save',
      variant: '',
      title: '',
      subtitle: '',
      name: 'Attachment',
    };
  }, []);

  const finalModuleId =
    idAttributeName !== 'id'
      ? _.get(moduleData, idAttributeName, '')
      : moduleId;

  const useUpdateAttachment = useCallback(() => {
    const doUpdate = async data => {
      const updatedData = {
        module: moduleName,
        moduleId: finalModuleId,
        // I'm checking attachment.moduleId because required docs use the req doc ID to update not the attach
        attachmentId: isCustomerOwnRequiredDocs
          ? attachment?.moduleId
          : data?.id ?? 0,
        isRestricted: isAssetDocumentTypeSelected
          ? data.isRestricted?.length
          : 0,
        description: data.description,
        documentCoreTypeId: data.documentCoreType,
        expiresOn: data?.expiresOn,
        isRequiredDoc: isCustomerOwnRequiredDocs,
        isReqDocExpirationDate:
          data.isReqDocExpirationDate && data.isReqDocExpirationDate.length
            ? true
            : false,
      };

      doUpdateAttachment(updatedData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateAttachmentError,
        error: updateAttachmentError,
        isLoading: isUpdateAttachmentLoading,
        isSuccess: isUpdateAttachmentSuccess,
        reset: resetUpdateAttachment,
      },
    ];
  }, [
    idAttributeName,
    moduleData,
    moduleName,
    isUpdateAttachmentError,
    updateAttachmentError,
    isUpdateAttachmentLoading,
    isUpdateAttachmentSuccess,
    resetUpdateAttachment,
    doUpdateAttachment,
    isAssetDocumentTypeSelected,
    isCustomerOwnRequiredDocs,
    attachment?.moduleId,
    finalModuleId,
  ]);

  const handleDelete = (attachmentId?: number) => {
    const id = attachmentId;

    if (!id) return;

    doDeleteAttachment({
      module: moduleName,
      moduleId: finalModuleId,
      attachmentId: id,
      isCustomerOwnRequiredDocs,
    });
  };

  const handleClose = () => {
    setIsEditMode(false);
    onCloseModal?.();
  };

  const handleOnDelete = () => {
    handleDelete(deletingAttachmentId);
    setIsEditMode(false);
    onCloseModal?.();
  };

  return (
    <>
      <ECAttachmentDeleteAlert
        open={!!deletingAttachmentId}
        onDelete={handleOnDelete}
        title={deleteAlertTitle!}
        text={deleteAlertText!}
        handleCloseModal={() => setDeletingAttachmentId(null)}
      />
      <ECModal
        isOpen={isOpen}
        onClose={handleClose}
        sx={{
          maxWidth: '90vw',
          maxHeight: '90vh',
          width: '75vw',
          height: '90vh',
          overflow: 'hidden',
        }}
      >
        <ECBox display="flex" justifyContent="space-between" height="100%">
          <ECBox
            display="flex"
            sx={{
              width: 'calc(100% - 350px)',
              maxHeight: '100%',
              height: '100%',
            }}
            alignItems="center"
          >
            {attachment?.attachmentType === AttachmentCategory.Document && (
              <ECBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={2}
                sx={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {attachment.fileName?.split('.')?.pop()?.toLowerCase() ===
                  'pdf' ||
                attachment.originalName?.split('.')?.pop()?.toLowerCase() ===
                  'pdf' ? (
                  <>
                    <ECTypography
                      textAlign="center"
                      variant="body1"
                      fontWeight="600"
                    >
                      {attachment.originalName}
                    </ECTypography>
                    <PDFObject
                      url={attachment?.url + '#toolbar=0'}
                      containerProps={{
                        style: {
                          width: '100%',
                          height: 'calc(100% - 40px)',
                        },
                      }}
                      pdfOpenParams={{
                        zoom: 'scale,left,top',
                      }}
                    />
                  </>
                ) : (
                  <>
                    <FileCopy
                      sx={theme => ({
                        color: theme.palette.action.active,
                      })}
                    />

                    <ECTypography textAlign="center" variant="body1">
                      {attachment.originalName?.split('.')[0]}
                    </ECTypography>

                    <ECTypography textAlign="center" variant="body2">
                      {attachment.originalName?.split('.')?.pop()}
                    </ECTypography>
                  </>
                )}
              </ECBox>
            )}
            {attachment?.attachmentType === AttachmentCategory.Photo && (
              <ECImage
                sx={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                alt="attachment"
                src={attachment?.url}
              />
            )}
          </ECBox>

          <ECBox
            sx={{
              width: '313px',
              maxHeight: '90vh',
              height: '90vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <ECBox display="flex" justifyContent="flex-end" p={2} gap={1}>
              {attachment?.canEdit && (
                <ECToggleButton
                  value="edit"
                  selected={isEditMode}
                  onChange={toggleEditMode}
                >
                  <Edit />
                </ECToggleButton>
              )}
              {attachment && isEditMode && (
                <ECIconButton
                  size="medium"
                  withBorder
                  onClick={() =>
                    setDeletingAttachmentId(
                      isCustomerOwnRequiredDocs
                        ? attachment?.moduleId
                        : attachment?.id,
                    )
                  }
                  type="error"
                >
                  <Delete />
                </ECIconButton>
              )}

              {!isEditMode && (
                <ECIconButton
                  size="medium"
                  withBorder
                  onClick={handleDownloadAttachment}
                  isLoading={isLoadingDownloadAttachment}
                >
                  <Download
                    sx={theme => ({ color: theme.palette.grey[600] })}
                  />
                </ECIconButton>
              )}

              <ECIconButton size="medium" withBorder onClick={handleClose}>
                <Close sx={theme => ({ color: theme.palette.grey[600] })} />
              </ECIconButton>
            </ECBox>

            <ECBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '4vh',
              }}
            >
              <EditForm2
                row={attachment}
                formConfig={formConfig}
                formFields={fields}
                detailsConfig={formConfig}
                detailsFields={fields}
                useUpdateMutation={useUpdateAttachment}
                onClose={handleClose}
                stickyFooter={false}
                hideToolBar
                showEditButton={!!attachment}
                onlyEdit={isEditMode}
              />
            </ECBox>
          </ECBox>
        </ECBox>
      </ECModal>
    </>
  );
};
