import {
  ECDynamicPageTemplate,
  ECEasyFormFieldType,
  FieldTypes,
} from 'app/components';
import {
  PreventiveMaintenanceWithModule,
  useAddPreventiveMaintenanceMutation,
  useLazyGetPMNextServiceDateQuery,
  useLazyGetPreventiveMaintenanceListQuery,
} from 'services/preventiveMaintenanceApi';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { loadPageScopes } from 'utils/pageScopes';
import { useMatch, useNavigate } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterEndpoint } from 'store/slice/page';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetBranchesListQuery } from 'services/branchApi';
import {
  useLazyGetTradesByAssetGroupIdQuery,
  useLazyGetTradeAssignmentByTradeIdQuery,
} from 'services/tradeApi';
import { useGetCompanyServiceProvidersDropdownListQuery } from 'services/serviceProviderApi';
import moment from 'moment';
import {
  SelectionType,
  ServiceFrequencyValueType,
} from 'app/components/ECServiceFrequency';
import _ from 'lodash';
import { setSnackbar } from 'store/slice/page';

const fancyFormElementsDetails = require('./config/fancy_form_config_details.json');
const fancyFormElementsCreate = require('./config/fancy_form_config_create.json');

export const PreventiveMaintenancePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [formFields, setFormFields] = useState(fancyFormElementsCreate.fields);
  const [selectedAssetGroupId, setSelectedAssetGroupId] = useState<number>();

  const navigate = useNavigate();
  const match = useMatch('/panel/*');

  const [
    addPreventiveMaintenance,
    {
      data: createData,
      isError: isCreateError,
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: isCreateSuccess,
      reset: resetCreate,
    },
  ] = useAddPreventiveMaintenanceMutation();

  const [triggerTrades, tradesResult] = useLazyGetTradesByAssetGroupIdQuery({});

  const {
    data: trades,
    isSuccess: isSuccessTrades,
    isFetching: isFetchingTrades,
  } = tradesResult;

  const [trigger, result] = useLazyGetTradeAssignmentByTradeIdQuery({});

  const {
    data: tradeAssigmnents,
    isSuccess: isSuccessTradeAssigments,
    isFetching: isFetchingTradeAssigments,
  } = result;

  const {
    data: allServiceProviders,
    isSuccess: isSuccessAllSPs,
    isFetching: isFetchingAllSPs,
  } = useGetCompanyServiceProvidersDropdownListQuery({
    t: 1000,
    st: 1,
    ob: 'cmpny.name',
  });

  useEffect(() => {
    if (isSuccessTrades && !isFetchingTrades) {
      const tradesField = formFields.find(field => field.fieldName === 'trade');

      if (!!tradesField?.value) {
        tradesField.value = null;
      }

      tradesField.options =
        trades?.data.map(d => ({ label: d.name, id: d.id })) || [];
      tradesField.optionValues = trades?.data.map(d => d.id) || [];

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'trade',
      );
      formFields[fieldIndex] = tradesField;

      setFormFields([...formFields]);
    }
  }, [trades, selectedAssetGroupId, isSuccessTrades, isFetchingTrades]);

  useEffect(() => {
    if (
      isSuccessTradeAssigments &&
      !isFetchingTradeAssigments &&
      allServiceProviders &&
      !isFetchingAllSPs
    ) {
      const servideProvidersField = formFields.find(
        field => field.fieldName === 'serviceProvider',
      );

      const assignedTradeSps = Array.from(
        new Map(
          tradeAssigmnents?.data
            .flatMap(d => d.sp)
            .map(sp => [
              sp['id'],
              {
                label: sp['name'],
                id: sp['id'],
                name: sp['name'],
                group: 'Assigned to this trade',
              },
            ]),
        ).values(),
      );

      const allServiceProvidersMapped = allServiceProviders?.data.map(sp => ({
        label: sp.name,
        name: sp.name,
        id: sp.id,
        group: 'All service providers',
      }));

      const uniqueServiceProviders = _.uniqBy(
        [...assignedTradeSps, ...allServiceProvidersMapped],
        'id',
      );

      if (uniqueServiceProviders?.length) {
        servideProvidersField.options = uniqueServiceProviders;

        servideProvidersField.optionValues = uniqueServiceProviders.map(
          sp => sp.id,
        );
      }

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'serviceProvider',
      );
      formFields[fieldIndex] = servideProvidersField;

      setFormFields([...formFields]);
    }
  }, [
    tradeAssigmnents,
    isSuccessTradeAssigments,
    isFetchingTradeAssigments,
    allServiceProviders,
    isSuccessAllSPs,
    isFetchingAllSPs,
  ]);

  const { data: branches, isSuccess: isSuccessBranches } =
    useGetBranchesListQuery({
      st: 1,
      t: 10000,
    });

  const locationOptions = useMemo(() => {
    return (
      _.orderBy(branches?.data, 'name')
        ?.filter(branch => branch?.status !== 0)
        ?.map(branch => ({
          id: branch?.id,
          orgId: branch?.organizationId,
          onHold: branch?.onHold,
          label: branch?.name,
        })) || []
    );
  }, [branches?.data]);

  useEffect(() => {
    const locationField = formFields.find(
      field => field.fieldName === 'locations',
    );

    if (locationField) {
      locationField.options = locationOptions;
      locationField.optionValues = locationOptions.map(
        location => location.orgId,
      );

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'locations',
      );

      formFields[fieldIndex] = locationField;
      setFormFields([...formFields]);
    }
  }, [branches, isSuccessBranches, locationOptions]);

  const [getPMNextServiceDate] = useLazyGetPMNextServiceDateQuery();

  const updateNextServiceDate = useCallback(
    async (startDate, serviceFrequency, createInAdvance, field) => {
      field.disabled = true;

      const { data: nextServiceDate } = await getPMNextServiceDate({
        startDate,
        createInAdvance,
        ...serviceFrequency,
      });

      field.disabled = false;
      field.value = nextServiceDate;

      return nextServiceDate;
    },
    [getPMNextServiceDate],
  );

  const handleOutputChange = async (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (
      fieldName === 'name' ||
      fieldName === 'category' ||
      fieldName === 'priority' ||
      fieldName === 'nte' ||
      fieldName === 'serviceProvider' ||
      fieldName === 'description' ||
      fieldName === 'files'
    ) {
      return;
    }

    const assetGroupField = output.find(
      field => field.fieldName === 'assetGroup',
    ) as any;

    const newAssetGroup = assetGroupField?.value;

    if (fieldName === 'assetGroup') {
      triggerTrades({ id: newAssetGroup?.id });
      setSelectedAssetGroupId(newAssetGroup?.id);
    }

    const assetGroupRefrigerantAlertField = output.find(
      field => field.fieldName === 'assetGroupRefrigerantAlert',
    );

    if (assetGroupRefrigerantAlertField) {
      assetGroupRefrigerantAlertField.visible = false; //newAssetGroup?.refrigerantTracking === 1;
    }
    const tradeField = output.find(field => field.fieldName === 'trade') as any;
    const newTradeId = tradeField?.value?.id;

    if (newTradeId) {
      trigger({
        id: newTradeId,
        t: 0,
      });
    }

    if (!assetGroupField?.value) {
      tradeField.value = null;
    }

    if (
      fieldName === 'serviceFrequency' ||
      fieldName === 'startDate' ||
      fieldName === 'createInAdvance'
    ) {
      const serviceFrequencyField = output.find(
        field => field.fieldName === 'serviceFrequency',
      );
      const woInAdvStartingParent = output.find(
        field => field.fieldName === 'woInAdvStartingDate',
      )?.subFields;

      const startingDateField = woInAdvStartingParent?.find(
        field => field.fieldName === 'startDate',
      );
      const woInAdvField = woInAdvStartingParent?.find(
        field => field.fieldName === 'createInAdvance',
      );

      const woInAdvValue = parseInt(woInAdvField?.value as string);
      const serviceFrequencyValue =
        serviceFrequencyField?.value as ServiceFrequencyValueType;

      let woMinDateAdditionOnDaysBetweenService = 0;

      if (serviceFrequencyValue.type === SelectionType.DAY) {
        woMinDateAdditionOnDaysBetweenService = woInAdvValue || 0;
      }

      if (startingDateField) {
        startingDateField.minDate = moment()
          .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
          .format('YYYY-MM-DD');

        if (fieldName !== 'startDate') {
          startingDateField.value = moment()
            .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
            .format('YYYY-MM-DD');
        }
      }

      const updatedDate = moment(
        moment(startingDateField?.value).format('YYYY-MM-DD'),
      );

      if (
        startingDateField &&
        woInAdvValue &&
        serviceFrequencyValue.type === SelectionType.DAY &&
        moment(updatedDate).isBefore(moment(startingDateField.minDate))
      ) {
        startingDateField.minDate = moment()
          .add(3 + woMinDateAdditionOnDaysBetweenService, 'days')
          .format('YYYY-MM-DD');
      }

      const startingDateValue = updatedDate?.format('YYYY-MM-DD');

      if (
        serviceFrequencyValue.type === SelectionType.MONTH &&
        serviceFrequencyValue &&
        serviceFrequencyValue.serviceMonths &&
        serviceFrequencyValue.dayOfMonth &&
        startingDateValue &&
        !isNaN(woInAdvValue)
      ) {
        const nextServiceDateField = woInAdvStartingParent?.find(
          field => field.fieldName === 'nextServiceDate',
        );
        await updateNextServiceDate(
          moment(startingDateValue),
          serviceFrequencyValue,
          woInAdvValue,
          nextServiceDateField,
        );
      } else if (
        serviceFrequencyValue.type === SelectionType.DAY &&
        serviceFrequencyValue &&
        serviceFrequencyValue.daysBetweenService &&
        startingDateValue &&
        !isNaN(woInAdvValue)
      ) {
        if (
          serviceFrequencyValue.daysBetweenService <= woInAdvValue ||
          woInAdvValue <= 0
        ) {
          if (woInAdvField) {
            woInAdvField.helperText = t(
              'translation:pages.preventiveMaintenance.workOrderInAdvanceError',
            );
            woInAdvField.value = null;
          }
        } else {
          const newStartingDate = moment(startingDateField?.value as Date);
          const nextServiceDateField = woInAdvStartingParent?.find(
            field => field.fieldName === 'nextServiceDate',
          );
          await updateNextServiceDate(
            moment(newStartingDate),
            serviceFrequencyValue,
            woInAdvValue,
            nextServiceDateField,
          );
          if (woInAdvField) {
            woInAdvField.helperText = '';
          }
        }
      }
    }

    const locationFieldValue = output.find(
      field => field.fieldName === 'locations',
    )?.value;
    const itemsIdOnHold = locationFieldValue
      ?.filter(location => location.onHold === 1)
      .map(location => `${location.id}`);

    const indexPmOnHoldAlert = output.findIndex(
      field => field.fieldName === 'pmOnHoldAlert',
    );
    output[indexPmOnHoldAlert] =
      itemsIdOnHold?.length > 0
        ? {
            type: FieldTypes.Alert,
            fieldName: 'pmOnHoldAlert',
            title: 'On-Hold Location(s)',
            severity: 'warning',
            variant: 'standard',
            description:
              'The following Locations are On-Hold. You can still add the location(s) to this Preventive Maintenance, but Work Orders will not be created for these location(s) while they are On-Hold.',
            items: [...itemsIdOnHold],
          }
        : {
            type: FieldTypes.Group,
            fieldName: 'pmOnHoldAlert',
          };

    setFormFields(output);
  };

  const { data: categoryListData, isSuccess: isSuccessCategoryListData } =
    useGetCategoryListQuery({ st: 1, t: 200 });

  useEffect(() => {
    if (isSuccessCategoryListData) {
      const categorySelectFieldParent = formFields.find(
        field => field.fieldName === 'categoryPriority',
      );

      const categorySelectField = categorySelectFieldParent?.subFields?.find(
        field => field.fieldName === 'category',
      );

      categorySelectField.options =
        categoryListData?.data.map(d => ({ label: d.name, id: d.id })) || [];
      categorySelectField.optionValues =
        categoryListData?.data.map(d => d.id) || [];

      const maintenanceCategoryItem = categoryListData?.data.find(
        category => category.name === 'Maintenance',
      );

      if (maintenanceCategoryItem) {
        categorySelectField.value = maintenanceCategoryItem.id;
      }

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'category',
      );
      formFields[fieldIndex] = categorySelectField;

      setFormFields([...formFields]);
    }
  }, [categoryListData, isSuccessCategoryListData]);

  const { data: priorities, isSuccess: isSuccessPriorities } =
    useGetPrioritiesListQuery({
      ob: 'prrt.name',
      o: 'desc',
    });

  useEffect(() => {
    if (isSuccessPriorities) {
      const prioritySelectFieldParent = formFields.find(
        field => field.fieldName === 'categoryPriority',
      );

      const prioritySelectField = prioritySelectFieldParent?.subFields?.find(
        field => field.fieldName === 'priority',
      );

      const sortedPriorities = _.orderBy(
        priorities?.data,
        (priority: any) => priority.name,
      );

      prioritySelectField.options =
        sortedPriorities?.map(d => ({
          label: d.name,
          colorName: d.color,
          id: d.id,
        })) || [];
      prioritySelectField.optionValues = sortedPriorities?.map(d => d.id) || [];

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'priority',
      );
      formFields[fieldIndex] = prioritySelectField;

      setFormFields([...formFields]);
    }
  }, [priorities, isSuccessPriorities]);

  const { data: assetTypes, isSuccess: isSuccessAssetTypes } =
    useGetAssetTypesListQuery({
      t: 0,
    });

  useEffect(() => {
    if (assetTypes) {
      const assetTypeSelectField = formFields.find(
        field => field.fieldName === 'assetGroup',
      );

      assetTypeSelectField.options =
        assetTypes?.data.map(d => ({
          label: d.name,
          id: d.id,
          refrigerantTracking: d.refrigerantTracking,
        })) || [];
      assetTypeSelectField.optionValues = assetTypes?.data.map(d => d.id) || [];

      const fieldIndex = formFields.findIndex(
        field => field.fieldName === 'assetGroup',
      );
      formFields[fieldIndex] = assetTypeSelectField;

      setFormFields([...formFields]);
    }
  }, [assetTypes, isSuccessAssetTypes]);

  const useAddPreventiveMaintenance = useCallback(() => {
    const doCreate = data => {
      if (
        data.serviceFrequency.type === SelectionType.MONTH &&
        (!data.serviceFrequency.dayOfMonth ||
          !data.serviceFrequency.serviceMonths)
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Day of Month and Service Months',
          }),
        );
        return;
      } else if (
        data.serviceFrequency.type === SelectionType.DAY &&
        !data.serviceFrequency.daysBetweenService
      ) {
        dispatch(
          setSnackbar({
            severity: 'error',
            message: 'Please Enter Days Between Service',
          }),
        );
        return;
      } else {
        const body = {
          name: data.name,
          assetTypeId: data.assetGroup?.id,
          tradeId: data.trade?.id,
          requestCategoryId: data.category?.id,
          priorityId: data.priority?.id,
          tradeNte: parseFloat(data.nte),
          spCompanyId: data.serviceProvider?.id,
          serviceMonths: data.serviceFrequency.serviceMonths,
          dayOfMonth: data.serviceFrequency.dayOfMonth,
          daysBetween: data.serviceFrequency.daysBetweenService,
          description: data.description,
          startDate: moment(data.startDate).format('MM/DD/YYYY'),
          createInAdvance: data.createInAdvance
            ? parseInt(data.createInAdvance)
            : 0,
          orgIds: data.locations?.map(location => location.orgId),
          files: data.files,
        };
        addPreventiveMaintenance(body);
      }
    };
    return [
      doCreate,
      {
        data: createData,
        isError: isCreateError,
        error: createError,
        isLoading: isLoadingCreate,
        isSuccess: isCreateSuccess,
        reset: resetCreate,
      },
    ];
  }, [
    isCreateError,
    createError,
    isLoadingCreate,
    isCreateSuccess,
    resetCreate,
    createData,
    addPreventiveMaintenance,
  ]);

  useEffect(() => {
    loadPageScopes('preventiveMaintenance');
    dispatch(setFilterEndpoint('/filter/pms'));
  }, []);

  const resetFields = () => {
    const resetedFields = [...fancyFormElementsCreate.fields];
    const serviceFrequencyField = resetedFields.find(
      field => field.fieldName === 'serviceFrequency',
    );
    if (serviceFrequencyField) {
      serviceFrequencyField.resetState = true;
    }
    setFormFields(resetedFields);
  };

  const handleOpenDetails = (row: PreventiveMaintenanceWithModule) => {
    const url = match ? match.pathname.replace('pms', row.moduleName) : '';

    return row.moduleName && row.id
      ? navigate(`${url}/${row.id}/details`)
      : undefined;
  };

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.preventiveMaintenance.title')}
      showStatusActiveFilter={!isMobileSize}
      useLazyGetListQuery={useLazyGetPreventiveMaintenanceListQuery}
      useCreateMutation={useAddPreventiveMaintenance}
      detailsConfig={fancyFormElementsDetails.data.config}
      detailsFields={fancyFormElementsDetails.data.fields}
      onRowClick={handleOpenDetails}
      createFormConfig={fancyFormElementsCreate.config}
      createFormFields={formFields}
      onChange={handleOutputChange}
      onDrawerClose={resetFields}
      // onCreate={onCreatePM}
    />
  );
};
