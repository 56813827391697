import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  inventoryApi,
  useAdjustInventoryItemStockMutation,
  useGetInventoryStorageLocationsListQuery,
} from 'services/inventoryApi';
import { P } from 'types/Permission';
import { InventoryItem } from '..';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

export interface AddStockContentProps {
  selectedInventory?: InventoryItem;
  onClose: () => void;
  onSubmit?: () => void;
  onChange?: (sp: any) => void;
  isOpen?: boolean;
}

const formElementsAddStock = require('./form_config_add_stock.json');

function AddStockContent({
  onClose,
  selectedInventory,
  onSubmit,
  onChange,
  isOpen,
}: AddStockContentProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formElementsAddStockFields, setFormElementsAddStockFields] = useState<
    ECEasyFormFieldType[]
  >(formElementsAddStock.fields);

  const {
    data: inventoryStorageList,
    isFetching: isInventoryStorageListFetching,
    isLoading: isInventoryStorageListLoading,
    isSuccess: isInventoryStorageListSuccess,
  } = useGetInventoryStorageLocationsListQuery(
    {
      ob: 'invntrystrg.name',
    },
    { skip: !isOpen },
  );

  useEffect(() => {
    if (isInventoryStorageListSuccess && inventoryStorageList?.data?.length) {
      const updatedFormFieldsAddStock = [...formElementsAddStockFields];
      const storageLocationField = updatedFormFieldsAddStock?.find(
        field => field.fieldName === 'storageLocation',
      );

      if (storageLocationField) {
        storageLocationField.options = inventoryStorageList.data.map(
          storageLocation => ({
            id: storageLocation.id,
            label: storageLocation.name,
          }),
        );
      }

      const quantityAndCostGroup = updatedFormFieldsAddStock?.find(
        field => field.fieldName === 'quantityAndCostGroup',
      );

      const costPerUnitField = quantityAndCostGroup?.subFields?.find(
        field => field.fieldName === 'costPerUnit',
      );

      if (costPerUnitField) {
        costPerUnitField.value = selectedInventory?.avgPrice;
      }
      setFormElementsAddStockFields(updatedFormFieldsAddStock);
    }
  }, [inventoryStorageList, isInventoryStorageListSuccess]);

  useEffect(() => {
    if (selectedInventory?.measurementUnit && isOpen) {
      const updatedFormFieldsAddStock = [...formElementsAddStockFields];
      const quantityAndCostGroup = updatedFormFieldsAddStock?.find(
        field => field.fieldName === 'quantityAndCostGroup',
      );

      const quantityOnHandField = quantityAndCostGroup?.subFields?.find(
        field => field.fieldName === 'quantityOnHand',
      );

      if (quantityOnHandField) {
        quantityOnHandField.endAdornment =
          selectedInventory?.measurementUnit?.symbol || '';
      }

      setFormElementsAddStockFields(updatedFormFieldsAddStock);
    }
  }, [selectedInventory, isOpen]);

  const [
    doAdjustInventoryItemStock,
    {
      data: adjustInventoryItemStockData,
      isError: adjustInventoryItemStockError,
      error: adjustInventoryItemStockErrorData,
      isLoading: isAdjustInventoryItemStockLoading,
      isSuccess: isAdjustInventoryItemStockSuccess,
      reset: resetAdjustInventoryItemStock,
    },
  ] = useAdjustInventoryItemStockMutation();

  const useAdjustInventoryItemsStockMutation = useCallback(() => {
    const doAdjust = async data => {
      if (!selectedInventory?.id) {
        return;
      }

      doAdjustInventoryItemStock({
        _id: data.storageLocation.id,
        inventoryItemId: Number(selectedInventory?.id),
        costPerUnit: parseFloat(data.costPerUnit),
        count: parseInt(data.quantityOnHand),
        addStock: true,
        removeStock: false,
        description: data.description,
        lot: data.lotNumber,
        bin: data.binNumber,
        inventoryActivityReceipts: data.stockReceipts,
      });
    };
    return [
      doAdjust,
      {
        isError: false,
        error: null,
        isSuccess: false,
        isLoading: isAdjustInventoryItemStockLoading,
        reset: resetAdjustInventoryItemStock,
      },
    ];
  }, [
    selectedInventory?.id,
    adjustInventoryItemStockError,
    adjustInventoryItemStockErrorData,
    isAdjustInventoryItemStockSuccess,
    isAdjustInventoryItemStockLoading,
    doAdjustInventoryItemStock,
    resetAdjustInventoryItemStock,
  ]);
  useEffect(() => {
    if (isAdjustInventoryItemStockSuccess) {
      dispatch(inventoryApi.util.invalidateTags(['Inventory']));
      onClose?.();
    }
  }, [isAdjustInventoryItemStockSuccess]);

  const handleClose = useCallback(() => {
    onClose?.();
    setFormElementsAddStockFields(_.cloneDeep(formElementsAddStock.fields));

    return {
      data: null,
      isError: false,
      error: null,
      isLoading: false,
      isSuccess: false,
    };
  }, [setFormElementsAddStockFields, onClose]);

  return (
    <ECEasyFormCreate
      id="add-stock-form-nested"
      useCreateMutation={useAdjustInventoryItemsStockMutation}
      formConfig={formElementsAddStock.config}
      formFields={formElementsAddStockFields}
      onClose={handleClose}
      pattern="modal"
    />
  );
}

export const addStockAction = {
  buttonLabel: 'Add Stock',
  modalContent: requirePermissionWrapper<AddStockContentProps>(AddStockContent),
  scopes: [P.GetInventory],
};
