import { useEffect } from 'react';
import { ECDynamicPageTemplate, ECIconButton } from 'app/components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setFilterEndpoint } from 'store/slice/page';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { useLazyGetAttachmentReportsQuery } from 'services/attachmentApi';
import { Download } from '@mui/icons-material';

export function ExportsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/reports'));
  }, []);

  const isCustomer = useCustomerUser();

  const handleDownloadClick = item => {
    const hiddenElement = document.createElement('a');

    hiddenElement.href = item.url || '';
    hiddenElement.download = item.fileName;
    hiddenElement.click();
  };

  const actionButton = (row: any) => (
    <ECIconButton
      size="medium"
      withBorder
      onClick={() => handleDownloadClick(row)}
    >
      <Download />
    </ECIconButton>
  );

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <ECDynamicPageTemplate
      pageTitle={t('translation:pages.accounting.title')}
      useLazyGetListQuery={useLazyGetAttachmentReportsQuery}
      useCreateMutation={{}}
      marginTop={false}
      withDrawer={false}
      shouldNotUseActiveFilter
      hideSaveFilterButton
      renderCustomActionComponent={actionButton}
    />
  );
}
