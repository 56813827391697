import type {
  InvoiceUpdate,
  InvoiceResponse,
  InvoiceCreate,
  InvoiceTableRow,
  InvoiceDetails,
  InvoiceBase,
} from 'types/Invoice';
import { WarrantyComponent } from 'types/Warranty';
import { BaseType } from 'types/BaseType';
import { QueryParams } from 'types/QueryParams';
import {
  WorkflowNextStatus,
  WorkflowStatusUpdate,
  WorkflowStepActionUpdate,
  BulkApprovalStatusUpdateAction,
  BulkActionStatusUpdateResponse,
} from 'types/WorkflowStatus';

import { emptyApi } from './emptyApi';
import { convertJsonToFormData } from '../utils/form';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'InvoiceList',
    'PendingInvoices',
    'InvoiceById',
    'SPInvoiceById',
    'WorkflowStatusList',
    'WarrantyComponent',
    'Attachment',
  ],
});

export const invoiceApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    // GET all Invoices
    getInvoiceList: builder.query<BaseType<InvoiceTableRow[]>, QueryParams>({
      query: params => {
        if (!Object.values(params)?.includes('wrkflwstts.name')) {
          return {
            url: '',
          };
        }

        return {
          url: 'invoice',
          params: {
            ...params,
            ob: params.ob || 'invce.id',
          },
        };
      },
      providesTags: ['InvoiceList', 'PendingInvoices'],
    }),
    // GET by Id
    getInvoiceById: builder.query<InvoiceDetails, string>({
      query: invoiceId => ({ url: `invoice/${invoiceId}` }),
      providesTags: ['InvoiceById'],
      transformResponse: (response: InvoiceResponse) => {
        return {
          ...response,
          assetId: response.request.asset?.id,
          workflowId: response.workflowId,
          requestId: response.request.id,
          status: response.workflowStatus.name,
          workflowStatusId: response.workflowStatus.id,
          nte: response.request.workorders?.[0].nte,
          branch: response.request.asset?.branch?.name,
          branchId: response.request.asset?.branch?.id,
          asset: response.request.asset?.name,
          category: response.request.category?.name,
          categoryId: response.request.category?.id,
          assetGroup: response.request.asset?.assetType?.name,
          assetGroupId: response.request.asset?.assetType?.id,
          trade: response.request.trade?.name,
          problem: response.request.problem?.name,
          problemId: response.request.problemId,
          phone: response.request?.asset?.branch?.phone || '',
          address: response.address || '',
          submittedBy: '',
          moduleName: 'invoice',
          customerCode: response.request.customerCode,
        };
      },
    }),
    // CREATE
    createInvoice: builder.mutation<InvoiceBase, InvoiceCreate>({
      query: body => {
        const notRequired = body.notRequired?.length ? 1 : 0;
        const transformedBody = {
          ...body,
          notRequired,
        };

        // if Coupa is Enabled we call a separate endpoint that integrated the 3rd part system
        if (body.enableCoupa) {
          const coupaBody = {
            ...transformedBody,
            notRequired,
          };
          return {
            url: 'coupa/invoice',
            method: 'post',
            body: coupaBody,
          };
        }

        const formData = convertJsonToFormData(transformedBody);

        body.files?.forEach(file => {
          formData.append('files', file);
        });
        return {
          url: 'invoice/withAttachment',
          method: 'post',
          body: formData,
        };
      },
      invalidatesTags: ['InvoiceById', 'SPInvoiceById', 'WorkflowStatusList'],
    }),
    // UPDATE
    updateInvoice: builder.mutation<void, InvoiceUpdate>({
      query: ({ id, ...updateDto }) => {
        return {
          url: `invoice/${id}`,
          body: updateDto,
          method: 'put',
        };
      },
      invalidatesTags: [
        'InvoiceById',
        'SPInvoiceById',
        'WarrantyComponent',
        'WorkflowStatusList',
      ],
    }),
    // bulk invoice category
    updateBulkInvoice: builder.mutation<void, BulkApprovalStatusUpdateAction>({
      query: params => {
        return {
          url: 'invoice/',
          body: params,
          method: 'put',
        };
      },
      invalidatesTags: ['InvoiceList', 'PendingInvoices'],
    }),
    bulkInvoiceFlagsChange: builder.mutation<void, WorkflowStatusUpdate>({
      query: body => {
        return {
          url: 'invoice/flag/change',
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['PendingInvoices'],
    }),
    //GET NEXT WORKFLOW STATUS DROPDOWN
    getNextStatusList: builder.query<WorkflowNextStatus[], number>({
      query: invoiceId => {
        return {
          url: `invoice/${invoiceId}/status/next`,
        };
      },
      providesTags: ['WorkflowStatusList'],
    }),
    // Workflow status change
    updateStatus: builder.mutation<void, WorkflowStatusUpdate>({
      query: ({
        id,
        statusTo,
        actionId,
        declineReason,
        note,
        recipientIds,
      }) => ({
        url: `invoice/${id}/status/change`,
        body: { statusTo, actionId, declineReason, recipientIds, note },
        method: 'put',
      }),
      invalidatesTags: [
        'InvoiceById',
        'SPInvoiceById',
        'WarrantyComponent',
        'WorkflowStatusList',
        'Attachment',
      ],
    }),
    updateStepAction: builder.mutation<void, WorkflowStepActionUpdate>({
      query: ({ id, ...rawBody }) => {
        const body = {
          approvalProcessStepId: rawBody.approvalProcessStepId,
          approvalProcessId: rawBody.approvalProcessId,
          statusTo: rawBody.statusTo,
          approvalProcessActionId: rawBody.approvalProcessActionId,
          workflowLinkId: rawBody.workflowLinkId,
        };

        return {
          url: `invoice/${id}/action`,
          body,
          method: 'put',
        };
      },
      invalidatesTags: ['InvoiceById', 'WorkflowStatusList'],
    }),

    // Service Provider
    getSPInvoiceList: builder.query<BaseType<InvoiceTableRow[]>, QueryParams>({
      query: params => {
        if (!Object.values(params)?.includes('wrkflwstts.name')) {
          return {
            url: '',
          };
        }

        return {
          url: 'sp/invoice',
          params: {
            ...params,
            ob: params.ob || 'invce.id',
          },
        };
      },
      providesTags: ['InvoiceList'],
    }),
    getSPInvoiceById: builder.query<InvoiceDetails, string>({
      query: id => ({ url: `sp/invoice/${id}` }),
      transformResponse: (response: InvoiceResponse) => {
        const mailingAddress = response.request.asset?.branch?.mailingAddress;
        const addressStreet = `${mailingAddress?.line1}${
          mailingAddress?.line2 ? ` ${mailingAddress?.line2}` : ''
        }${mailingAddress?.line3 ? ` ${mailingAddress?.line3}` : ''}`;

        return {
          ...response,
          assetId: response.request.asset?.id,
          requestId: response.request.id,
          status: response.workflowStatus.name,
          workflowStatusId: response.workflowStatus.id,
          nte: response.request.workorders?.[0].nte,
          branch: response.request.branch?.name,
          branchId: response.request.branchId,
          storeNumber: response.request.branch?.storeNumber.toString(),
          asset: response.request.asset?.name,
          category: response.request.category?.name,
          categoryId: response.request.category?.id,
          assetGroup: response.request.asset?.assetType?.name,
          trade: response.request.trade?.name,
          problem: response.request.problem?.name,
          phone: response.request.branch?.phone,
          address: `${addressStreet}, ${mailingAddress?.cityName}, ${mailingAddress?.stateProvinceCode} ${mailingAddress?.zipCodePostalCode}`,
          submittedBy: '',
          assignedToUsers: response.assignedToUsers?.map(user => ({
            ...user,
            name: user?.fullName,
          })),
          warrantyComponents: response.warrantyComponents?.map(wc => ({
            ...wc,
            repairName: wc.repair?.name,
            warranty: {
              noWarranty: wc.noWarranty,
              resolution: wc.resolution?.name,
              period: wc.duration,
              periodLabel: wc.period?.description,
            },
          })),
          customerCode: response.request.customerCode,
          customerName: response.customer?.name,
          completedDate: response.request?.workorders?.find(
            wo => wo.completedDate,
          )?.completedDate,
        };
      },
      providesTags: ['SPInvoiceById'],
    }),
    getWarrantyComponentForInvoiceId: builder.query<
      WarrantyComponent[],
      string
    >({
      query: id => ({
        url: `invoice/${id}/failures/resolutions`,
      }),
      providesTags: ['WarrantyComponent'],
    }),

    updateBulkInvoiceStatus: builder.mutation<
      BulkActionStatusUpdateResponse,
      BulkApprovalStatusUpdateAction
    >({
      query: ({
        itemIds,
        statusTo,
        actionId,
        latitude,
        longitude,
        declineTypeId,
        declineReason,
        note,
        recipientsIds,
      }) => ({
        url: 'invoice/status/change',
        body: {
          itemIds,
          statusTo,
          actionId,
          latitude,
          longitude,
          declineTypeId,
          declineReason,
          note,
          recipientsIds,
        },
        method: 'put',
      }),
    }),

    // CREATE COUPA PURCHASE ORDER
    createCoupaPurchaseOrder: builder.mutation<InvoiceBase, number>({
      query: requestId => {
        return {
          url: 'coupa/invoice/create-purcharse-order',
          method: 'post',
          body: { requestId },
        };
      },
      invalidatesTags: ['InvoiceById', 'SPInvoiceById', 'WorkflowStatusList'],
    }),

    // add credit
    // takes invoice ID and credit amount as params
    addInvoiceCredit: builder.mutation<void, { id: number; credit: number }>({
      query: ({ id, credit }) => {
        return {
          url: `invoice/${id}/credit/change`,
          method: 'put',
          body: { costCredit: credit },
        };
      },
      invalidatesTags: ['InvoiceById', 'SPInvoiceById'],
      // returns the CREDIT amount to be added to success snackbar message
      transformResponse: (response: any, meta, arg) => {
        return {
          ...response,
          credit: arg.credit,
        };
      },
    }),
  }),
});

export const {
  useGetInvoiceListQuery,
  useLazyGetInvoiceListQuery,
  useGetInvoiceByIdQuery,
  useLazyGetInvoiceByIdQuery,
  useUpdateInvoiceMutation,
  useUpdateBulkInvoiceMutation,
  useGetNextStatusListQuery,
  useUpdateStatusMutation,
  useCreateInvoiceMutation,
  useUpdateStepActionMutation,
  useBulkInvoiceFlagsChangeMutation,
  useGetSPInvoiceListQuery,
  useLazyGetSPInvoiceListQuery,
  useGetSPInvoiceByIdQuery,
  useLazyGetSPInvoiceByIdQuery,
  useGetWarrantyComponentForInvoiceIdQuery,
  useLazyGetWarrantyComponentForInvoiceIdQuery,
  useUpdateBulkInvoiceStatusMutation,
  useCreateCoupaPurchaseOrderMutation,
  useAddInvoiceCreditMutation,
} = invoiceApi;
