import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { setFilterEndpoint } from 'store/slice/page';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import { GetGlCodeView } from './Views';
import { useGetAccountingGLCodesQuery } from 'services/accountingApi';
import { AccountingViewsEnum } from 'types/Accounting';

export function AccountingGLCodesPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFilterEndpoint('/filter/accounting'));
  }, [dispatch]);

  const { data: glCodeData } = useGetAccountingGLCodesQuery({
    t: 0,
    onlyConfig: true,
  });

  const glCodeFilter = useMemo(() => {
    const showLocationFilter = glCodeData?.config.fields.find(
      field => field.name === 'branchName',
    )?.searchable;
    const showConceptFilter = glCodeData?.config.fields.find(
      field => field.name === 'branchTypeName',
    )?.searchable;
    if (showLocationFilter && showConceptFilter) {
      return { view: AccountingViewsEnum.ALL };
    }
    if (showLocationFilter) {
      return { view: AccountingViewsEnum.LOCATIONS };
    }
    if (showConceptFilter) {
      return { view: AccountingViewsEnum.CONCEPTS };
    }
    return { view: AccountingViewsEnum.DEFAULT };
  }, [glCodeData]);

  const isCustomer = useCustomerUser();
  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return <>{GetGlCodeView(glCodeFilter)}</>;
}
