import TabContext, { TabContextProps } from '@mui/lab/TabContext';
import TabList, { TabListProps } from '@mui/lab/TabList';
import Tab, { TabProps } from '@mui/material/Tab';
import TabPanel, { TabPanelProps } from '@mui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { ECBox, ECButton, ECRequirePermission } from '..';
import { CompanyConfigurationFields, P } from 'types/Permission';
import { BoxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import * as _ from 'lodash';
import { ECTradeAssignmentActivities } from '../ECTradeAssignmentActivity';

export const ECTabContext = styled(TabContext)<TabContextProps>(
  ({ theme }) => ({
    '.MuiTabs-indicator': {
      color: theme.palette.marketingSuggestion.marketingSuggestion,
    },
  }),
);

export const ECTabList = styled(TabList)<TabListProps>(({ theme }) => ({
  '.MuiTabs-indicator': {
    color: theme.palette.marketingSuggestion.marketingSuggestion,
  },
}));

export const ECStyledTab = styled(Tab)<TabProps>(({ theme }) => ({}));

interface ECTabProps extends TabProps {
  link?: string;
  scopes?: P[];
  scopesOptional?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
  variant?: 'fullWidth' | 'standard' | 'scrollable';
  hasError?: boolean;
}

export const ECTab = (props: ECTabProps) => {
  const {
    scopes = [],
    scopesOptional,
    companyConfigScopes,
    children,
    variant,
    hasError,
    link,
    ...rest
  } = props;

  if (link) {
    return (
      <ECRequirePermission
        scopes={scopes}
        scopesOptional={scopesOptional}
        companyConfigScopes={companyConfigScopes}
      >
        <Link
          to={link}
          style={{
            flex: variant === 'fullWidth' ? 1 : undefined,
            pointerEvents: rest.disabled ? 'none' : 'auto',
          }}
        >
          <ECStyledTab
            {...rest}
            sx={{
              color: hasError ? 'red' : '',
              flex: 1,
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '125px !important',
            }}
          />
        </Link>
      </ECRequirePermission>
    );
  }

  return (
    <ECRequirePermission
      scopes={scopes}
      scopesOptional={scopesOptional}
      companyConfigScopes={companyConfigScopes}
    >
      <ECStyledTab
        {...rest}
        sx={{ color: hasError ? 'red' : '', minWidth: '125px !important' }}
      >
        {children}
      </ECStyledTab>
    </ECRequirePermission>
  );
};

interface ECStyledTabPanelProps extends TabPanelProps {
  noPadding?: boolean;
}
export const ECStyledTabPanel = styled(TabPanel)<ECStyledTabPanelProps>(
  ({ theme, noPadding }) => ({
    backgroundColor: theme.palette.background.paper,
  }),
);

interface ECTabPanelProps extends ECStyledTabPanelProps {
  scopes?: P[];
  scopesOptional?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
  index?: string;
  renderAllTabs?: boolean;
  selectedTab?: string;
  SummaryDataForm?: ReactElement;
  disabled?: boolean;
  showPMWOButton?: boolean;
}
export const ECTabPanel = (props: ECTabPanelProps) => {
  const {
    scopes = [],
    scopesOptional,
    companyConfigScopes,
    children,
    value,
    index,
    renderAllTabs,
    selectedTab,
    SummaryDataForm,
    noPadding,
    sx,
    ...rest
  } = props;
  const renderSummaryDataForm = SummaryDataForm && value === 'details';
  return renderAllTabs ? (
    <ECRequirePermission
      scopes={scopes}
      scopesOptional={scopesOptional}
      companyConfigScopes={companyConfigScopes}
    >
      <ECBox hidden={value !== index}>
        <ECStyledTabPanel
          value={value}
          {...rest}
          sx={{
            padding: noPadding ? 0 : 3,
            ...sx,
          }}
        >
          {renderSummaryDataForm ? (
            <>
              {SummaryDataForm}
              <hr />
            </>
          ) : (
            <></>
          )}
          {children}
        </ECStyledTabPanel>
      </ECBox>
    </ECRequirePermission>
  ) : (
    <ECRequirePermission
      scopes={scopes}
      scopesOptional={scopesOptional}
      companyConfigScopes={companyConfigScopes}
    >
      <ECStyledTabPanel
        value={value}
        {...rest}
        sx={{
          padding: noPadding ? 0 : 3,
          zIndex: 1,
          ...sx,
        }}
      >
        {renderSummaryDataForm ? (
          <>
            {SummaryDataForm}
            <hr color="#eeeeee" />
          </>
        ) : (
          <></>
        )}
        {children}
      </ECStyledTabPanel>
    </ECRequirePermission>
  );
};

export interface ECTabContainerTabType {
  value: string;
  label: string;
  content?: React.ReactNode;
  link?: string;
  scopes?: P[];
  scopesOptional?: P[];
  companyConfigScopes?: CompanyConfigurationFields[];
  disabled?: boolean;
  hasError?: boolean;
}

export interface ECTabContainerProps extends BoxProps {
  tabs: ECTabContainerTabType[];
  variant?: 'fullWidth' | 'standard' | 'scrollable';
  color?: 'primary' | 'marketingSuggestion';
  type?: 'standard' | 'contained';
  noPadding?: boolean;
  selectedTab?: string;
  SummaryDataForm?: ReactElement;
  onChangeSelectedTab?: (tab) => void;
  renderAllTabs?: boolean;
  clearFilterOnChangeSelectedTab?: boolean;
  tabsAsLinks?: boolean;
  showActivityButton?: boolean;
  disableChangeTab?: boolean;
}

export const ECTabContainer = ({
  tabs,
  noPadding,
  variant = 'fullWidth',
  color = 'primary',
  type = 'standard',
  selectedTab,
  onChangeSelectedTab,
  renderAllTabs = false,
  SummaryDataForm,
  tabsAsLinks,
  clearFilterOnChangeSelectedTab = true,
  showActivityButton,
  disableChangeTab = false,
  ...boxProps
}: ECTabContainerProps) => {
  const [value, setValue] = React.useState(tabs?.[0]?.value ?? '');
  const navigate = useNavigate();
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const hasPermissions = useHasPermission(
    _.flattenDeep(tabs?.map(tab => tab.scopes ?? []) ?? []),
  );

  useEffect(() => {
    const valueFromUrl = _.last(window.location.href.split('/')) ?? '';
    if (tabsAsLinks && tabs?.map(tab => tab.value).includes(valueFromUrl)) {
      setValue(valueFromUrl);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (!!value && !tabs?.map(tab => tab.value).includes(value)) {
      const firstTabAccessbileForUser = hasPermissions?.findIndex(
        permission => permission,
      );

      if (firstTabAccessbileForUser > -1) {
        navigate(tabs?.[firstTabAccessbileForUser]?.link ?? '');
      } else {
        navigate(tabs?.find(tab => !tab?.scopes)?.link ?? '');
      }
    }
  }, [...hasPermissions, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (disableChangeTab) {
      return;
    }
    onChangeSelectedTab?.(newValue);
    setValue(newValue);
  };

  const handleOpenActivityDrawer = useCallback(() => {
    setIsActivityDrawerOpen(true);
  }, []);

  const onClose = () => {
    setIsActivityDrawerOpen(false);
  };

  useEffect(() => {
    if (selectedTab) setValue(selectedTab);
  }, [selectedTab]);

  const mapType = {
    standard: {
      root: {
        backgroundColor: SummaryDataForm
          ? 'marketingSuggestion.mainBackground'
          : 'transparent',
      },
      indicator: {
        backgroundColor: theme =>
          color === 'primary'
            ? theme.palette.primary.main
            : theme.palette.marketingSuggestion.marketingSuggestion,
      },
      selected: {
        color: theme =>
          `${
            color === 'primary'
              ? theme.palette.primary.main
              : theme.palette.marketingSuggestion.marketingSuggestion
          } !important`,
        backgroundColor: SummaryDataForm
          ? 'marketingSuggestion.mainBackground'
          : 'transparent',
      },
    },
    contained: {
      root: {
        border: 1,
        borderRight: 0,
        borderColor: theme => theme.palette.primary.main,
        '&:first-child': {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
        '&:last-child': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          borderRight: 1,
        },
        color: theme => theme.palette.primary.main,
      },
      indicator: {
        backgroundColor: 'transparent',
      },
      selected: {
        color: theme => theme.palette.primary.main,
        backgroundColor: theme => theme.palette.primary.outlinedHoverBackground,
      },
    },
  };

  return (
    <ECTabContext value={value}>
      <ECBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingRight={showActivityButton ? '36px' : 0}
      >
        <ECBox {...boxProps} flexGrow={showActivityButton ? 0 : 1}>
          <ECBox
            sx={theme => ({
              borderBottom: type === 'contained' ? 0 : 1,
              borderColor: 'divider',
              bgcolor: theme.palette.background.default,
            })}
          >
            <ECTabList
              variant={variant}
              sx={theme => ({
                '.MuiTab-root': {
                  ...mapType[type].root,
                },
                '& .MuiTabs-indicator': {
                  ...mapType[type].indicator,
                },
                '& .Mui-selected': {
                  ...mapType[type].selected,
                },
              })}
              onChange={handleChange}
              aria-label="tabs container"
            >
              {tabs?.map((tab, index) => (
                <ECTab
                  variant={variant}
                  hasError={tab.hasError}
                  label={tab.label}
                  value={tab.value}
                  scopes={tab.scopes}
                  scopesOptional={tab.scopesOptional}
                  companyConfigScopes={tab.companyConfigScopes}
                  disabled={disableChangeTab || tab.disabled}
                  link={
                    disableChangeTab
                      ? undefined
                      : tabsAsLinks
                        ? tab.link
                        : undefined
                  }
                  key={`tab-${index}`}
                />
              ))}
            </ECTabList>
          </ECBox>
        </ECBox>
        {showActivityButton && (
          <ECButton
            variant="outlined"
            style={{
              background: 'transparent',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
            onClick={handleOpenActivityDrawer}
          >
            Activity
          </ECButton>
        )}
      </ECBox>
      {showActivityButton && isActivityDrawerOpen && (
        <ECTradeAssignmentActivities
          isActivityDrawerOpen={isActivityDrawerOpen}
          onClose={onClose}
        />
      )}

      {tabs?.map((tab, index) => (
        <ECTabPanel
          SummaryDataForm={SummaryDataForm}
          noPadding={noPadding}
          selectedTab={selectedTab}
          value={renderAllTabs ? value : tab.value}
          index={`${index}`}
          scopes={tab.scopes}
          scopesOptional={tab.scopesOptional}
          companyConfigScopes={tab.companyConfigScopes}
          sx={{ height: '100%' }}
          renderAllTabs={renderAllTabs}
          disabled={tab.disabled}
          key={`tab-panel-${index}`}
        >
          {tab.content}
        </ECTabPanel>
      ))}
    </ECTabContext>
  );
};
