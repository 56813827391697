import { currencyFormatter } from 'utils/strings/currency-formatter';
import { MainTable } from '.';
import { ECBox } from '../ECBox';
import {
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableRow,
} from '../ECTable';
import { ECTypography } from '../ECTypography';
import ECCurrencyField from '../ECCurrencyField';
import { FormHelperText } from '@mui/material';
import { ECFormControl } from '../ECForm';
import { CostLaborItem } from 'types/CostLaborItem';
import { ECMenuItem, ECSelect } from '../ECSelect';
import { ECAlert } from '../ECAlert';
import { ServiceProviderRatesAndTerms } from 'types/ServiceProviderTypes';
import { useCustomerUser } from 'app/hooks';
import { ECButton } from '../ECButton';
import { useGetLaborItemsQuery } from 'services/lookupApi';
import { ECTextField } from '../ECTextField';
import { ECIconButton } from '../ECIconButton';
import { Close } from '@mui/icons-material';
import { useCallback } from 'react';
import { transform } from 'lodash';
import { formatClearedDecimal } from 'utils/strings/formatDecimal';
import { TRAVEL_CHARGES_NAMES } from './labor-items-sub-table';

interface Props {
  name: string;
  allCharges: CostLaborItem[];
  charges: CostLaborItem[];
  ratesAndTerms: ServiceProviderRatesAndTerms;
  isEditing?: boolean;
  readOnly?: boolean;
  onChange: (value: any, label: string) => void;
}

const mapNameToKey = {
  Labor: 'laborChargeItems',
  Travel: 'travelTypeItems',
};

const CHARGES_WITH_ONLY_AMOUNT = ['Flat Rate', 'Trip Charge', 'Truck Charge'];

export const LaborItemsSubTableCharges = ({
  name,
  allCharges,
  charges,
  ratesAndTerms,
  isEditing,
  readOnly,
  onChange,
}: Props) => {
  const isCustomer = useCustomerUser();
  const { data: laborItemsOptions } = useGetLaborItemsQuery();

  const options = laborItemsOptions?.[mapNameToKey[name]];

  const getUnitCostFromRatesAndTerms = useCallback(
    (costType: string) => {
      const ratesAndTermsWithLowerCaseKeys = transform(
        ratesAndTerms,
        (result: any, val, key) => {
          result[key.toLowerCase()] = val;
        },
      );

      return (
        ratesAndTermsWithLowerCaseKeys?.[
          `journeyman${costType?.replaceAll(' ', '')?.toLocaleLowerCase()}`
        ] || 0
      );
    },
    [ratesAndTerms],
  );

  const handleAddNew = useCallback(() => {
    const regularTimeCostType = laborItemsOptions?.laborChargeItems?.find(
      charge => charge.name === 'Regular Time',
    );
    const travelTimeCostType = laborItemsOptions?.travelTypeItems?.find(
      charge => charge.name === 'Travel Time',
    );

    const newCharge =
      name === 'Labor'
        ? {
            costType: regularTimeCostType,
            unitCost: getUnitCostFromRatesAndTerms('Regular Time'),
            unitCount: 0,
            totalCost: 0,
          }
        : {
            costType: travelTimeCostType,
            unitCost: getUnitCostFromRatesAndTerms('Travel Time'),
            unitCount: 0,
            totalCost: 0,
          };

    onChange([...allCharges, newCharge], 'Labor Charge');
  }, [
    name,
    allCharges,
    laborItemsOptions,
    onChange,
    getUnitCostFromRatesAndTerms,
  ]);

  const currentSubTableCharges =
    name === 'Labor'
      ? allCharges?.filter(
          item => !TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
        )
      : allCharges?.filter(item =>
          TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
        );
  const otherSubTableCharges =
    name === 'Labor'
      ? allCharges?.filter(item =>
          TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
        )
      : allCharges?.filter(
          item => !TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
        );

  const handleChange = (index, value) => {
    onChange(
      [
        ...otherSubTableCharges,
        ...currentSubTableCharges?.map((i, itemIndex) =>
          itemIndex === index ? value : i,
        ),
      ],
      'Labor Charge',
    );
  };

  const handleDelete = index => {
    onChange(
      [
        ...otherSubTableCharges,
        ...currentSubTableCharges?.filter(
          (i, itemIndex) => itemIndex !== index,
        ),
      ],
      'Labor Charge',
    );
  };

  return (
    <>
      <ECTableRow
        sx={{
          bgcolor: theme => `${theme.palette.action.hover} !important`,
        }}
      >
        <ECTableCell colSpan={2} sx={{ border: 0, px: 2 }}>
          <ECBox
            display="flex"
            flexDirection="column"
            width="100%"
            borderColor={theme => theme.palette.divider}
            bgcolor={theme => theme.palette.common.white}
            pb={2}
          >
            <ECTableContainer>
              <MainTable>
                <ECTableBody>
                  <ECTableRow>
                    <ECTableCell sx={{ border: 0, minWidth: '178px' }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        {name} Charge
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell sx={{ border: 0, minWidth: '178px' }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Rate
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell
                      sx={{
                        border: 0,
                        display: 'flex',
                        alignItems: 'center',
                        minWidth: '178px',
                      }}
                    >
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Hours
                      </ECTypography>
                    </ECTableCell>

                    <ECTableCell sx={{ border: 0, minWidth: '178px' }}>
                      <ECTypography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        Amount
                      </ECTypography>
                    </ECTableCell>

                    {isEditing && (
                      <ECTableCell
                        sx={{ border: 0, minWidth: '50px' }}
                      ></ECTableCell>
                    )}
                  </ECTableRow>

                  {charges?.map((item, index) => {
                    const charge = getUnitCostFromRatesAndTerms(
                      item?.costType?.name,
                    );

                    const isChargeWithOnlyAmountField =
                      CHARGES_WITH_ONLY_AMOUNT.includes(item?.costType?.name);
                    const isFlatRateCharge =
                      item?.costType?.name === 'Flat Rate';

                    return (
                      <>
                        <ECTableRow
                          key={`${item.id}-labor`}
                          id={`${item.id}`}
                          sx={{
                            bgcolor: theme =>
                              `${theme.palette.common.white} !important`,
                          }}
                        >
                          <ECTableCell
                            sx={{ border: 0, py: isEditing ? 0.5 : 0 }}
                          >
                            <ECFormControl fullWidth>
                              {isEditing && !readOnly ? (
                                <ECSelect
                                  displayEmpty
                                  hiddenLabel
                                  variant="filled"
                                  value={item?.costType?.name}
                                  onChange={e => {
                                    const value = e.target.value as string;
                                    const newCharge =
                                      getUnitCostFromRatesAndTerms(value);

                                    if (
                                      CHARGES_WITH_ONLY_AMOUNT.includes(value)
                                    ) {
                                      handleChange(index, {
                                        ...item,
                                        costType: {
                                          name: value,
                                          id: options?.find(
                                            option => option?.name === value,
                                          )?.id,
                                        },
                                        totalCost: newCharge,
                                      });
                                      return;
                                    }

                                    handleChange(index, {
                                      ...item,
                                      costType: {
                                        name: value,
                                        id: options?.find(
                                          option => option?.name === value,
                                        )?.id,
                                      },
                                      unitCost: newCharge,
                                      totalCost: newCharge * item.unitCount,
                                    });
                                  }}
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left',
                                    },
                                  }}
                                >
                                  {options?.map(option => (
                                    <ECMenuItem value={option?.name}>
                                      {option?.name}
                                    </ECMenuItem>
                                  ))}
                                </ECSelect>
                              ) : (
                                <ECTypography
                                  variant="caption"
                                  sx={theme => ({
                                    color: theme.palette.text.secondary,
                                  })}
                                >
                                  {item?.costType?.name}
                                </ECTypography>
                              )}
                            </ECFormControl>
                          </ECTableCell>

                          <ECTableCell
                            sx={{
                              border: 0,
                              py: isEditing ? 0.5 : 0,
                              visibility: isChargeWithOnlyAmountField
                                ? 'hidden'
                                : undefined,
                            }}
                          >
                            {isEditing && !readOnly ? (
                              <ECFormControl fullWidth>
                                <ECCurrencyField
                                  hiddenLabel
                                  value={item.unitCost}
                                  id={`${item.id}-unitCost`}
                                  variant="filled"
                                  onChange={(e, value) => {
                                    handleChange(index, {
                                      ...item,
                                      unitCost: value,
                                      totalCost: Number(value) * item.unitCount,
                                    });
                                  }}
                                />
                              </ECFormControl>
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {currencyFormatter.format(item.unitCost)}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          <ECTableCell
                            sx={{
                              border: 0,
                              py: isEditing ? 0.5 : 0,
                              display: 'flex',
                              alignItems: isEditing ? undefined : 'center',
                              visibility: isChargeWithOnlyAmountField
                                ? 'hidden'
                                : undefined,
                            }}
                          >
                            {isEditing && !readOnly ? (
                              <ECTextField
                                value={item.unitCount}
                                id={`${item.id}-unitCount`}
                                variant="filled"
                                inputProps={{
                                  min: 0,
                                }}
                                hiddenLabel
                                onChange={e => {
                                  const value = formatClearedDecimal(
                                    e.target.value,
                                  );

                                  handleChange(index, {
                                    ...item,
                                    unitCount: value,
                                    totalCost: item.unitCost * Number(value),
                                  });
                                }}
                              />
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {item.unitCount}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          <ECTableCell
                            sx={{ border: 0, py: isEditing ? 0.5 : 0 }}
                          >
                            {isEditing && !readOnly ? (
                              <ECCurrencyField
                                hiddenLabel
                                id={`${item.id}-totalCost`}
                                value={item.totalCost}
                                variant={
                                  isChargeWithOnlyAmountField
                                    ? 'filled'
                                    : 'standard'
                                }
                                onChange={(e, value) => {
                                  handleChange(index, {
                                    ...item,
                                    totalCost: value,
                                  });
                                }}
                                readOnly={!isChargeWithOnlyAmountField}
                              />
                            ) : (
                              <ECTypography
                                variant="caption"
                                sx={theme => ({
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                {currencyFormatter.format(
                                  item.unitCost * item.unitCount,
                                )}
                              </ECTypography>
                            )}
                          </ECTableCell>

                          {isEditing &&
                            !readOnly &&
                            (charges?.length !== 1 || name !== 'Labor') && (
                              <ECTableCell
                                sx={{
                                  border: 0,
                                  px: 0,
                                  py: isEditing ? 0.5 : 0,
                                }}
                              >
                                <ECIconButton
                                  type="error"
                                  onClick={() => handleDelete(index)}
                                >
                                  <Close />
                                </ECIconButton>
                              </ECTableCell>
                            )}
                        </ECTableRow>

                        {!isFlatRateCharge && (
                          <ECTableRow
                            key={`${item.id}-labor-alert`}
                            id={`${item.id}-alert`}
                            sx={{
                              bgcolor: theme =>
                                `${theme.palette.common.white} !important`,
                            }}
                          >
                            <ECTableCell
                              colSpan={4}
                              sx={{ border: 0, py: isEditing ? 0.5 : 0 }}
                            >
                              {charge ===
                              (isChargeWithOnlyAmountField
                                ? Number(item?.totalCost)
                                : Number(item?.unitCost)) ? (
                                <ECAlert
                                  severity="info"
                                  sx={{ whiteSpace: 'break-spaces' }}
                                >
                                  The {item?.costType?.name} rate matches{' '}
                                  {isCustomer
                                    ? `the Service Provider's`
                                    : 'your'}{' '}
                                  rate of {currencyFormatter.format(charge)}
                                </ECAlert>
                              ) : (
                                <ECAlert
                                  severity={isCustomer ? 'error' : 'warning'}
                                  sx={{ whiteSpace: 'break-spaces' }}
                                >
                                  The {item?.costType?.name} rate does not match{' '}
                                  {isCustomer
                                    ? `the Service Provider's`
                                    : 'your'}{' '}
                                  rate of {currencyFormatter.format(charge)}
                                </ECAlert>
                              )}
                            </ECTableCell>
                          </ECTableRow>
                        )}
                      </>
                    );
                  })}

                  {isEditing && !readOnly && (
                    <ECTableRow
                      sx={{
                        bgcolor: theme =>
                          `${theme.palette.common.white} !important`,
                      }}
                    >
                      <ECTableCell colSpan={4} sx={{ border: 0 }}>
                        <ECButton
                          variant="text"
                          color="primary"
                          onClick={handleAddNew}
                        >
                          + Add Charge
                        </ECButton>
                      </ECTableCell>
                    </ECTableRow>
                  )}
                </ECTableBody>
              </MainTable>
            </ECTableContainer>
          </ECBox>
        </ECTableCell>
      </ECTableRow>
    </>
  );
};
