import {
  ECBox,
  ECButton,
  ECCard,
  ECCardContent,
  ECStack,
  ECSwitch,
  ECTextField,
  ECTypography,
} from 'app/components';
import {
  ECWorkflowSummaryField,
  SummaryFieldTypes,
} from 'app/components/ECWorkflowSummaryField';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCustomerUser } from 'app/hooks';
import { EditRTDrawerButton } from './EditRTDrawerButton';
import { emptyCircuit } from 'app/components/ECRefrigerantTracking';
import { InputAdornment } from '@mui/material';

interface Circuit {
  id: number;
  name?: string;
  charge?: string;
  method?: string;
  annualLeakRate?: number;
  chargeTotal?: number;
  description?: string;
  measurementUnitId?: number;
  measurementUnit?: any;
  refrigerantMethod?: any;
  refrigerantMethodId?: number;
  refrigerantTypeId?: number;
  type?: string;
  refrigerantType?: any;
  chargeOunces?: number;
  chargePound?: number;
}

export interface RTCircuitsCardProps {
  assetId?: number;
  branchId?: number;
  assetTypeId?: number;
  title?: string;
  generalStatus?: any;
  epaApplianceType?: any;
  refrigerantInitalSp?: any;
  circuits: Circuit[];
  showCircuits?: boolean;
  showViewAllButton?: boolean;
  hideRefrigerantChargeSummary?: boolean;
  editMode?: boolean;
  validationMessage?: string;
  onChange?: (value: any[]) => void;
  useFullWidthCircuitCards?: boolean;
  showEditAlert?: boolean;
}

export const RTCircuitsCard = ({
  title = 'Refrigerant Charge',
  generalStatus,
  epaApplianceType,
  circuits: initialCircuits,
  showCircuits = true,
  showViewAllButton,
  refrigerantInitalSp,
  assetId,
  branchId,
  assetTypeId,
  hideRefrigerantChargeSummary = false,
  editMode,
  validationMessage,
  onChange,
  useFullWidthCircuitCards = false,
  showEditAlert = false,
}: RTCircuitsCardProps) => {
  const navigate = useNavigate();
  // only customers can edit
  const showEditButton = useCustomerUser();

  const [circuitRows, setCircuitRows] = useState<any[]>([]);
  useEffect(() => {
    const initialCircuitRows = initialCircuits?.map(circuit => ({
      ...circuit,
      repairOnThisCircuit: false,
    }));

    setCircuitRows(initialCircuitRows || []);
  }, [initialCircuits, editMode]);

  const handleClickViewAll = useCallback(() => {
    navigate('./refrigerant');
  }, [navigate]);

  useEffect(() => {
    if (editMode) onChange?.(circuitRows);
  }, [onChange, circuitRows, editMode]);

  return (
    <ECBox p={1} position={'relative'}>
      {showEditAlert && (
        <ECBox
          position="absolute"
          top={'50%'}
          left={'50%'}
          borderRadius={1}
          sx={{
            transform: 'translate(-50%, -50%)',
            opacity: '1',
          }}
          bgcolor="#757575"
          zIndex={100}
          p={2}
        >
          <ECTypography
            variant="h6"
            fontSize="14px"
            color="#FFF"
            fontWeight="400"
            textAlign={'center'}
          >
            Edit Refrigerant Details on the Refrigerant Charges Tab.
          </ECTypography>
        </ECBox>
      )}
      <ECBox sx={{ opacity: showEditAlert ? '0.7' : undefined }}>
        {hideRefrigerantChargeSummary ? null : (
          <>
            <ECBox display="flex" justifyContent="space-between">
              <ECTypography variant="h6" fontSize="20px" mb={2}>
                {title}
              </ECTypography>
              {showViewAllButton && (
                <ECButton
                  variant="text"
                  color="primary"
                  onClick={handleClickViewAll}
                  disabled={showEditAlert}
                >
                  View All
                </ECButton>
              )}
              {showEditButton && assetId && assetTypeId && branchId && (
                <EditRTDrawerButton
                  assetId={assetId}
                  branchId={branchId}
                  assetTypeId={assetTypeId}
                  value={{
                    epaApplianceType: epaApplianceType,
                    initialChargeServiceProvider: refrigerantInitalSp,
                    circuits: initialCircuits.length
                      ? initialCircuits.map(c => ({
                          id: c.id,
                          circuitName: c.name,
                          refrigerantType: {
                            ...c.refrigerantType,
                            id: c.refrigerantTypeId,
                          },
                          chargelbs: c.chargePound,
                          chargeoz: c.chargeOunces,
                          method: {
                            ...c.refrigerantMethod,
                            id: c.refrigerantMethodId,
                          },
                        }))
                      : [emptyCircuit],
                  }}
                />
              )}
            </ECBox>
            <ECWorkflowSummaryField
              key={`general-status`}
              labelWidth="200px"
              field={{
                id: 'general-status',
                label: 'General Status',
                data: generalStatus,
                type: SummaryFieldTypes.SingleChip,
              }}
              isEditMode={false}
            />
            <ECWorkflowSummaryField
              key={`EPA-appliance-type`}
              labelWidth="200px"
              field={{
                id: 'EPA-appliance-type',
                label: 'EPA appliance type',
                data: epaApplianceType?.name,
                type: SummaryFieldTypes.Text,
              }}
              isEditMode={false}
            />
            <ECWorkflowSummaryField
              key={`last-charge-sp`}
              labelWidth="200px"
              field={{
                id: 'last-charge-sp',
                label: 'Last Charge SP',
                data: refrigerantInitalSp?.name,
                type: SummaryFieldTypes.Text,
              }}
              isEditMode={false}
            />
          </>
        )}

        {showCircuits && (
          <ECBox
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent="flex-start"
          >
            {(editMode ? circuitRows : initialCircuits)?.map(
              (circuit, index) => {
                const currentCircuitRepairFlag =
                  circuit?.repairOnThisCircuit || false;
                return (
                  <ECCard
                    sx={theme => ({
                      flex: useFullWidthCircuitCards
                        ? undefined
                        : '1 1 calc(50% - 16px)',
                      p: 2,
                      width: useFullWidthCircuitCards ? '100%' : undefined,
                      borderRadius: '4px',
                      border: '1px solid rgba(0, 0, 0, 0.26)',
                      background: '#FFF',
                      boxShadow: theme.shadows[4],
                      height: editMode
                        ? currentCircuitRepairFlag
                          ? 'auto'
                          : '150px'
                        : 'auto',
                    })}
                    square
                    key={`circuit-${circuit.id}`}
                  >
                    <ECCardContent sx={{ padding: '0px !important' }}>
                      <ECBox display={'flex'} mb={1}>
                        <ECTypography variant="h6" fontSize="20px">
                          Circuit {index + 1}
                        </ECTypography>
                        <ECTypography
                          variant="body1"
                          fontSize="20px"
                          ml={1}
                          sx={{ opacity: 0.5 }}
                        >
                          ({circuit.name})
                        </ECTypography>
                      </ECBox>
                      {editMode ? (
                        <>
                          <ECBox
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            padding={1}
                          >
                            <ECTypography
                              sx={theme => ({
                                color: theme.palette.text.primary,
                              })}
                            >
                              Repair on this Circuit
                            </ECTypography>
                            <ECStack direction="row" alignItems="center">
                              <ECSwitch
                                id={`enable-circuit-${circuit.id}`}
                                checked={currentCircuitRepairFlag}
                                value={currentCircuitRepairFlag}
                                onChange={e => {
                                  setCircuitRows(prevState =>
                                    prevState.map(row =>
                                      row.id === circuit.id
                                        ? {
                                            ...row,
                                            repairOnThisCircuit:
                                              e.target.checked,
                                          }
                                        : row,
                                    ),
                                  );
                                }}
                              />
                              <ECTypography
                                sx={theme => ({
                                  color: theme.palette.text.primary,
                                })}
                              >
                                {currentCircuitRepairFlag ? 'Yes' : 'No'}
                              </ECTypography>
                            </ECStack>
                            <ECBox
                              width="75%"
                              height="1px"
                              bgcolor={theme => theme.palette.other.divider}
                            />
                          </ECBox>
                          <ECBox
                            sx={{
                              display: currentCircuitRepairFlag
                                ? 'block'
                                : 'none',
                            }}
                          >
                            <ECBox width="100%" display="flex" padding={1}>
                              <ECTextField
                                variant="standard"
                                placeholder="Refrigerant Type"
                                label="Refrigerant Type"
                                value={circuit?.type}
                                inputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </ECBox>
                            <ECBox width="100%" display="flex" padding={1}>
                              <ECTextField
                                variant="standard"
                                placeholder="Method"
                                label="Method"
                                value={circuit?.method}
                                inputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </ECBox>
                            <ECBox
                              width="100%"
                              display="flex"
                              padding={1}
                              gap={2}
                            >
                              <ECTextField
                                fullWidth
                                variant="filled"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <ECTypography
                                        color={theme =>
                                          !!validationMessage &&
                                          !Number(circuit.chargelbs) &&
                                          !Number(circuit.chargeoz)
                                            ? theme.palette.error.dark
                                            : theme.palette.text.secondary
                                        }
                                      >
                                        lbs
                                      </ECTypography>
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                value={circuit.chargelbs}
                                error={
                                  !!validationMessage &&
                                  !Number(circuit.chargelbs) &&
                                  !Number(circuit.chargeoz)
                                }
                                onChange={e => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(
                                      inputValue,
                                    ) ||
                                    inputValue === ''
                                  ) {
                                    const newCircuitRows = [...circuitRows];
                                    newCircuitRows[index] = {
                                      ...newCircuitRows[index],
                                      chargelbs: inputValue,
                                    };
                                    setCircuitRows(newCircuitRows);
                                  }
                                }}
                              />
                              <ECTextField
                                fullWidth
                                variant="filled"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <ECTypography
                                        color={theme =>
                                          !!validationMessage &&
                                          !Number(circuit.chargelbs) &&
                                          !Number(circuit.chargeoz)
                                            ? theme.palette.error.dark
                                            : theme.palette.text.secondary
                                        }
                                      >
                                        oz
                                      </ECTypography>
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                value={circuit.chargeoz}
                                error={
                                  !!validationMessage &&
                                  !Number(circuit.chargelbs) &&
                                  !Number(circuit.chargeoz)
                                }
                                onChange={e => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(
                                      inputValue,
                                    ) ||
                                    inputValue === ''
                                  ) {
                                    const newCircuitRows = [...circuitRows];
                                    newCircuitRows[index] = {
                                      ...newCircuitRows[index],
                                      chargeoz: inputValue,
                                    };
                                    setCircuitRows(newCircuitRows);
                                  }
                                }}
                              />
                            </ECBox>
                          </ECBox>
                        </>
                      ) : (
                        <>
                          <ECWorkflowSummaryField
                            key={`refrigerant-type-${circuit.id}`}
                            labelWidth="195px"
                            field={{
                              id: 'refrigerant-type',
                              label: 'Refrigerant Types',
                              data:
                                circuit.refrigerantType?.name || circuit.type,
                              type: SummaryFieldTypes.Text,
                              isEditable: true,
                            }}
                            isEditMode={false}
                          />
                          <ECWorkflowSummaryField
                            key={`charge-${circuit.id}`}
                            labelWidth="195px"
                            field={{
                              id: 'charge',
                              label: 'Charge',
                              data: circuit.chargeTotal ?? circuit.charge,
                              type: SummaryFieldTypes.Text,
                            }}
                            isEditMode={false}
                          />
                          <ECWorkflowSummaryField
                            key={`method-${circuit.id}`}
                            labelWidth="195px"
                            field={{
                              id: 'method',
                              label: 'Method',
                              data:
                                circuit.refrigerantMethod?.name ||
                                circuit.method,
                              type: SummaryFieldTypes.Text,
                            }}
                            isEditMode={false}
                          />
                          <ECWorkflowSummaryField
                            key={`annual-leak-rate-${circuit.id}`}
                            labelWidth="195px"
                            field={{
                              id: 'annual-leak-rate',
                              label: 'Annual Leak Rate',
                              data: circuit.annualLeakRate || null,
                              type: SummaryFieldTypes.Text,
                            }}
                            isEditMode={false}
                          />
                        </>
                      )}
                    </ECCardContent>
                  </ECCard>
                );
              },
            )}
            {initialCircuits?.length % 2 === 1 && (
              <ECBox
                sx={{ flex: '1 1 calc(50% - 16px)', visibility: 'hidden' }}
              />
            )}
          </ECBox>
        )}
      </ECBox>
    </ECBox>
  );
};
