import { ECBox, ECDynamicPageTemplate } from 'app/components';
import { useParams } from 'react-router';
import {
  useLazyGetAssetCircuitsListQuery,
  useLazyGetAssetByIdQuery,
} from 'services/assetApi';
import { Skeleton } from '@mui/material';
import {
  useGetWORefrigerantTrackingQuery,
  useGetWorkOrderByIdQuery,
} from 'services/workOrdersApi';
import { useCustomerUser } from 'app/hooks';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';
import { useEffect, useMemo } from 'react';
import { RTCard } from './RTCard';

export function RefrigerantTrackingPage() {
  const { id } = useParams();

  const isCustomer = useCustomerUser();
  const [hasEditWorkorderPermission] = useHasPermission([P.EditWorkOrder]);

  const { data: workOrder } = useGetWorkOrderByIdQuery(
    { id: Number(id) },
    { skip: !id },
  );

  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery({});
  const { data: assetObject, isSuccess: isSuccessAsset } = resultAsset;

  const isInternalManagedWO = Boolean(workOrder?.internal);

  useEffect(() => {
    if (workOrder?.assetId) {
      triggerAsset(workOrder.assetId);
    }
  }, [workOrder?.assetId, triggerAsset]);

  const canEditRTOnWorkOrder = useMemo(() => {
    if (!isSuccessAsset) return false;
    if (isCustomer)
      return (
        hasEditWorkorderPermission &&
        isInternalManagedWO &&
        Boolean(assetObject?.refrigerantTrackingEnable)
      );
    return (
      hasEditWorkorderPermission &&
      Boolean(assetObject?.refrigerantTrackingEnable)
    );
  }, [
    hasEditWorkorderPermission,
    isCustomer,
    isInternalManagedWO,
    assetObject?.refrigerantTrackingEnable,
    isSuccessAsset,
  ]);

  const { data, isLoading } = useGetWORefrigerantTrackingQuery(id, {
    skip: !id,
  });

  return (
    <>
      {isLoading ? (
        <ECBox pl={1}>
          <Skeleton
            variant="rectangular"
            height={32}
            width={'25%'}
            sx={{ marginBottom: 2, marginTop: 1 }}
          />
          {Array.from(new Array(3)).map((_, index) => (
            <Skeleton
              variant="rectangular"
              key={index}
              height={32}
              width={'50%'}
              sx={{ marginBottom: 0.5 }}
            />
          ))}
        </ECBox>
      ) : (
        <RTCard
          woId={id ?? ''}
          initialData={data}
          showEditButton={canEditRTOnWorkOrder}
        />
      )}
      {data?.circuits?.length === 0 && (
        <ECDynamicPageTemplate
          marginTop={false}
          pageTitle="Refrigerant Charges"
          useLazyGetListQuery={useLazyGetAssetCircuitsListQuery}
          idToGetListBy={id}
          useUpdateMutation={{}}
          useCreateMutation={{}}
          hideSimpleSearchBar
          useSkeletonLoading
          // I'm usingGlobalSearch using this to hide the DIV
          //usingGlobalSearch
        />
      )}
    </>
  );
}
