import { ECFormControlLabel } from 'app/components/ECForm';
import { useEffect, useMemo, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECGrid,
  ECRadio,
  ECRadioGroup,
  ECStack,
  ECTypography,
} from 'app/components';
import { useLazySendInviteQuery } from 'services/serviceProviderApi';
import { ECEasyFormGridItem } from 'app/components/ECForm/ECEasyFormGridItem';
import { ECEasyFormSectionGridItem } from 'app/components/ECForm/ECFormCell';
import { Alert, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyEntity } from 'types/Company';
import { formatPhone } from 'utils/strings/phone';
import { setSnackbar } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { ServiceProviderInvitationCreate } from 'types/ServiceProviderTypes';
import { Contact } from './step-1';

interface Step2Props {
  existingData?: any;
  contacts?: Contact[];
  companies?: CompanyEntity[];
  onClose?: () => void;
}

export const Step2 = ({
  existingData,
  contacts,
  companies,
  onClose,
}: Step2Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedCompany, setSelectedCompany] = useState<number | string>(0);

  const [triggerInvite, resultInvite] = useLazySendInviteQuery();

  const { data, isFetching, isLoading, isSuccess, error, isError } =
    resultInvite;

  const handleInviteClick = async () => {
    const company = companies?.find(
      company => company.email === selectedCompany,
    );
    let invitationData: ServiceProviderInvitationCreate = {
      spEmail: company ? company.email : existingData.email,
      spName: company ? company.name : existingData.name,
      spPhone: company ? company.phone : existingData.phone,
      spPhoneExt: company ? company.phoneExt : existingData.phoneExt,
      smsNotification: existingData.smsNotification ? 1 : 0,
      vendorId: existingData.vendorId,
    };
    if (company) {
      invitationData.spId = company.id;
    }
    if (
      contacts &&
      contacts.length > 0 &&
      Object.keys(contacts[0]).length > 0
    ) {
      invitationData.contacts = contacts.map(contact => ({
        name: invitationData.spName,
        email: contact.email,
        phone: contact.phone,
        ext: contact.ext,
        smsNotification: contact.smsNotification ? 1 : 0,
      }));
    }
    triggerInvite(invitationData);
  };

  useEffect(() => {
    if (isSuccess && data?.id) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: t(
            'translation:pages.serviceProviderInvite.invitationSuccess',
          ),
        }),
      );
      onClose?.();
    } else if (isError && error) {
      const errorResponse = error as any;
      const message =
        errorResponse?.data?.message || errorResponse?.data?.description;
      dispatch(
        setSnackbar({
          severity: 'error',
          message: message,
        }),
      );
    }
  }, [isSuccess, data, isFetching, isLoading, isError, error]);

  const { name, email, phone } = existingData;

  // checks if email is exact match and sp is already working with company
  // renders sameEmailLink
  const renderSameEmailAndLink = useMemo(
    () => companies?.find(comp => comp.email === email && comp.linkIsActive),
    [companies, email],
  );

  // this renders companies that are already working that match data and cant be invited
  // renders alreadyWorking
  const renderAlreadyWorking = useMemo(
    () => companies?.filter(comp => comp.email !== email && comp.linkIsActive),
    [companies, email],
  );

  // checks if email is exact, is not working already and invitation is pending
  // renders alreadyInvited
  const renderAlreadyInvited = useMemo(
    () =>
      companies?.find(
        comp =>
          comp.email === email &&
          comp.invitationIsPending &&
          !comp.linkIsActive,
      ),
    [companies, email],
  );

  const renderExactMatchRegistered = useMemo(
    () =>
      companies?.filter(
        comp =>
          !comp.linkIsActive &&
          !comp.invitationIsPending &&
          comp.email === email,
      ),
    [companies, email],
  );

  // checks for SPs that exist in ecotrak but not working with - user can invite all these sp
  // renders alreadyRegistered
  const renderAlreadyRegistered = useMemo(
    () =>
      companies?.filter(
        comp =>
          !comp.linkIsActive &&
          !comp.invitationIsPending &&
          !(
            comp.email === email &&
            comp.name === name &&
            comp.phone === phone
          ) &&
          renderExactMatchRegistered?.length === 0,
      ),
    [companies, email, name, phone, renderExactMatchRegistered],
  );

  // checks if NO equal email at all in companies -
  // renders notRegistered
  const shouldRenderNotRegistered = !companies?.some(
    comp => comp.email === email,
  );

  const shouldRenderAlreadyRegistered = !!renderAlreadyRegistered?.length;
  const shouldRenderExactMatchRegistered = !!renderExactMatchRegistered?.length;

  const renderCompanyInfo = (
    name,
    phone,
    email,
    contacts,
    contactTitle,
    address,
    isSelected = false,
  ) => (
    <ECBox
      border={
        isSelected
          ? '2px solid rgba(0, 0, 255, .26)'
          : '2px solid rgba(0, 0, 0, .26)'
      }
      borderColor={theme =>
        isSelected ? theme.palette.primary.main : theme.palette.action.disabled
      }
      borderRadius={1}
      p={1}
      display="flex"
      flexDirection="column"
    >
      <ECBox>
        <ECTypography
          variant="h3"
          sx={{ marginBottom: '1rem' }}
          color={theme => theme.palette.text.primary}
        >
          {name}
        </ECTypography>
      </ECBox>

      {address && (
        <ECBox display="flex">
          <ECTypography fontWeight="bold">Company Address: &nbsp;</ECTypography>
          <ECTypography color={theme => theme.palette.text.secondary}>
            {address.line1} {address.line2} {address.line3} {address.cityName}{' '}
            {address.stateProvinceCode} {address.zipCodePostalCode}
          </ECTypography>
        </ECBox>
      )}

      <ECBox display="flex">
        <ECTypography fontWeight="bold">Company Email: &nbsp;</ECTypography>
        <ECTypography color={theme => theme.palette.text.secondary}>
          {email}
        </ECTypography>
      </ECBox>

      <ECBox display="flex">
        <ECTypography fontWeight="bold">Company Phone: &nbsp;</ECTypography>
        <ECTypography color={theme => theme.palette.text.secondary}>
          {formatPhone(phone)}
        </ECTypography>
      </ECBox>

      {contacts &&
        contacts.length > 0 &&
        Object.keys(contacts[0]).length > 0 && (
          <>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

            <ECBox>
              <ECTypography
                variant="h6"
                sx={{ marginBottom: '1rem' }}
                color={theme => theme.palette.text.primary}
              >
                {contactTitle}
              </ECTypography>
            </ECBox>

            <ECGrid
              container
              display="grid"
              gridTemplateColumns="1fr 1fr"
              mb={3}
            >
              {contacts.map((contact, index) => (
                <ECGrid
                  item
                  mb={1}
                  justifySelf={index % 2 !== 0 ? 'flex-end' : undefined}
                >
                  <ECTypography
                    variant="body1"
                    color={theme => theme.palette.text.secondary}
                  >
                    {contact.email} &nbsp;
                    {formatPhone(contact.phone)}
                  </ECTypography>
                </ECGrid>
              ))}
            </ECGrid>
          </>
        )}
    </ECBox>
  );

  useEffect(() => {
    if (shouldRenderNotRegistered && !shouldRenderAlreadyRegistered) {
      setSelectedCompany(1);
    } else if (shouldRenderExactMatchRegistered) {
      setSelectedCompany(renderExactMatchRegistered?.[0]?.email || 1);
    } else if (shouldRenderAlreadyRegistered) {
      setSelectedCompany(renderAlreadyRegistered?.[0]?.email || 1);
    }
  }, [
    shouldRenderNotRegistered,
    shouldRenderAlreadyRegistered,
    shouldRenderExactMatchRegistered,
    renderAlreadyRegistered,
    renderExactMatchRegistered,
  ]);

  return (
    <>
      <ECBox
        display="flex"
        flexDirection="column"
        sx={{ width: '100%', padding: '2rem' }}
      >
        {!shouldRenderNotRegistered && (
          <ECEasyFormSectionGridItem>
            <ECTypography
              variant="h6"
              sx={{ marginBottom: '1rem' }}
              color={theme => theme.palette.text.primary}
            >
              Confirm Service Provider
            </ECTypography>
          </ECEasyFormSectionGridItem>
        )}

        <ECGrid container spacing={2} mb="2rem">
          <>
            {shouldRenderNotRegistered && (
              <>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECTypography
                    variant="h6"
                    sx={{ marginBottom: '1rem' }}
                    color={theme => theme.palette.text.primary}
                  >
                    Create a new Service Provider
                  </ECTypography>
                </ECEasyFormGridItem>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECRadioGroup
                    row
                    id="radio-group-companies-any-attribute"
                    name="radio-group-companies-any-attribute"
                    value={selectedCompany}
                    onChange={event => {
                      const eventValue = (event.target as HTMLInputElement)
                        .value;
                      setSelectedCompany(eventValue);
                    }}
                  >
                    <ECFormControlLabel
                      control={<ECRadio />}
                      sx={{
                        width: '100%',
                        my: 1,
                        '& .MuiFormControlLabel-label': { width: '100%' },
                      }}
                      value={1}
                      label={renderCompanyInfo(
                        name,
                        phone,
                        email,
                        contacts,
                        'NEW Invitation Contacts',
                        null,
                        selectedCompany == 0,
                      )}
                    />
                  </ECRadioGroup>
                </ECEasyFormGridItem>
              </>
            )}
            {renderSameEmailAndLink && (
              <ECEasyFormGridItem config={{ cols: 1 }}>
                <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{ marginBottom: 2 }}
                >
                  {t('translation:pages.serviceProviderInvite.sameEmailLink')}
                </Alert>
                <>
                  {renderCompanyInfo(
                    renderSameEmailAndLink.name,
                    renderSameEmailAndLink.phone,
                    renderSameEmailAndLink.email,
                    contacts,
                    'NEW Invitation Contacts',
                    null,
                  )}
                </>
              </ECEasyFormGridItem>
            )}
            {renderAlreadyWorking && renderAlreadyWorking.length > 0 && (
              <ECEasyFormGridItem config={{ cols: 1 }}>
                <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{ marginBottom: 2 }}
                >
                  {t('translation:pages.serviceProviderInvite.alreadyWorking')}
                </Alert>
                {renderAlreadyWorking.map(company => (
                  <>
                    {renderCompanyInfo(
                      company.name,
                      company.phone,
                      company.email,
                      contacts,
                      'NEW Invitation Contacts',
                      null,
                    )}
                  </>
                ))}
              </ECEasyFormGridItem>
            )}
            {renderAlreadyInvited && (
              <>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <Alert variant="outlined" severity="warning">
                    {t(
                      'translation:pages.serviceProviderInvite.alreadyInvited',
                    )}
                  </Alert>
                </ECEasyFormGridItem>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECRadioGroup
                    row
                    id="radio-group-companies-any-attribute"
                    name="radio-group-companies-any-attribute"
                    value={false}
                    onChange={event => {
                      const eventValue = (event.target as HTMLInputElement)
                        .value;
                      setSelectedCompany(eventValue);
                    }}
                  >
                    <ECFormControlLabel
                      control={<ECRadio />}
                      sx={{
                        width: '100%',
                        my: 1,
                        '& .MuiFormControlLabel-label': { width: '100%' },
                      }}
                      disabled={true}
                      value={1}
                      label={renderCompanyInfo(
                        name,
                        phone,
                        email,
                        contacts,
                        'NEW Invitation Contacts',
                        null,
                        selectedCompany == 0,
                      )}
                    />
                  </ECRadioGroup>
                </ECEasyFormGridItem>
              </>
            )}
            {renderExactMatchRegistered &&
              renderExactMatchRegistered?.length > 0 && (
                <>
                  <ECEasyFormGridItem config={{ cols: 1 }}>
                    <Alert variant="outlined" severity="info">
                      {t('translation:pages.serviceProviderInvite.exactMatch')}
                    </Alert>
                  </ECEasyFormGridItem>
                  <ECEasyFormGridItem config={{ cols: 1 }}>
                    <ECRadioGroup
                      row
                      id="radio-group-companies-any-attribute"
                      name="radio-group-companies-any-attribute"
                      value={selectedCompany}
                      onChange={event => {
                        const eventValue = (event.target as HTMLInputElement)
                          .value;
                        setSelectedCompany(eventValue);
                      }}
                    >
                      {renderExactMatchRegistered?.map(company => (
                        <ECFormControlLabel
                          control={<ECRadio />}
                          sx={{
                            width: '100%',
                            my: 1,
                            '& .MuiFormControlLabel-label': { width: '100%' },
                          }}
                          value={company.email}
                          label={renderCompanyInfo(
                            company.name,
                            company.phone,
                            company.email,
                            contacts,
                            'NEW Invitation Contacts',
                            null,
                            selectedCompany === company.email,
                          )}
                        />
                      ))}
                    </ECRadioGroup>
                  </ECEasyFormGridItem>
                </>
              )}
            {renderAlreadyRegistered && renderAlreadyRegistered.length > 0 && (
              <>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECTypography
                    variant="h6"
                    sx={{ marginBottom: '1rem' }}
                    color={theme => theme.palette.text.primary}
                  >
                    Select from existing Service Providers
                  </ECTypography>
                  <Alert variant="outlined" severity="info">
                    {t(
                      'translation:pages.serviceProviderInvite.alreadyRegistered',
                    )}
                  </Alert>
                </ECEasyFormGridItem>
                <ECEasyFormGridItem config={{ cols: 1 }}>
                  <ECRadioGroup
                    row
                    id="radio-group-companies-any-attribute"
                    name="radio-group-companies-any-attribute"
                    value={selectedCompany}
                    onChange={event => {
                      const eventValue = (event.target as HTMLInputElement)
                        .value;
                      setSelectedCompany(eventValue);
                    }}
                  >
                    {renderAlreadyRegistered?.map(company => (
                      <ECFormControlLabel
                        control={<ECRadio />}
                        sx={{
                          width: '100%',
                          my: 1,
                          '& .MuiFormControlLabel-label': { width: '100%' },
                        }}
                        value={company.email}
                        label={renderCompanyInfo(
                          company.name,
                          company.phone,
                          company.email,
                          contacts,
                          'NEW Invitation Contacts',
                          null,
                          selectedCompany === company.email,
                        )}
                      />
                    ))}
                  </ECRadioGroup>
                </ECEasyFormGridItem>
              </>
            )}
          </>
        </ECGrid>

        <ECBox
          display="flex"
          sx={{ width: '100%' }}
          bgcolor={theme => theme.palette.marketingSuggestion.mainBackground}
        >
          <ECGrid
            container
            spacing={2}
            sx={{
              flexDirection: 'row',
              justifyContent: 'right',
              p: 1,
              padding: 3,
            }}
          >
            <ECEasyFormGridItem config={{ cols: 1 }}>
              <ECStack direction="row" justifyContent="right" spacing={2}>
                <ECButton
                  type="button"
                  variant="text"
                  sx={{ height: 42 }}
                  onClick={onClose}
                >
                  {t('translation:dynamicForm.cancel')}
                </ECButton>
                <ECButton
                  type={'submit'}
                  variant="contained"
                  sx={{
                    height: 42,
                    width: 'auto',
                  }}
                  disabled={!selectedCompany}
                  onClick={handleInviteClick}
                >
                  {t('translation:pages.serviceProviderInvite.invite')}
                </ECButton>
              </ECStack>
            </ECEasyFormGridItem>
          </ECGrid>
        </ECBox>
      </ECBox>
    </>
  );
};
