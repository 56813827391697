import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PanelNavigator } from '../PanelNavigator';
import {
  LoginPage,
  SSOLoginPage,
  MfaPage,
  SignUp,
  SignUpWizardPage,
} from '../../pages';
import {
  SendLinkForm,
  ResetPasswordForm,
  ForgotPasswordPage,
} from 'app/pages/ForgotPasswordPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { history } from 'utils/history';
import { useMatch } from 'react-router';
import { DecideUserPage } from 'app/pages/DecideUserPage';
import { CreateSPPage } from 'app/pages/CreateSPPage';
import { CreateSPFormPage } from 'app/pages/CreateSPFormPage';
import { CreateSPSuccessPage } from 'app/pages/CreateSPSuccessPage';
import { useAddRecaptchaScript } from 'app/hooks';
import { SignUpWizardCompletion } from 'app/pages/SignUpWizardConfirmationPage';

function RequireAuth({ children, redirectTo }) {
  const match = useMatch('/panel/*');
  const sessionToken = localStorage.getItem('idToken');
  return sessionToken ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ redirectedFrom: match?.pathname }} />
  );
}

const AppNavigatorWrapped = () => {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  // See https://jasonwatmore.com/react-router-6-navigate-outside-react-components
  history.navigate = useNavigate();
  history.location = useLocation();

  useAddRecaptchaScript();

  return (
    <Routes>
      <Route path={'/signup-wizard'} element={<SignUpWizardPage />} />
      <Route path={'/signup'} element={<SignUp />} />
      <Route path={'/signup/completion'} element={<SignUpWizardCompletion />} />
      <Route path={'/'} element={<SSOLoginPage />} />
      <Route path={'/emergency'} element={<SSOLoginPage />} />
      <Route path={'/login'} element={<LoginPage />} />
      <Route path={'/mfa'} element={<MfaPage />} />
      <Route path={'/forgot-password'} element={<ForgotPasswordPage />}>
        <Route path={'/forgot-password/send-link'} element={<SendLinkForm />} />
        <Route
          path={'/forgot-password/reset'}
          element={<ResetPasswordForm />}
        />
      </Route>
      <Route path={'/sp-self-signup'} element={<CreateSPPage />} />
      <Route path={`/home`} element={<DecideUserPage />} />
      <Route path={'/sp-self-signup/register'} element={<CreateSPFormPage />} />
      <Route
        path={'/sp-self-signup/success'}
        element={<CreateSPSuccessPage />}
      />
      <Route
        path={'/*'}
        element={
          <Navigate to={'/'} state={{ redirectedFrom: history.location }} />
        }
      />
      <Route
        path="/panel/*"
        element={
          // Good! Do your composition here instead of wrapping <Route>.
          // This is really just inverting the wrapping, but it's a lot
          // more clear which components expect which props.
          <RequireAuth redirectTo="/">
            <PanelNavigator />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AppNavigatorWrapped;
