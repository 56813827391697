import { ECTableCell, ECTableRow } from '../ECTable';
import { CostLaborItem } from 'types/CostLaborItem';
import { ServiceProviderRatesAndTerms } from 'types/ServiceProviderTypes';
import { LaborItemsSubTableCharges } from './labor-items-sub-table-charges';
import { ECTypography } from '../ECTypography';
import { useEffect } from 'react';
import { useGetLaborItemsQuery } from 'services/lookupApi';

export const TRAVEL_CHARGES_NAMES = [
  'Travel Time',
  'Trip Charge',
  'Truck Charge',
];

interface Props {
  costLaborItems: CostLaborItem[];
  ratesAndTerms: ServiceProviderRatesAndTerms;
  isEditing?: boolean;
  readOnly?: boolean;
  onChange: (value: any, label: string) => void;
}

export const LaborItemsSubTable = ({
  costLaborItems = [],
  ratesAndTerms,
  isEditing,
  readOnly,
  onChange,
}: Props) => {
  const travelCharges = costLaborItems?.filter(item =>
    TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
  );
  const laborCharges = costLaborItems?.filter(
    item => !TRAVEL_CHARGES_NAMES.includes(item?.costType?.name),
  );

  const { data: laborItemsOptions } = useGetLaborItemsQuery();

  useEffect(() => {
    if (
      isEditing &&
      !readOnly &&
      !costLaborItems?.length &&
      !!ratesAndTerms &&
      !!Object.keys(ratesAndTerms)?.length &&
      !!laborItemsOptions?.laborChargeItems?.length
    ) {
      const regularTimeCostType = laborItemsOptions?.laborChargeItems?.find(
        charge => charge.name === 'Regular Time',
      );

      onChange(
        [
          ...costLaborItems,
          {
            costType: regularTimeCostType,
            unitCost: ratesAndTerms?.['journeymanRegularTime'],
            unitCount: 0,
            totalCost: 0,
          },
        ],
        'Labor Charge',
      );
    }
  }, [isEditing, laborItemsOptions, costLaborItems, ratesAndTerms, readOnly]);

  if (!costLaborItems?.length && !isEditing) {
    return null;
  }

  return (
    <>
      <LaborItemsSubTableCharges
        name="Labor"
        allCharges={costLaborItems}
        charges={laborCharges}
        ratesAndTerms={ratesAndTerms}
        isEditing={isEditing}
        onChange={onChange}
        readOnly={readOnly}
      />

      {(!!travelCharges?.length || (isEditing && !readOnly)) && (
        <>
          <ECTableRow
            sx={{
              bgcolor: theme => `${theme.palette.action.hover} !important`,
            }}
          >
            <ECTableCell colSpan={2} sx={{ border: 0, px: 2 }}>
              <ECTypography
                sx={theme => ({ color: theme.palette.text.secondary })}
              >
                Travel
              </ECTypography>
            </ECTableCell>
          </ECTableRow>

          <LaborItemsSubTableCharges
            name="Travel"
            allCharges={costLaborItems}
            charges={travelCharges}
            ratesAndTerms={ratesAndTerms}
            isEditing={isEditing}
            onChange={onChange}
            readOnly={readOnly}
          />
        </>
      )}
    </>
  );
};
