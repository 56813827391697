import React, { useCallback, useEffect, useState } from 'react';
import { ECBox } from '../ECBox';
import { ECCheckbox } from '../ECCheckbox';
import { ECFormControlLabel, ECFormGroup } from '../ECForm';
import { ECAlert } from '../ECAlert';
import { ECStack } from '../ECStack';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import {
  useGetCalculationMethodsQuery,
  useGetEpaAppliancesQuery,
  useGetRefrigerantTypesQuery,
} from 'services/lookupApi';
import { useGetDesignatedSPsQuery } from 'services/serviceProviderApi';
import { ECAutocomplete } from '../ECAutocomplete';
import { ECButton } from '../ECButton';
import { ECTypography } from '../ECTypography';
import { ECTextField } from '../ECTextField';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import { ECMenuItem } from '../ECSelect';

export interface CircuitItem {
  id?: number;
  circuitName: string;
  refrigerantType?: any;
  chargelbs?: number;
  chargeoz?: number;
  method?: any;
}

interface ECRefrigerantTrackingProps {
  onChange?: (value: any[]) => void;
  value?: any | null;
  existingData?: any;
  options?: any[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  validationMessage?: string;
  trackingSPParams?: {
    assetTypeId?: number;
    branchId?: number;
    allocatedCircuits?: CircuitItem[];
    dontShowCheckBoxAndAlert?: boolean;
  };
  visible?: boolean;
}
export const emptyCircuit = {
  id: 0,
  circuitName: '',
  refrigerantType: null,
  chargelbs: '',
  chargeoz: '',
  method: null,
};

export const ECRefrigerantTracking: React.FC<ECRefrigerantTrackingProps> = ({
  onChange,
  value,
  trackingSPParams,
  validationMessage,
  existingData,
  visible: isVisible = true,
}) => {
  const [circuitRows, setCircuitRows] = useState<any[]>([emptyCircuit]);
  const [epaApplianceType, setEpaApplianceType] = useState<any>(null);
  const [initialChargeServiceProvider, setInitialChargeServiceProvider] =
    useState<any>(null);
  const [refrigerantTrackingEnable, setRefrigerantTrackingEnable] =
    useState<boolean>(isVisible);

  const shouldFetchSPdata =
    trackingSPParams?.branchId && trackingSPParams?.assetTypeId;

  const { data: refrigerantTypesData } = useGetRefrigerantTypesQuery();
  const { data: calculationMethodsData } = useGetCalculationMethodsQuery();

  useEffect(() => {
    if (existingData) {
      setRefrigerantTrackingEnable(true);
      setEpaApplianceType(existingData.epaApplianceType);
      setInitialChargeServiceProvider(
        existingData.initialChargeServiceProvider,
      );
      setCircuitRows(existingData.circuits);
      onChange?.(existingData);
    }
  }, []);

  const handleOnChangeCircuits = useCallback(
    circuits => {
      setCircuitRows(circuits);
      onChange?.({
        ...value,
        circuits,
        refrigerantTrackingEnable: refrigerantTrackingEnable,
      });
    },
    [value, onChange, refrigerantTrackingEnable],
  );

  const renderAutocompleteOption = useCallback(
    (props, option, { inputValue }) => {
      const searchIndex = (option.label || option.name)
        ?.toLowerCase()
        .indexOf(inputValue.toLowerCase());

      return (
        <ECMenuItem {...props} key={option.fieldName}>
          {searchIndex !== -1 && inputValue ? (
            <>
              {(option.label || option.name)?.substring(0, searchIndex)}
              <strong>
                {(option.label || option.name)?.substring(
                  searchIndex,
                  searchIndex + inputValue.length,
                )}
              </strong>
              {(option.label || option.name)?.substring(
                searchIndex + inputValue.length,
              )}
            </>
          ) : (
            option.label || option.name
          )}
        </ECMenuItem>
      );
    },
    [],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <ECBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <ECStack dir="column" width={'100%'}>
        {!trackingSPParams?.dontShowCheckBoxAndAlert && (
          <>
            <ECFormGroup sx={{ px: 1.5 }}>
              <ECFormControlLabel
                control={
                  <ECCheckbox
                    id="rdCheckbox"
                    name="refrigerantTracking"
                    checked={refrigerantTrackingEnable}
                    onChange={event => {
                      setRefrigerantTrackingEnable(prev => {
                        const newValue = !prev;
                        onChange?.({
                          ...value,
                          refrigerantTrackingEnabled: newValue,
                        });
                        return newValue;
                      });
                    }}
                    sx={{
                      '&.Mui-checked': {
                        color: theme => theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label="Enable Refrigerant Tracking"
              />
            </ECFormGroup>
            {refrigerantTrackingEnable && (
              <ECAlert severity="info" sx={{ width: '100%', marginTop: 1 }}>
                Refrigerant Tracking is only need for assets that have regularly
                scheduled EPA check-ins, such as a HVAC unit. Once you turn on
                the feature, it cannot be reverted.
              </ECAlert>
            )}
          </>
        )}
        {refrigerantTrackingEnable && (
          <>
            <ECAutocompletePaginated
              sx={{ width: '100%', marginTop: 2 }}
              fieldName="epaApplianceType"
              value={epaApplianceType}
              placeholder="EPA appliance type"
              label="EPA Appliance Type"
              variant="filled"
              required
              useQuery={useGetEpaAppliancesQuery}
              onChange={epa => {
                setEpaApplianceType(epa);
                onChange?.({
                  ...value,
                  epaApplianceType: epa,
                });
              }}
              error={
                refrigerantTrackingEnable &&
                !!validationMessage &&
                _.isNil(epaApplianceType)
              }
              validationMessage={
                _.isNil(epaApplianceType) && validationMessage
                  ? validationMessage
                  : undefined
              }
            />
            <ECAutocompletePaginated
              fieldName="initialChargeServiceProvider"
              placeholder="Initial Charge Service Provider"
              label="Initial Charge Service Provider"
              sx={{ width: '100%', marginTop: 2 }}
              value={initialChargeServiceProvider}
              useQuery={useGetDesignatedSPsQuery}
              shouldSkipQuery={!shouldFetchSPdata}
              obAlias="name"
              queryParams={
                shouldFetchSPdata
                  ? {
                      branchId: trackingSPParams?.branchId,
                      assetTypeId: trackingSPParams?.assetTypeId,
                    }
                  : {}
              }
              onChange={sp => {
                setInitialChargeServiceProvider(sp);
                onChange?.({
                  ...value,
                  initialChargeServiceProvider: sp,
                });
              }}
              error={
                refrigerantTrackingEnable &&
                !!validationMessage &&
                _.isNil(initialChargeServiceProvider)
              }
              validationMessage={
                _.isNil(initialChargeServiceProvider) && validationMessage
                  ? validationMessage
                  : undefined
              }
              variant="filled"
            />
            <ECBox
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 2,
                marginLeft: 0.1,
                width: '100%',
              }}
            >
              {[
                'Circuit Name',
                'Refrigerant Type',
                'Initial Charge',
                'Method',
              ].map((label, index) => (
                <ECBox
                  key={index}
                  sx={{
                    flex: '1 1 25%',
                    bgcolor: '#e5e5e5',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: index === 0 ? 'center' : 'flex-start',
                    borderTopLeftRadius: index === 0 ? '4px' : 0,
                    borderTopRightRadius: index === 3 ? '4px' : 0,
                  }}
                >
                  <ECTypography variant="subtitle1">{label}</ECTypography>
                </ECBox>
              ))}
            </ECBox>
            {Array.isArray(circuitRows) &&
              circuitRows?.map((circuit, index) => {
                return (
                  <ECBox
                    key={index}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginTop: 1,
                      marginLeft: 0.1,
                      width: '100%',
                      gap: 1,
                    }}
                  >
                    {/* Circuit Name */}
                    <ECBox sx={{ flex: '1 1 20%' }}>
                      <ECTextField
                        id={`CircuitName-${index}`}
                        fullWidth
                        variant="filled"
                        error={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !circuit.circuitName
                        }
                        helperText={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !circuit.circuitName
                            ? validationMessage
                            : undefined
                        }
                        size="small"
                        value={circuit.circuitName}
                        onChange={e => {
                          handleOnChangeCircuits(
                            circuitRows?.map((row, i) =>
                              i === index
                                ? { ...row, circuitName: e.target.value }
                                : row,
                            ),
                          );
                        }}
                      />
                    </ECBox>

                    {/* Refrigerant Type */}
                    <ECBox sx={{ flex: '1 1 20%' }}>
                      <ECAutocomplete
                        id={`RefrigerantType-${index}`}
                        options={refrigerantTypesData ?? []}
                        getOptionLabel={option => option?.name}
                        value={circuitRows[index].refrigerantType}
                        onChange={(event, value) => {
                          const newCircuitRows = [...circuitRows];
                          newCircuitRows[index].refrigerantType = value;
                          handleOnChangeCircuits(newCircuitRows);
                        }}
                        error={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          _.isNil(circuitRows[index].refrigerantType)
                        }
                        errorMessage={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          _.isNil(circuitRows[index].refrigerantType)
                            ? validationMessage
                            : undefined
                        }
                        renderOption={renderAutocompleteOption}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        size="small"
                      />
                    </ECBox>
                    {/* Charge (lbs and oz) */}
                    <ECBox
                      sx={{
                        flex: '1 1 26%',
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <ECTextField
                        fullWidth
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">lbs</InputAdornment>
                          ),
                        }}
                        size="small"
                        value={circuit.chargelbs}
                        error={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !Number(circuit.chargelbs) &&
                          !Number(circuit.chargeoz)
                        }
                        helperText={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !Number(circuit.chargelbs) &&
                          !Number(circuit.chargeoz)
                            ? validationMessage
                            : undefined
                        }
                        onChange={e => {
                          const inputValue = e.target.value;
                          if (
                            /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(inputValue) ||
                            inputValue === ''
                          ) {
                            const newCircuitRows = [...circuitRows];
                            newCircuitRows[index].chargelbs = inputValue;
                            handleOnChangeCircuits(newCircuitRows);
                          }
                        }}
                      />
                      <ECTextField
                        fullWidth
                        variant="filled"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">oz</InputAdornment>
                          ),
                        }}
                        size="small"
                        value={circuit.chargeoz}
                        error={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !Number(circuit.chargeoz) &&
                          !Number(circuit.chargelbs)
                        }
                        helperText={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          !Number(circuit.chargeoz) &&
                          !Number(circuit.chargelbs)
                            ? validationMessage
                            : undefined
                        }
                        onChange={e => {
                          const inputValue = e.target.value;
                          if (
                            /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(inputValue) ||
                            inputValue === ''
                          ) {
                            const newCircuitRows = [...circuitRows];
                            newCircuitRows[index].chargeoz = inputValue;
                            handleOnChangeCircuits(newCircuitRows);
                          }
                        }}
                      />
                    </ECBox>

                    {/* Method */}
                    <ECBox sx={{ flex: '1 1 22%' }}>
                      <ECAutocomplete
                        id={`Method-${index}`}
                        options={calculationMethodsData ?? []}
                        getOptionLabel={option => option?.name}
                        value={circuitRows[index].method}
                        onChange={(event, value) => {
                          const newCircuitRows = [...circuitRows];
                          newCircuitRows[index].method = value;
                          handleOnChangeCircuits(newCircuitRows);
                        }}
                        error={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          _.isNil(circuitRows[index].method)
                        }
                        errorMessage={
                          refrigerantTrackingEnable &&
                          !!validationMessage &&
                          _.isNil(circuitRows[index].method)
                            ? validationMessage
                            : undefined
                        }
                        renderOption={renderAutocompleteOption}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        size="small"
                      />
                    </ECBox>

                    {/* Remove Button */}
                    <ECBox
                      sx={{
                        flex: '1 1 8%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ECButton
                        color={'error'}
                        sx={{ visibility: index !== 0 ? 'visible' : 'hidden' }}
                        variant="text"
                        onClick={() => {
                          if (circuitRows.length === 1) {
                            handleOnChangeCircuits([emptyCircuit]);
                            return;
                          }
                          const newCircuitRows = circuitRows.filter(
                            (_, i) => i !== index,
                          );
                          handleOnChangeCircuits(newCircuitRows);
                        }}
                      >
                        <CloseIcon />
                      </ECButton>
                    </ECBox>
                  </ECBox>
                );
              })}
            <ECBox
              key="add-circuit"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: 1,
                marginLeft: 0.1,
                width: '100%',
                gap: 1,
              }}
            >
              <ECButton
                variant="text"
                color="info"
                startIcon={<AddIcon color="info" />}
                onClick={() => {
                  handleOnChangeCircuits([...circuitRows, emptyCircuit]);
                }}
              >
                Add Circuit
              </ECButton>
            </ECBox>
          </>
        )}
      </ECStack>
    </ECBox>
  );
};
