import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ECBox, ECWorkflowTemplate } from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { useParams, Outlet, useNavigate } from 'react-router';
import {
  useGetPreventiveMaintenanceByIdQuery,
  useUpdatePreventiveMaintenanceMutation,
} from 'services/preventiveMaintenanceApi';
import { useGetAssetTypesListQuery } from 'services/assetTypeApi';
import { useGetPrioritiesListQuery } from 'services/prioritiesApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useLazyGetTradesByAssetGroupIdQuery } from 'services/tradeApi';
import { useTranslation } from 'react-i18next';
import { P } from 'types/Permission';
import { setSnackbar } from 'store/slice/page';
import { ECButtonWithViewText } from 'app/components/ECButtonWithView';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { debounce } from 'lodash';

const fancyFormElementsEdit = require('../config/fancy_form_config_edit.json');
const formElementsDetails = require('../config/fancy_form_config_details.json');

enum PMNavViewType {
  invoice = '/panel/invoices',
  workOrder = '/panel/work-orders',
}

export function PreventiveMaintenanceDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [summaryFieldsValues, setSummaryFieldsValues] = useState<any>({});
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false);
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [_forceRefresh, setForceRefresh] = useState(new Date());
  const hasEditPMPermission = useHasPermission([P.EditPreventiveMaintenance]);

  const {
    data: pm,
    isSuccess: isSuccessPm,
    isFetching: isFetchingPm,
  } = useGetPreventiveMaintenanceByIdQuery(id ? Number(id) : 0);

  const [isActive, setIsActive] = useState<boolean>(Boolean(pm && pm.status));

  const { data: assetGroupData } = useGetAssetTypesListQuery({});

  const { data: priorityData } = useGetPrioritiesListQuery({});

  const { data: categoryData } = useGetCategoryListQuery({ st: 1 });

  const [triggerTrades, tradesResult] = useLazyGetTradesByAssetGroupIdQuery();

  const { data: trades, isSuccess: isSuccessTrades } = tradesResult;

  const initializeFields = useCallback(() => {
    setSummaryFieldsValues({
      ...pm,
      name: pm?.name,
      id: pm?.id,
      status: !!pm?.status,
      assetType: {
        label: pm?.assetType?.name,
        data: pm?.assetTypeId,
        refrigerantTracking: pm?.assetType?.refrigerantTracking,
      },
      assetTypeId: pm?.assetTypeId,
      requestCategoryId: pm?.requestCategoryId,
      category: {
        label: pm?.category?.name,
        data: pm?.requestCategoryId,
      },
      trade: {
        label: pm?.trade?.name,
        data: pm?.tradeId,
      },
      priority: {
        label: priorityData?.data?.find(
          priority => priority.id === pm?.priorityId,
        )?.name,
        data: pm?.priorityId,
      },
      tradeId: pm?.tradeId,
      priorityId: pm?.priorityId,
      description: pm?.description,
    });
  }, [pm, priorityData, isSuccessPm]);

  useEffect(() => {
    setIsActive(!!pm?.status);
    initializeFields();
    if (pm?.assetTypeId) {
      triggerTrades({ id: pm?.assetTypeId });
    }
  }, [pm, priorityData, isSuccessPm]);

  const [
    doUpdatePreventiveMaintenance,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdatePreventiveMaintenanceMutation();

  const useUpdateMutation = useCallback(() => {
    const doUpdate = async data => {
      if (Object.keys(summaryFieldsValues).length === 0) {
        dispatch(
          setSnackbar({
            severity: 'success',
            message: t('translation:dynamicForm.updateNothing'),
          }),
        );

        setCancelEdit(true);
        setIsEditClicked(false);
        return;
      }

      if (
        !summaryFieldsValues?.name ||
        !summaryFieldsValues?.assetType ||
        !summaryFieldsValues?.category ||
        !summaryFieldsValues?.trade ||
        !summaryFieldsValues?.priority
      ) {
        setInvalidFields([
          !summaryFieldsValues?.name
            ? t('translation:form.validation.required', { name: 'Name' })
            : '',
          !summaryFieldsValues?.assetType
            ? t('translation:form.validation.required', { name: 'Asset Group' })
            : '',
          !summaryFieldsValues?.category
            ? t('translation:form.validation.required', { name: 'Category' })
            : '',
          !summaryFieldsValues?.trade
            ? t('translation:form.validation.required', { name: 'Trade' })
            : '',
          !summaryFieldsValues?.priority
            ? t('translation:form.validation.required', { name: 'Priority' })
            : '',
        ]);

        window.scrollTo(0, 0);

        return;
      }

      const updatedData = {
        ...data,
        name: summaryFieldsValues?.name ?? pm?.name,
        status: summaryFieldsValues?.status ? 1 : 0,
        assetTypeId: summaryFieldsValues?.assetType?.data ?? pm?.assetTypeId,
        requestCategoryId:
          summaryFieldsValues?.category?.data ?? pm?.requestCategoryId,
        priorityId: summaryFieldsValues?.priority?.data ?? pm?.priorityId,
        tradeId: summaryFieldsValues?.trade?.data ?? pm?.tradeId,
        description: summaryFieldsValues?.description ?? pm?.description,
      };

      doUpdatePreventiveMaintenance(updatedData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
      },
    ];
  }, [
    doUpdatePreventiveMaintenance,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    _forceRefresh,
    summaryFieldsValues,
    pm?.id,
  ]);

  const handleSummaryFieldChange = (fieldName: string) => (value: any) => {
    if (value) {
      setSummaryFieldsValues({ ...summaryFieldsValues });
    }
    if (fieldName === 'status') {
      setIsActive(value);
    }

    if (fieldName === 'assetType') {
      const updatedValue = value
        ? {
            label: value.label,
            data: value.data,
            refrigerantTracking: value.refrigerantTracking,
          }
        : null;

      const shouldClearTrade =
        !updatedValue ||
        (summaryFieldsValues.assetType &&
          summaryFieldsValues.assetType.data !== updatedValue?.data);

      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        assetType: updatedValue,
        trade: shouldClearTrade ? null : prevSummaryFieldsValues.trade,
      }));

      if (updatedValue && shouldClearTrade) {
        triggerTrades({ id: updatedValue.data });
      }
    } else {
      setSummaryFieldsValues(prevSummaryFieldsValues => ({
        ...prevSummaryFieldsValues,
        [fieldName]: value,
      }));
    }

    setForceRefresh(new Date());
  };

  const summaryData: SummaryFieldsData[] = useMemo(() => {
    const assetGroupOptions = assetGroupData?.data?.map(assetGroup => ({
      label: assetGroup.name,
      data: assetGroup.id,
      refrigerantTracking: assetGroup.refrigerantTracking,
    }));

    const categoryOptions = categoryData?.data?.map(category => ({
      label: category.name,
      data: category.id,
    }));

    const priorityOptions = priorityData?.data?.map(priority => ({
      label: priority.name,
      data: priority.id,
    }));

    const tradesOptions = trades?.data?.map(trade => ({
      label: trade.name,
      data: trade.id,
    }));

    return [
      {
        id: 'name',
        label: 'PM Title',
        data: summaryFieldsValues?.name || '',
        isValid: !!summaryFieldsValues?.name,
        maxLength: 200,
        type: SummaryFieldTypes.Text,
        isEditable: true,
        onChange: handleSummaryFieldChange('name'),
        validationMessage: invalidFields[0],
      },
      {
        id: 'id',
        label: 'PM ID',
        data: id,
        type: SummaryFieldTypes.Text,
      },
      {
        id: 'status',
        label: 'State',
        data: !!summaryFieldsValues?.status,
        type: SummaryFieldTypes.State,
        onChange: handleSummaryFieldChange('status'),
        isEditable: true,
      },
      {
        id: 'assetGroup',
        label: 'Asset Group',
        data: summaryFieldsValues?.assetType || [],
        type: SummaryFieldTypes.Autocomplete,
        onChange: handleSummaryFieldChange('assetType'),
        isEditable: true,
        placeholder: 'Select Asset Group',
        options: assetGroupOptions || [],
        required: true,
        validationMessage: invalidFields[1],
      },
      {
        id: 'assetGroupRefrigerantAlert',
        label: 'asset Group Refrigerant Alert',
        data: {
          description:
            'Inspection work orders will be generated for assets at the selected locations with refrigerant tracking enabled.',
          title: 'Inspection Work Orders on Assets',
          severity: 'info',
        },
        type: SummaryFieldTypes.CustomAlert,
        isVisible: false, //summaryFieldsValues?.assetType?.refrigerantTracking === 1,
        showLabelWithVisibilityNone: true,
      },
      {
        id: 'category',
        label: 'Category',
        data: summaryFieldsValues?.category || [],
        type: SummaryFieldTypes.Autocomplete,
        onChange: handleSummaryFieldChange('category'),
        isEditable: true,
        placeholder: 'Select Category',
        options: categoryOptions || [],
        required: true,
        validationMessage: invalidFields[2],
      },
      {
        id: 'trade',
        label: 'Trade',
        data: summaryFieldsValues?.trade ?? [],
        type: SummaryFieldTypes.Autocomplete,
        onChange: handleSummaryFieldChange('trade'),
        isEditable: true,
        placeholder: 'Select Trade',
        options: tradesOptions || [],
        required: true,
        validationMessage: invalidFields[3],
      },
      {
        id: 'priority',
        label: 'Priority',
        data:
          summaryFieldsValues?.priority ||
          summaryFieldsValues?.priorityId ||
          [],
        options: priorityOptions || [],
        type: SummaryFieldTypes.Autocomplete,
        isEditable: true,
        placeholder: 'Select Priority',
        onChange: handleSummaryFieldChange('priority'),
        required: true,
        validationMessage: invalidFields[4],
      },
      {
        id: 'description',
        label: 'Description',
        data: summaryFieldsValues?.description || '',
        type: SummaryFieldTypes.TextArea,
        isEditable: true,
        maxLength: 1000,
        onChange: handleSummaryFieldChange('description'),
      },
    ];
  }, [
    pm,
    assetGroupData,
    categoryData,
    priorityData,
    summaryFieldsValues,
    handleSummaryFieldChange,
  ]);

  const handleEditButtonClick = value => {
    setIsEditClicked(value);
    initializeFields();
  };

  const handlePMNav = useCallback(
    (type: 'invoice' | 'workOrder') => {
      const pmIdData = summaryData.find(
        datum => datum.id === 'id' && datum.label === 'PM ID',
      );
      if (!pmIdData) return;
      navigate(PMNavViewType[type], {
        state: { pmId: pmIdData.data },
      });
    },
    [summaryData],
  );

  return pm ? (
    <>
      <ECWorkflowTemplate
        title={isEditClicked ? `Edit ${pm.name}` : pm.name}
        tabsAsLinks
        summaryData={summaryData}
        hideNoteTab
        cancelEdit={cancelEdit}
        editConfig={fancyFormElementsEdit.data.config}
        editFields={fancyFormElementsEdit.data.fields}
        detailsConfig={formElementsDetails.data.config}
        detailsFields={formElementsDetails.data.fields}
        useUpdateMutation={useUpdateMutation}
        isBookmarkVisible={false}
        disableTabsOnEdit={true}
        showPrintButton={false}
        closeIconAsX
        detailsData={pm}
        showLogoOnError={true}
        fullHeight
        checkNothingToUpdateEditForm={false}
        showEditForm={true}
        showLogo={false}
        customSummaryComponents={
          <ECBox display="flex" flexDirection="column" rowGap="16px">
            <ECButtonWithViewText
              title="Work Orders for PM"
              onViewClick={() => handlePMNav('workOrder')}
              scopes={[P.GetAllWorkOrders]}
            />
            <ECButtonWithViewText
              title="Invoices for PM"
              onViewClick={() => handlePMNav('invoice')}
              scopes={[P.GetAllInvoices]}
            />
          </ECBox>
        }
        onEditButtonClick={handleEditButtonClick}
        submissionConfirmationModal={{
          title:
            'Confirm you want to deactivate this Preventative Maintenance.',
          buttonText: 'Deactivate',
          bodyText:
            'Deactivating this Preventative Maintenance will remove all locations from the Preventive Maintenance. This cannot be undone. All locations will need to be added again if reactivated.',
          isVisible: !isActive,
          onClose: () => setIsActive(true),
        }}
        additionalTabs={[
          {
            value: 'pmlocation',
            label: t('translation:pages.pmLocation.title'),
            scopes: [P.GetPreventiveMaintenancesList],
            content: (
              <Outlet
                context={{
                  ...pm,
                }}
              />
            ),
            link: 'pmlocation',
            editable: false,
          },
        ]}
        isEditAllowed={hasEditPMPermission[0]}
      />
    </>
  ) : null;
}
