import { ECBox, ECMainBox, ECNotLoggedInBar } from '..';

export interface ECSignUpTemplateProps {
  children: React.ReactNode;
}

export const ECSignUpTemplate = ({ children }: ECSignUpTemplateProps) => {
  return (
    <ECMainBox
      component={'main'}
      sx={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <ECNotLoggedInBar />

      <ECBox sx={{ marginTop: '10%' }}>{children}</ECBox>
    </ECMainBox>
  );
};
