import { Method } from '../../../types/BaseType';
import _ from 'lodash';
import { formatPhone } from 'utils/strings/phone';

export const processBranchBody = (bodyToRender, method) => {
  if (method === Method.POST) {
    const fieldOrder = [
      'locationId',
      'name',
      'orgLevel',
      'jobTitle',
      'concept',
      'mailingAddress',
      'mailingEqualBilling',
      'billingAddress',
      'leaseLandlordName',
      'leaseLandlordAddress',
      'phone',
      'onHold',
      'status',
      'storeNumber',
      'openingDate',
    ];

    const modifiedBody = {
      ...bodyToRender,
      locationId: bodyToRender?.id,
      status: bodyToRender?.status ? 'Active' : 'Inactive',
    };

    const orderedBody = fieldOrder.reduce((result, field) => {
      if (field in modifiedBody) {
        result[field] =
          field === 'phone'
            ? formatPhone(modifiedBody[field])
            : modifiedBody[field];
      }
      return result;
    }, {});
    for (const key in modifiedBody) {
      if (!(key in orderedBody)) {
        orderedBody[key] = modifiedBody[key];
      }
    }

    return orderedBody;
  }

  return bodyToRender;
};
