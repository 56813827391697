import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ECGrid } from '../ECGrid';
import {
  ECTable,
  ECTableBody,
  ECTableCell,
  ECTableContainer,
  ECTableHead,
  ECTableRow,
} from '../ECTable';
import { ECTextField } from '../ECTextField';
import ECCurrencyField from '../ECCurrencyField';
import { ECAutocompletePaginated } from '../ECAutocompletePaginated';
import { ECButton } from '../ECButton';
import {
  CheckCircleOutline,
  Close,
  Edit,
  ShoppingCart,
} from '@mui/icons-material';
import { useGetPurchaseOrderItemsForDropdownQuery } from 'services/purchaseOrderApi';
import { ECBox } from '../ECBox';
import { currencyFormatter } from 'utils/strings/currency-formatter';
import { ECTypography } from '../ECTypography';
import { ECInputTextField } from '../ECTextField/InputTextField';
import { FieldTypes } from '../ECForm';
import AddIcon from '@mui/icons-material/Add';
import { uniqueId } from 'lodash';

export enum PurchaseOrderStatusType {
  PENDING = 'Pending SP Acceptance',
  PENDING_APPROVAL = 'Pending Approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  PARTIALLY_RECONCILED = 'Partially Reconciled',
  RECONCILED = 'Reconciled',
  INVOICED = 'Invoiced',
}

interface PurchaseItemsTableProps {
  items: any[];
  onChange: (items: any[]) => void;
  editableFields: any[];
  purchaseOrderStatus: PurchaseOrderStatusType;
  setRemovedItems: any;
}

enum TableHeaderMap {
  itemName = 'Item Name',
  partNumber = 'Part #',
  quantity = 'Quantity',
  quantityReceived = 'Q.Received',
  deliveryStatus = 'Delivery Status',
  costPerUnit = '$ Unit',
  lastCostPerUnit = '$ Pre Cost/Uint',
  subTotal = 'Subtotal',
}

const tableHeader = [
  'itemName',
  'partNumber',
  'quantity',
  'quantityReceived',
  'deliveryStatus',
  'lastCostPerUnit',
  'costPerUnit',
  'subTotal',
];

export const fieldToSwitchForMutableCheck = {
  quantity: 'count',
  costPerUnit: 'price',
  itemName: 'inventoryItemId',
};

const checkMutableSwitchField = field => {
  if (field === 'quantity' || field === 'costPerUnit' || field === 'itemName') {
    return fieldToSwitchForMutableCheck[field];
  }

  return field;
};

const currencyFields = ['lastCostPerUnit', 'costPerUnit', 'subTotal'];

const RenderPOTableText = ({ label, value, index, purchaseOrderStatus }) => {
  const isInvoicedDeliveryType =
    purchaseOrderStatus === PurchaseOrderStatusType.INVOICED &&
    label === 'deliveryStatus' &&
    value.toLowerCase() === 'received';
  if (isInvoicedDeliveryType) {
    return (
      <ECTableCell key={index}>
        <ECTypography
          sx={theme => ({
            background: '#4CAF5014',
            fontSize: '14px',
            padding: '9px',
            borderRadius: '20px',
            width: 'fit-content',
          })}
        >
          <CheckCircleOutline
            sx={theme => ({
              color: theme.palette.success.main,
              marginRight: 0.5,
            })}
          />
          {value}
        </ECTypography>
      </ECTableCell>
    );
  }

  return <ECTableCell key={index}>{value}</ECTableCell>;
};

export const ECPurchaseOrderItemsTable = forwardRef(
  (
    {
      items,
      onChange,
      editableFields,
      purchaseOrderStatus,
      setRemovedItems,
    }: PurchaseItemsTableProps,
    ref,
  ) => {
    const [errors, setErrors] = useState<any>([]);
    const [purchaseItems, setPurchaseItems] = useState(items);

    useEffect(() => {
      onChange(purchaseItems);
    }, [purchaseItems]);

    const canEditQuantityReceived = useMemo(() => {
      return [
        PurchaseOrderStatusType.APPROVED,
        PurchaseOrderStatusType.PARTIALLY_RECONCILED,
        PurchaseOrderStatusType.RECONCILED,
      ].includes(purchaseOrderStatus);
    }, [purchaseOrderStatus]);

    const canEditUnitCost = useCallback(
      (item, key) => {
        return (
          PurchaseOrderStatusType.PENDING_APPROVAL === purchaseOrderStatus &&
          key === 'costPerUnit' &&
          item.isNew
        );
      },
      [purchaseOrderStatus],
    );

    const removeFieldErrorOnceUpdated = useCallback(
      (fieldName, index) => {
        if (errors.length === 0 || errors.length < index) return;
        setErrors(prevErrors => {
          return prevErrors.map((itemErrors, errorIndex) => {
            if (errorIndex === index) {
              return itemErrors.filter(error => error.fieldName !== fieldName);
            }
            return itemErrors;
          });
        });
      },
      [errors],
    );

    const handleChange = useCallback(
      (value, name, id) => {
        const updatedPurchaseItems = purchaseItems.map((item, index) => {
          if (item.id === id) {
            const newItem = {
              ...item,
              [name]: value,
            };

            const newTotal =
              Number(newItem.quantity) * Number(newItem.costPerUnit);

            if (name === 'itemName') {
              removeFieldErrorOnceUpdated(name, index);

              if (!value?.name) {
                return {
                  ...newItem,
                  inventoryItemId: null,
                  [name]: value?.name,
                  subTotal: '-',
                  partNumber: '-',
                  quantity: '-',
                  deliveryStatus: '-',
                  costPerUnit: '-',
                  lastCostPerUnit: '-',
                  quantityReceived: '-',
                };
              }

              if (item.isNew) {
                return {
                  ...newItem,
                  [name]: value?.name,
                  inventoryItemId: value?.id,
                  subTotal: Number(newTotal.toFixed(2)),
                  id: value?.id,
                  partNumber: value?.partNumber,
                  lastCostPerUnit: value?.lastCostPerUnit,
                };
              }

              return {
                ...newItem,
                [name]: value?.name,
                inventoryItemId: value?.id,
                subTotal: Number(newTotal.toFixed(2)),
              };
            }

            removeFieldErrorOnceUpdated(name, index);

            return {
              ...newItem,
              subTotal: Number(newTotal.toFixed(2)),
            };
          }

          return item;
        });

        setPurchaseItems(updatedPurchaseItems);
      },
      [purchaseItems, removeFieldErrorOnceUpdated],
    );
    const handleAddNewPOItem = useCallback(() => {
      const newItem = {
        itemName: '',
        partNumber: '',
        quantity: null,
        quantityReceived: 0,
        deliveryStatus: 'Not Received',
        costPerUnit: '0',
        lastCostPerUnit: '',
        subTotal: '',
        inventoryItemId: null,
        id: uniqueId(`po-item-${purchaseItems.length + 1}-`),
        isNew: true,
      };
      setPurchaseItems(prevPurchaseItems => [...prevPurchaseItems, newItem]);
    }, [purchaseItems]);

    const handlePOItemDelete = item => {
      if (purchaseItems.length === 1) return;
      const updatedPurchaseItems = purchaseItems.filter(
        purchaseItem => purchaseItem.id !== item.id,
      );

      setPurchaseItems(updatedPurchaseItems);
      if (!item?.isNew) {
        setRemovedItems(prev => [...prev, item.id]);
      }
    };

    const subTotal = useMemo(() => {
      if (purchaseOrderStatus === PurchaseOrderStatusType.INVOICED) {
        return purchaseItems?.reduce((acc, item) => {
          if (!isNaN(Number(item.subTotal))) {
            return acc + Number(item.subTotal);
          }
          return acc;
        }, 0);
      }
      return 0;
    }, [purchaseItems, purchaseOrderStatus]);

    useImperativeHandle(ref, () => ({
      validatePOItems: () => {
        const allErrors: any = [];
        const isValid = purchaseItems.every(item => {
          let valid = true;
          const itemErrors: any = [];
          const quantity = Number(item.quantity);
          const unitCost = Number(item.costPerUnit);
          const quantityReceived = Number(item.quantityReceived);

          if (!item.itemName || !item.inventoryItemId) {
            itemErrors.push({
              fieldName: 'itemName',
              message:
                purchaseItems.length === 1
                  ? 'Add at least one item to the Purchase Order'
                  : 'Item Name is required',
            });
            valid = false;
          }
          if (isNaN(quantity) || quantity <= 0) {
            itemErrors.push({
              fieldName: 'quantity',
              message: 'Quantity must be a number and greater than zero',
            });

            valid = false;
          }
          if (!item.costPerUnit || unitCost < 0) {
            itemErrors.push({
              fieldName: 'costPerUnit',
              message: 'Unit must not be a negative number/empty',
            });
            valid = false;
          }
          if (isNaN(quantityReceived)) {
            itemErrors.push({
              fieldName: 'quantityReceived',
              message: 'Q.Received must be a number',
            });
            valid = false;
          }
          allErrors.push(itemErrors);
          return valid;
        });

        setErrors(allErrors);

        return isValid;
      },
    }));

    const renderPurchaseItems = useMemo(() => {
      return purchaseItems.map((purchaseItem, poIndex) => {
        return (
          <>
            <ECTableRow key={`${purchaseItem.id}_${poIndex}`}>
              {tableHeader.map((key, index) => {
                if (key === 'itemName') {
                  return (
                    <>
                      {editableFields?.includes(
                        checkMutableSwitchField(key),
                      ) ? (
                        <ECTableCell colSpan={4} key={`${poIndex}_${index}`}>
                          <ECAutocompletePaginated
                            sx={{
                              width: '250px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              py: 1,
                            }}
                            fieldName={key}
                            value={{
                              data: purchaseItem.id,
                              name: purchaseItem[key],
                            }}
                            variant="filled"
                            placeholder="Item Name*"
                            readOnly={false}
                            useQuery={useGetPurchaseOrderItemsForDropdownQuery}
                            queryParams={{
                              sb: 'invntry.name',
                              ob: 'invntry.name',
                              o: 'a',
                            }}
                            onChange={newValue => {
                              handleChange(newValue, key, purchaseItem.id);
                            }}
                            error={errors?.[poIndex]?.some(
                              item => item.fieldName === key,
                            )}
                            validationMessage={
                              errors?.[poIndex]?.find(
                                field => field.fieldName === key,
                              )?.message
                            }
                          />
                        </ECTableCell>
                      ) : (
                        <ECTableCell
                          sx={{
                            maxWidth: '322px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                          colSpan={4}
                          key={`${poIndex}_${index}`}
                        >
                          {purchaseItem[key]}
                        </ECTableCell>
                      )}
                    </>
                  );
                } else if (currencyFields.includes(key)) {
                  return (
                    <>
                      {editableFields?.includes(checkMutableSwitchField(key)) ||
                      canEditUnitCost(purchaseItem, key) ? (
                        <ECTableCell key={`${poIndex}_${index}`}>
                          <ECCurrencyField
                            style={{ padding: '2px 0' }}
                            variant="filled"
                            value={purchaseItem[key]}
                            error={errors?.[poIndex]?.some(
                              item => item.fieldName === key,
                            )}
                            id={`${purchaseItem.id}`}
                            readOnly={false}
                            onChange={e => {
                              handleChange(
                                e.target.value,
                                key,
                                purchaseItem.id,
                              );
                            }}
                            validationMessage={
                              errors?.[poIndex]?.find(
                                field => field.fieldName === key,
                              )?.message
                            }
                          />
                        </ECTableCell>
                      ) : (
                        <ECTableCell key={`${poIndex}_${index}`}>
                          {!isNaN(Number(purchaseItem[key]))
                            ? currencyFormatter.format(purchaseItem[key])
                            : purchaseItem[key]}
                        </ECTableCell>
                      )}
                    </>
                  );
                }
                if (key === 'quantity' || key === 'quantityReceived') {
                  const shouldUseQuantityReceived =
                    key === 'quantityReceived' && canEditQuantityReceived;

                  return (
                    <>
                      {shouldUseQuantityReceived ? (
                        <ECTableCell key={`${poIndex}_${index}`}>
                          <ECInputTextField
                            type={FieldTypes.Number}
                            label=""
                            variant="filled"
                            fieldName={key}
                            error={errors?.[poIndex]?.some(
                              item => item.fieldName === key,
                            )}
                            helperText={
                              errors?.[poIndex]?.find(
                                field => field.fieldName === key,
                              )?.message
                            }
                            placeholder=""
                            value={purchaseItem[key]}
                            onKeyDown={e => {
                              if (e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            onChange={value => {
                              handleChange(value, key, purchaseItem.id);
                            }}
                          />
                        </ECTableCell>
                      ) : editableFields?.includes(
                          checkMutableSwitchField(key),
                        ) ? (
                        <ECTableCell key={`${poIndex}_${index}`}>
                          <ECInputTextField
                            type={FieldTypes.Number}
                            label=""
                            variant="filled"
                            fieldName={key}
                            error={errors?.[poIndex]?.some(
                              item => item.fieldName === key,
                            )}
                            helperText={
                              errors?.[poIndex]?.find(
                                field => field.fieldName === key,
                              )?.message
                            }
                            onKeyDown={e => {
                              if (e.key === '-') {
                                e.preventDefault();
                              }
                            }}
                            placeholder=""
                            value={purchaseItem[key]}
                            onChange={value => {
                              handleChange(value, key, purchaseItem.id);
                            }}
                          />
                        </ECTableCell>
                      ) : (
                        <ECTableCell key={`${poIndex}_${index}`}>
                          {purchaseItem[key]}
                        </ECTableCell>
                      )}
                    </>
                  );
                }

                return (
                  <>
                    {editableFields?.includes(checkMutableSwitchField(key)) ? (
                      <ECTableCell key={`${poIndex}_${index}`}>
                        <ECTextField
                          variant="filled"
                          sx={{ py: 1 }}
                          value={purchaseItem[key]}
                          onChange={e =>
                            handleChange(e.target.value, key, purchaseItem.id)
                          }
                          error={errors?.[poIndex]?.some(
                            item => item.fieldName === key,
                          )}
                          helperText={
                            errors?.[poIndex]?.find(
                              field => field.fieldName === key,
                            )?.message
                          }
                        />
                      </ECTableCell>
                    ) : (
                      <RenderPOTableText
                        index={`${poIndex}_${index}`}
                        key={index}
                        label={key}
                        purchaseOrderStatus={purchaseOrderStatus}
                        value={purchaseItem[key]}
                      />
                    )}
                  </>
                );
              })}

              {(purchaseOrderStatus === PurchaseOrderStatusType.PENDING ||
                purchaseOrderStatus ===
                  PurchaseOrderStatusType.PENDING_APPROVAL) && (
                <ECButton
                  sx={{ marginTop: '27px' }}
                  color="error"
                  variant="text"
                  onClick={() => handlePOItemDelete(purchaseItem)}
                >
                  <Close />
                </ECButton>
              )}
            </ECTableRow>
          </>
        );
      });
    }, [purchaseItems, items, canEditQuantityReceived, editableFields, errors]);

    return (
      <ECGrid pb={8} container>
        <ECGrid item xs={12}>
          <ECTableContainer>
            <ECTable>
              <ECTableHead>
                <ECTableRow>
                  {tableHeader.map((header, index) => (
                    <ECTableCell colSpan={index === 0 ? 4 : 1} key={index}>
                      {TableHeaderMap[header]}
                    </ECTableCell>
                  ))}
                </ECTableRow>
              </ECTableHead>
              <ECTableBody>
                {renderPurchaseItems}
                {purchaseOrderStatus ===
                  PurchaseOrderStatusType.PENDING_APPROVAL && (
                  <ECTableRow>
                    <ECTableCell sx={{ borderBottom: 0 }}>
                      <ECButton
                        startIcon={<AddIcon />}
                        onClick={handleAddNewPOItem}
                      >
                        Add Item
                      </ECButton>
                    </ECTableCell>
                  </ECTableRow>
                )}
              </ECTableBody>
            </ECTable>
          </ECTableContainer>
        </ECGrid>
        {purchaseOrderStatus === PurchaseOrderStatusType.INVOICED && (
          <ECGrid xs={12}>
            <ECBox
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              mt="14px"
              columnGap={1}
            >
              <ECTypography fontWeight={500} fontSize="36px">
                Subtotal:
              </ECTypography>

              <ECTypography fontWeight={400} fontSize="34px">
                {currencyFormatter.format(subTotal)}
              </ECTypography>
            </ECBox>
          </ECGrid>
        )}
      </ECGrid>
    );
  },
);

export const PurchaseOrderItemsHeaderButton = ({
  POStatus,
  onClick,
  isEditMode,
}: {
  POStatus: PurchaseOrderStatusType;
  onClick?: () => void;
  isEditMode?: boolean;
}) => {
  const isPOInvoiced = POStatus === PurchaseOrderStatusType.INVOICED;
  const variant = !isEditMode
    ? 'outlined'
    : isPOInvoiced
      ? 'outlined'
      : 'contained';

  let buttonData;

  switch (POStatus) {
    case PurchaseOrderStatusType.PENDING:
      buttonData = {
        text: 'Edit',
        icon: <Edit />,
      };
      break;
    case PurchaseOrderStatusType.APPROVED:
    case PurchaseOrderStatusType.PARTIALLY_RECONCILED:
    case PurchaseOrderStatusType.RECONCILED:
      buttonData = {
        text: 'RECONCILE SHIPMENT',
        icon: <ShoppingCart />,
      };
      break;
    case PurchaseOrderStatusType.INVOICED:
      buttonData = {
        text: 'RECONCILE SHIPMENT',
        icon: <ShoppingCart />,
      };
      break;
    default:
      buttonData = {
        text: 'EDIT',
        icon: <Edit />,
      };
  }
  return (
    <ECGrid item xs={12} my="20px">
      <ECButton
        key="edit-purchase-order-items"
        variant={variant}
        onClick={() => {
          onClick?.();
        }}
        // disabled={isPOInvoiced}
        startIcon={buttonData.icon}
      >
        {buttonData.text}
      </ECButton>
    </ECGrid>
  );
};
