import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdateWorkOrderMutation,
  useGetWorkOrderNextStatusListQuery,
  useWorkOrderActionClickMutation,
  workOrdersApi,
  useLazyGetPossibleWOLinksQuery,
  useCreateLinkedWorkordersMutation,
  useDeleteLinkedWorkordersMutation,
  useGetWorkOrderByIdQuery,
} from 'services/workOrdersApi';
import { WOLinkedParamsMutate } from 'types/WorkOrders';
import {
  ECBox,
  ECButton,
  ECEasyForm,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECIconButton,
  ECInsightsButton,
  ECPaper,
  ECWorkflowTemplate,
  FieldTypes,
} from 'app/components';
import {
  SummaryFieldTypes,
  SummaryFieldsData,
} from 'app/components/ECWorkflowSummaryField';
import { Outlet, useMatch, useParams } from 'react-router';
import { useGetAttachmentQuery } from 'services/attachmentApi';
import { AttachmentCategory, DocumentCoreTypes } from 'types/Attachment';
import { useGetFlagsListQuery } from 'services/flagsApi';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { ChipSearchOptions } from 'app/components';
import { WorkflowNextStatus } from 'types/WorkflowStatus';
import { CreateInvoiceDrawer } from './CreateInvoiceDrawer';
import { WorkflowStatusGroupName } from 'app/components/ECWorkflowStatusBadge';
import {
  useGetAssetsByBranchIdAndAssetGroupIdQuery,
  useGetUsersByBranchIdQuery,
} from 'services/branchApi';
import { useGetProblemListQuery } from 'services/problemApi';
import { useGetCategoryListQuery } from 'services/categoryApi';
import { useCustomerUser } from 'app/hooks';
import { ForbiddenPage } from '../ForbiddenPage';
import {
  cancelAction,
  reassignAction,
  unassignAction,
} from '../DashboardPage/actions';
import { ECModal } from 'app/components/ECModal';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { LinkItemsList } from 'app/components/ECWorkflowSidebar';
import { useGetUserProfileQuery } from 'services/userProfileApi';
import { dashboardApi } from 'services/dashboardApi';
import { ECConfirmationDialog } from '../../components/ECDialog';
import ContactInfoIcon from '@mui/icons-material/PermContactCalendar';
import { Close } from '@mui/icons-material';
import { AutoAwesome } from '@mui/icons-material';
import {
  useGetAssetWarrantyQuery,
  useLazyGetAssetByIdQuery,
  useGetAndCalculateAssetInsightsQuery,
} from '../../../services/assetApi';
import { CopyWorkorder } from './CopyWorkorder';
import { useGetPriorityListQuery } from 'services/assetTypeApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { compact, debounce, isEmpty, isNil, uniqBy, cloneDeep } from 'lodash';
import { ECWorkOrderChangeTechnicianCommentModalContent } from 'app/components/ECWorkOrderChangeTechnicianCommentModalContent';
import { commentApi, useLazyGetCommentListQuery } from 'services/commentApi';
import moment from 'moment';
import { moduleApi } from 'services/moduleApi';
import { AllocatedInventoryItems } from './AllocatedInventoryItems';
import { generateWODetailsHTMLContent } from 'utils/geneate-html-content';
import { hasPermission } from 'utils/permissions';
import { P } from 'types/Permission';
import { AdjustAllocatedInventoryItems } from './AdjustAllocatedInventoryItems';
import { ECInsightsDrawer } from 'app/components/ECInsightsDrawer';
import { useGetSurveyFeedbackQuery } from 'services/surveyApi';
import { useGetProfileQuery } from 'services/profileApi';
import ECPrintCard from 'app/components/ECPrintCard';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { useLazyGetGeneralAssetByIdQuery } from 'services/generalAssetApi';
import { useGetSPBranchUsersQuery } from 'services/userApi';
import { useLocation, useNavigate } from 'react-router';
import { StyleConstants } from 'styles/StyleConstants';
import { UpdateETAAction } from './UpdateETAAction';

const fancyFormElementsEdit = require('./fancy_form_config_edit.json');
const formElementsDetails = require('./fancy_form_config_details.json');
const fancyFormElementsLinkedWO = require('./fancy_form_config_linkedwo.json');

const SEARCH_DEBOUNCE_TIME = 1000;
const REDIRECT_DEBOUNCE_TIME = 1500;
const PRINT_DEBOUNCE_TIME = 1500;

export const reassignWoStatus = [
  WorkflowStatusGroupName.Unassigned,
  WorkflowStatusGroupName.Pending,
  WorkflowStatusGroupName.Rejected,
  WorkflowStatusGroupName.Cancelled,
];

export const reassignInternalWoStatus = [
  WorkflowStatusGroupName.Accepted,
  WorkflowStatusGroupName.Cancelled,
];

export const internalTechAssignedWoStatus = [
  WorkflowStatusGroupName.Pending,
  WorkflowStatusGroupName.PendingParts,
  WorkflowStatusGroupName.Unassigned,
  WorkflowStatusGroupName.Rejected,
  WorkflowStatusGroupName.Accepted,
  WorkflowStatusGroupName.EnRoute,
  WorkflowStatusGroupName.Submitting,
  WorkflowStatusGroupName.Submitted,
  WorkflowStatusGroupName.ProposalSubmitted,
  WorkflowStatusGroupName.ReturnVisitRequired,
  WorkflowStatusGroupName.Approved,
  WorkflowStatusGroupName.Arrived,
  WorkflowStatusGroupName.NotFixed,
  WorkflowStatusGroupName.NotRequired,
  WorkflowStatusGroupName.PendingParts,
  WorkflowStatusGroupName.ProposalApproved,
  WorkflowStatusGroupName.ProposalRejected,
];

export enum SPType {
  INTERNAL_TECH = 'INTERNAL',
  INTERNALLY_MANAGED = 'IM',
  THIRD_PARTY = '3P',
}

export function WorkOrderDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const match = useMatch('/panel/*');

  const dispatch = useDispatch();

  const isDetailsTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('notes') &&
    !window.location.href.includes('refrigerant') &&
    !window.location.href.includes('activity');

  const [drawerType, setDrawerType] = useState<
    | 'copy'
    | 'invoice'
    | 'linkedWO'
    | 'allocateInventoryItems'
    | 'adjustAllocatedInventoryItems'
    | 'insights'
  >();
  const [hasEditWorkorderPermission, hasEditPriorityOnWOPermission] =
    useHasPermission([P.EditWorkOrder, P.EditPriorityOnWorkOrder]);
  const [comment, setComment] = useState<string>('');
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [submitData, setSubmitData] = useState<any>({});
  const [searchWOs, setSearchWOs] = useState('');
  const [internalWorkOrder, setInternalWorkOrder] = useState<boolean>(false);
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<WorkflowNextStatus>();
  const [showSPContactInfo, setShowSPContactInfo] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<ECEasyFormFieldType[]>([]);
  const [detailsFormFields, setDetailsFormFields] = useState<
    ECEasyFormFieldType[]
  >(formElementsDetails.fields);
  const [anchorElPrint, setAnchorElPrint] = useState<any>(null);
  const [openPrintActionPopover, setOpenPrintActionPopover] = useState(false);
  const [shouldIncludeComments, setShouldIncludeComments] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isOpenReassignModal, setIsOpenReassignModal] =
    useState<boolean>(false);
  const [isOpenUnassignModal, setIsOpenUnassignModal] =
    useState<boolean>(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isOpenAcceptModal, setIsOpenAcceptModal] = useState<boolean>(false);
  const [isOpenReassignTechnicianModal, setIsOpenReassignTechnicianModal] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const notificationAction = searchParams.get('notificationAction');
  const { from } = location.state || { from: 'woDetails' };

  useEffect(() => {
    if (from === 'dispatch') {
      setIsEditing(true);
      navigate(location.pathname, {
        replace: true,
        state: { from: 'woDetails' },
      });
    }
  }, [from, setIsEditing, navigate, location.pathname]);

  const [assignTechicianComment, setAssignTechicianComment] =
    useState<string>('');

  const [woDataConfirmAssignTechnician, setWoDataConfirmAssignTechnician] =
    useState<any>(null);

  const {
    data: workOrder,
    isSuccess: isSuccessWorkOrder,
    refetch,
  } = useGetWorkOrderByIdQuery(
    { id: Number(id) },
    { skip: !id || !isDetailsTab },
  );

  const {
    data: spUsersData,
    isSuccess: spUsersIsSuccess,
    isError: spUsersIsError,
  } = useGetSPBranchUsersQuery(
    {
      branchId: workOrder?.locationId,
      spCompanyId: workOrder?.spCompanyId || 0,
    },
    { skip: !id || !isDetailsTab },
  );

  const woInternalTechniciansList = useMemo(() => {
    if (spUsersIsError) {
      return [];
    }
    return (
      spUsersData?.map(user => ({
        id: user.id,
        name: user.fullName,
      })) || []
    );
  }, [spUsersData, spUsersIsSuccess, spUsersIsError]);

  const isAsset = useMemo(
    () => isNil(workOrder?.generalAssetId),
    [workOrder?.generalAssetId],
  );

  const isCreatedFromPM = useMemo(
    () => !isNil(workOrder?.pmId),
    [workOrder?.pmId],
  );

  const moduleName = useMemo(() => {
    return isAsset ? 'asset' : 'general-asset';
  }, [isAsset]);

  const { data: companyProfile } = useGetProfileQuery();

  const { data: userProfile, isSuccess: isSuccessUserProfile } =
    useGetUserProfileQuery(undefined, {
      skip: !isDetailsTab,
    });

  const { data: surveyFeedback } = useGetSurveyFeedbackQuery(
    {
      moduleName: 'workorder',
      id: id || '',
      companyId: companyProfile?.id?.toString() || '',
    },
    {
      skip:
        !id ||
        !companyProfile?.id ||
        !hasPermission({
          userPermissions: userProfile?.permissions?.map(p => p.action),
          scopes: [P.GetSurveyFeedback],
        }) ||
        !companyProfile?.workorderSurvey,
    },
  );

  const isFeedbackAlreadySubmitted = !isEmpty(surveyFeedback?.questions);

  const isUserHaveGetInventoryPermission = useMemo(() => {
    if (isSuccessUserProfile && Array.isArray(userProfile?.permissions)) {
      return hasPermission({
        userPermissions: userProfile.permissions?.map(p => p.action),
        scopes: [P.GetInventory, P.AllocateStock],
      });
    }
    return false;
  }, [userProfile, isSuccessUserProfile]);

  const hideAllocateInventoryButtonStates = useMemo(
    () => [
      WorkflowStatusGroupName.Complete,
      WorkflowStatusGroupName.Cancelled,
      WorkflowStatusGroupName.SoftCompleted,
      WorkflowStatusGroupName.Pending,
    ],
    [],
  );
  const handleAllocateInventoryButtonClicked = useCallback(() => {
    setDrawerType('allocateInventoryItems');
  }, []);

  const handleAdjustAllocateInventoryButtonClicked = useCallback(() => {
    setDrawerType('adjustAllocatedInventoryItems');
  }, []);

  useEffect(() => {
    if (!isDetailsTab) {
      return;
    }

    const isAllocatedItemsAvailable = !!workOrder?._allocatedInventory?.length;
    if (workOrder && isSuccessWorkOrder) {
      const localDetailsFormFields = [...detailsFormFields];
      const allocatedInventoryField = localDetailsFormFields?.[0]?.subFields
        ?.find(field => field.groupName === 'rightColumn')
        ?.subFields?.find(field => field.fieldName === 'allocatedInventory');

      if (allocatedInventoryField) {
        allocatedInventoryField.hideAllocateButton = !(
          !hideAllocateInventoryButtonStates?.includes?.(
            workOrder?.statusGroupName?.toUpperCase() as WorkflowStatusGroupName,
          ) && isUserHaveGetInventoryPermission
        );
        allocatedInventoryField.actionButtonClicked = isAllocatedItemsAvailable
          ? handleAdjustAllocateInventoryButtonClicked
          : handleAllocateInventoryButtonClicked;
        allocatedInventoryField.value = workOrder?.processedAllocatedInventory;
        setDetailsFormFields(localDetailsFormFields);
      }
    }

    const localDetailsFormFields = [...detailsFormFields];
    const checkinTimeField = localDetailsFormFields?.[0]?.subFields
      ?.find(field => field.groupName === 'leftColumn')
      ?.subFields?.find(field => field.fieldName === 'checkinTime');

    if (checkinTimeField) {
      checkinTimeField.visible = !!workOrder?.showCheckinTime;
      setDetailsFormFields(localDetailsFormFields);
    }
  }, [workOrder, isSuccessWorkOrder]);
  const existingDataAllocatedInventoryItemsDrawer = useMemo(() => {
    return {
      workOrderId: workOrder?.id,
      nte: workOrder?.nte,
      maxNte: userProfile?.maxNte,
      invoiceTotal: workOrder?.invoices ? workOrder.invoices[0]?.total : 0,
    };
  }, [workOrder, userProfile]);

  const { data: assetWarranty } = useGetAssetWarrantyQuery(
    workOrder?.assetId || 0,
    {
      skip: !workOrder?.assetId || !isDetailsTab,
    },
  );

  const linkedWO: LinkItemsList | undefined = useMemo(() => {
    if (
      isSuccessWorkOrder &&
      workOrder &&
      workOrder?.linkedWorkorders &&
      workOrder?.linkedWorkorders?.length > 0
    ) {
      return {
        content: workOrder?.linkedWorkorders?.map(linkwo => ({
          href: linkwo.toString(),
          text: `#${linkwo.toString()}`,
        })),
        order: 0,
      };
    } else {
      return undefined;
    }
  }, [workOrder, isSuccessWorkOrder, workOrder?.linkedWorkorders]);

  const { data: wOAttachments, isSuccess: isSuccessWOAttachments } =
    useGetAttachmentQuery(
      {
        module: 'workorder',
        moduleId: workOrder?.id ?? 0,
        category: AttachmentCategory.Photo,
      },
      {
        skip: !workOrder?.id || !isDetailsTab,
      },
    );
  const imagesMemo = useMemo(() => {
    if (wOAttachments && isSuccessWOAttachments) {
      return wOAttachments?.others?.map(attachment => ({
        id: attachment.id,
        preview: attachment.url,
        type: attachment.category,
        name: attachment.fileName,
        description: attachment.description,
        uploadedBy: attachment?.createdByUserEmail,
        uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
        documentType: attachment?.documentCoreType?.name,
        fileType: attachment.fileType,
        attachmentType: attachment.attachmentType,
        expiresOn: attachment.expiresOn,
        originalName: attachment.originalName,
        documentRequiredId: attachment.documentRequiredId,
        moduleId: attachment.moduleId,
      }));
    }
  }, [wOAttachments, isSuccessWOAttachments]);

  const [triggerAsset, resultAsset] = useLazyGetAssetByIdQuery({});
  const { data: assetObject, isSuccess: isSuccessAsset } = resultAsset;

  const [triggerOfGeneralAsset, resultOfGeneralAsset] =
    useLazyGetGeneralAssetByIdQuery();
  const { data: generalAssetObject, isSuccess: isSuccessGeneralAsset } =
    resultOfGeneralAsset;

  const existingDataCopyDrawer = useMemo(() => {
    return {
      locationId: workOrder?.locationId,
      locationName: workOrder?.locationName,
      assetId: workOrder?.assetId,
      areaId: workOrder?.assetAreaId,
      problemId: workOrder?.problemId,
      problem: workOrder?.problem,
      asset: workOrder?.generalAssetId
        ? !generalAssetObject
          ? {
              id: workOrder?.generalAssetId,
              name: workOrder?.generalAssetName,
              isGeneralAsset: workOrder?.generalAssetId,
              priorityId: workOrder?.priorityId,
              openWoCount: workOrder?.openWoCount,
            }
          : {
              ...generalAssetObject,
              isGeneralAsset: workOrder?.generalAssetId,
              openWoCount: workOrder?.openWoCount,
            }
        : !assetObject
          ? {
              id: workOrder?.assetId,
              name: workOrder?.assetName,
              priorityId: workOrder?.priorityId,
              openWoCount: workOrder?.openWoCount,
            }
          : { ...assetObject, openWoCount: workOrder?.openWoCount },
      generalAssetId: workOrder?.generalAssetId,
      generalAssetName: workOrder?.generalAssetName,
      serviceProviderName: workOrder?.serviceProviderName,
      spCompanyId: workOrder?.spCompanyId,
      tradeId: workOrder?.tradeId,
      branchId: workOrder?.locationId,
      priorityId: workOrder?.priorityId,
      overtimeApproved: workOrder?.overtimeApproved,
      requestCategoryId: workOrder?.requestCategoryId,
      requestedBy: '',
      description: workOrder?.description,
      copyWOAttachments: ['Copy WO Attachments'],
      files: imagesMemo,
      originalCopiedWorkorderId: workOrder?.id,
      moduleName: 'workorder',
    };
  }, [
    workOrder,
    imagesMemo,
    wOAttachments,
    isSuccessWOAttachments,
    assetObject,
    generalAssetObject,
    isSuccessAsset,
  ]);

  const { data: workOrderNextStatus } = useGetWorkOrderNextStatusListQuery(
    Number(id) || 0,
    {
      skip: !id || !isDetailsTab,
    },
  );

  const workOrderNextStatusActions = useMemo(() => {
    const coupaInvoice = workOrder?.invoices?.some(invoice => invoice.coupaId);
    const failedCoupaInvoice = workOrder?.invoices?.some(
      invoice => invoice.retryCoupa,
    );
    // remove NOT FIXED from actions if its coupa WO
    if (coupaInvoice || failedCoupaInvoice) {
      return workOrderNextStatus?.filter(
        action => action.statusGroupName !== WorkflowStatusGroupName.NotFixed,
      );
    }
    return workOrderNextStatus;
  }, [workOrderNextStatus, workOrder]);

  useEffect(() => {
    if (!workOrderNextStatus || !notificationAction) return;
    const statusToChange = workOrderNextStatus.find(
      item => item.name.toLowerCase() === notificationAction,
    );

    if (!statusToChange) return;

    if (notificationAction === 'accept') {
      setStatusData(statusToChange);
      setIsOpenAcceptModal(true);
    } else if (notificationAction === 'reject') {
      setIsOpenUnassignModal(true);
    }
  }, [notificationAction, workOrderNextStatus]);

  useEffect(() => {
    const assigneeSPUserIdField = fancyFormElementsEdit.fields.find(
      field => field.fieldName === 'assigneeSPUserId',
    );
    assigneeSPUserIdField.useQuery = useGetSPBranchUsersQuery;
    assigneeSPUserIdField.queryParams = {
      branchId: workOrder?.locationId,
      spCompanyId: workOrder?.spCompanyId || 0,
    };
    assigneeSPUserIdField.shouldUseOriginalQueryParams = true;
    assigneeSPUserIdField.fullName = workOrder?.spAssigneeUserName;
    assigneeSPUserIdField.value =
      workOrder?.spAssigneeUserId && workOrder?.spAssigneeUserName
        ? {
            id: workOrder?.spAssigneeUserId,
            fullName: workOrder?.spAssigneeUserName,
            name: workOrder?.spAssigneeUserName,
          }
        : undefined;

    setFormFields(fancyFormElementsEdit.fields);
  }, [workOrder?.locationId, workOrder?.spAssigneeUserId]);

  const { data: categoryListData } = useGetCategoryListQuery(
    { st: 1 },
    {
      skip: !isDetailsTab,
    },
  );

  const dropdownCategoryList = useMemo(() => {
    if (categoryListData) {
      const categories = categoryListData?.data
        ?.filter(category => {
          return category?.module?.name !== 'Inventory';
        })
        ?.map(category => ({
          label: category.name,
          data: category.id,
        }));

      // if current category is not on list, add it
      if (
        workOrder?.category &&
        workOrder?.categoryId &&
        !categories?.some(category => category.data === workOrder?.categoryId)
      ) {
        categories?.push({
          label: workOrder?.category,
          data: workOrder?.categoryId,
        });
      }
      return categories;
    } else {
      return [];
    }
  }, [categoryListData, workOrder?.category, workOrder?.categoryId]);

  const { data: problemsList } = useGetProblemListQuery(
    {},
    {
      skip: !isDetailsTab,
    },
  );

  const [workOrderSummaryData, setWorkOrderSummaryData] = useState<any>({});

  const { data: assets } = useGetAssetsByBranchIdAndAssetGroupIdQuery(
    {
      id: workOrder?.locationId ?? 0,
      agId: workOrder?.assetGroupId ?? 0,
    },
    {
      skip: !workOrder?.locationId || !workOrder?.assetGroupId || !isDetailsTab,
    },
  );

  const { data: assetInsights, isLoading: isLoadingAssetInsights } =
    useGetAndCalculateAssetInsightsQuery(
      {
        id: workOrder?.assetId || 0,
      },
      {
        skip:
          !workOrder?.assetId || !isDetailsTab || !isAsset || isCreatedFromPM,
      },
    );

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName,
      moduleId: workOrder?.generalAssetId || workOrder?.assetId || 0,
      category: AttachmentCategory.Photo,
    },
    {
      skip: !workOrder?.assetId || !isDetailsTab,
    },
  );

  const assetProfileAttachment = useMemo(() => {
    const matchingPhotos = attachments?.others?.filter(
      attachment =>
        attachment?.documentCoreType?.code ===
        DocumentCoreTypes.assetProfilePhoto,
    );
    if (matchingPhotos && matchingPhotos.length > 0) {
      return matchingPhotos[matchingPhotos.length - 1];
    }
  }, [attachments]);

  useEffect(() => {
    setFormFields(
      fancyFormElementsEdit?.fields?.map(field => {
        if (field.type === 'group') {
          return field?.subFields?.map(subField => {
            if (workOrder?.mutableFields?.includes?.(subField?.fieldName)) {
              subField.readOnly = false;
            } else {
              subField.readOnly = true;
            }
          });
        }

        if (workOrder?.mutableFields?.includes?.(field?.fieldName)) {
          field.readOnly = false;
        } else {
          field.readOnly = true;
        }

        // if the WO was internal
        // it should never be editable
        if (workOrder?.internal && field.fieldName === 'internal') {
          field.readOnly = true;
        }

        if (workOrder && field?.fieldName === 'assigneeSPUserId') {
          if (
            workOrder?.mutableFields?.includes('spAssigneeUserId') &&
            workOrder?.spCode === SPType.INTERNAL_TECH
          ) {
            field.type = 'select_paginated';
            field.readOnly = false;
          } else {
            field.type = 'text_field';
            field.value = workOrder?.spAssigneeUserName;
          }
        }

        return field;
      }),
    );
  }, [
    workOrder?.mutableFields,
    workOrder?.internal,
    workOrder?.spCode,
    workOrder?.spAssigneeUserName,
  ]);

  const { data: flagsData, isSuccess: isSuccessFlag } = useGetFlagsListQuery(
    {},
    {
      skip: !isEditMode || !isDetailsTab,
    },
  );

  useEffect(() => {
    if (flagsData && isSuccessFlag) {
      let editSelectField = fancyFormElementsEdit?.fields?.find(
        field => field.fieldName === 'flagsIds',
      );
      editSelectField.options =
        flagsData?.data?.map(d => ({
          label: d.label,
          color: d.color,
          id: d.id,
        })) || [];
      editSelectField.optionValues = flagsData?.data?.map(d => d.label) || [];

      fancyFormElementsEdit.fields = [...fancyFormElementsEdit.fields];
      setFormFields(fancyFormElementsEdit.fields);
    }
  }, [isSuccessFlag, flagsData]);

  const [
    doUpdateWorkOrder,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset: resetUpdateWorkOrder,
    },
  ] = useUpdateWorkOrderMutation();

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) {
      dispatch(workOrdersApi.util.invalidateTags(['WorkOrderStatusList']));
      handleCloseCommentModal();
    }
  }, [isUpdateSuccess, isUpdateLoading]);

  const useUpdateWorkOrder = useCallback(() => {
    const doUpdate = async data => {
      if (!workOrder?.id) return;

      const flagsIds = data.flagsIds?.map(flag => flag?.id);
      const { nte, assigneeSPUserId, ...updatedDataWithoutPrev } = data;
      const updatedData = {
        ...updatedDataWithoutPrev,
        priorityId: data.priorityId,
        requestCategoryId: workOrderSummaryData?.categoryId,
        internal: data.internal ? 1 : 0,
        assetId: workOrderSummaryData?.assetId,
        problemId: workOrderSummaryData?.problemId,
        assigneeUserId: isNil(data.assigneeUserId?.id)
          ? null
          : data.assigneeUserId?.id,
        flagsIds,
        spAssigneeUserId: assigneeSPUserId?.id || null,
      };
      if (nte >= 0 && nte != workOrder.nte) {
        updatedData.nte = nte;
      }

      if (
        data?.assigneeUserId?.id &&
        data?.assigneeUserId?.id !== workOrder?.assigneeUser?.id
      ) {
        setSubmitData(updatedData);
        setShowCommentModal(true);
        return;
      }

      if (
        assigneeSPUserId?.id &&
        assigneeSPUserId?.id !== workOrder?.spAssigneeUserId
      ) {
        updatedData.spAssigneeUserId = assigneeSPUserId?.id;
        // User clicked on "Unselected" option
        if (assigneeSPUserId === -1) {
          updatedData.spAssigneeUserId = null;
        } else {
          setIsOpenReassignTechnicianModal(true);
          setWoDataConfirmAssignTechnician(updatedData);
          return;
        }
      }
      doUpdateWorkOrder(updatedData);
      setInternalWorkOrder(false);
      setIsEditing(false);
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset: resetUpdateWorkOrder,
      },
    ];
  }, [
    workOrderSummaryData,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    workOrder,
    workOrder?.id,
    doUpdateWorkOrder,
    resetUpdateWorkOrder,
  ]);

  const [
    doUpdateWorkOrderStatus,
    { isLoading: isLoadingAction, isSuccess: isSuccessUpdateAction },
  ] = useWorkOrderActionClickMutation();

  useEffect(() => {
    if (isSuccessUpdateAction) {
      setIsOpenUnassignModal(false);
      setIsOpenCancelModal(false);
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Work Order status updated successfully',
        }),
      );
    }
  }, [isSuccessUpdateAction]);

  const handleConfirmSubmitCommentAssignTechnician = useCallback(() => {
    const updatedData = {
      ...woDataConfirmAssignTechnician,
      noteToSp: assignTechicianComment,
    };
    doUpdateWorkOrder(updatedData);
    dispatch(commentApi.util.invalidateTags(['Comment']));
  }, [
    woDataConfirmAssignTechnician,
    assignTechicianComment,
    doUpdateWorkOrder,
    dispatch,
  ]);

  useEffect(() => {
    if (
      (!isLoadingAction && isSuccessUpdateAction) ||
      (!isUpdateLoading && isUpdateSuccess)
    ) {
      dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
      closeReassignTechnicianModal();
      setIsEditing(false);
    }
  }, [
    isLoadingAction,
    isSuccessUpdateAction,
    isUpdateLoading,
    isUpdateSuccess,
  ]);

  const doUpdateStatus = async (data: WorkflowNextStatus) => {
    if (data.statusGroupName === WorkflowStatusGroupName.Cancelled) {
      setStatusData(data);
      setIsOpenCancelModal(true);
    } else if (
      data.statusGroupName === WorkflowStatusGroupName.Unassigned &&
      companyProfile?.customerProfile?.woUnassignCommentRequired
    ) {
      setStatusData(data);
      setIsOpenUnassignModal(true);
    } else if (data.statusGroupName === WorkflowStatusGroupName.Accepted) {
      setStatusData(data);
      setIsOpenAcceptModal(true);
    } else {
      doUpdateWorkOrderStatus({
        id: workOrder?.id || 0,
        ...data,
      });
    }
  };

  const useRefWoInternal = useRef(workOrder?.internal);

  useEffect(() => {
    useRefWoInternal.current = workOrder?.internal;
  }, [workOrder?.internal]);

  const onChangeEditFields = useCallback(
    data => {
      if (
        !useRefWoInternal.current &&
        data?.find(d => d.fieldName === 'internal')?.value
      ) {
        setInternalWorkOrder(true);
      } else {
        setInternalWorkOrder(false);
      }
    },
    [workOrder?.internal, useRefWoInternal],
  );

  const handleCopyClick = () => {
    setDrawerType('copy');
  };
  const handleClose = useCallback(() => setDrawerType(undefined), []);

  const addInvoice = useMemo(() => {
    const onAddInvoice = () => {
      setDrawerType('invoice');
    };
    return (
      <ECButton onClick={onAddInvoice} variant="outlined">
        Add Invoice
      </ECButton>
    );
  }, []);

  const allowedStatusToCreateInvoice = useMemo(
    () => [
      WorkflowStatusGroupName.Complete,
      WorkflowStatusGroupName.SoftCompleted,
    ],
    [],
  );

  const isCreateInvoiceAllowed = useMemo(() => {
    const hasInvoices = workOrder?.invoices?.find(invoice => invoice.id);

    return (
      !!workOrder?.internal &&
      allowedStatusToCreateInvoice?.includes?.(
        workOrder?.statusGroupName as any,
      ) &&
      !hasInvoices
    );
  }, [
    workOrder?.workflowStatus,
    workOrder?.internal,
    workOrder?.invoices,
    allowedStatusToCreateInvoice,
  ]);

  const handleChangeSummaryData = (id: string) => (value: any) => {
    setWorkOrderSummaryData(prev => ({ ...prev, [id]: value }));
  };

  const initializeSummaryFields = useCallback(() => {
    const { assetId, categoryId, problemId } = workOrder || {};

    setWorkOrderSummaryData({
      assetId,
      categoryId,
      problemId,
    });

    setCancelEdit(false);
  }, [workOrder?.assetId, workOrder?.categoryId, workOrder?.problemId]);

  useEffect(() => {
    if (!isDetailsTab) {
      return;
    }

    if (workOrder) {
      initializeSummaryFields();
    }
    if (workOrder?.assetId) {
      triggerAsset(workOrder.assetId);
    }
    if (workOrder?.generalAssetId) {
      triggerOfGeneralAsset(workOrder?.generalAssetId);
    }

    setInternalWorkOrder(false);
  }, [workOrder]);

  const workOrderLocation = useMemo(() => {
    return workOrder?.locationId
      ? {
          href: workOrder.locationId?.toString(),
          text: workOrder.locationName || workOrder.locationId?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const workOrderAsset = useMemo(() => {
    return !workOrder?.pmId && !workOrder?.generalAssetId && workOrder?.assetId
      ? {
          href: workOrder.assetId?.toString(),
          text: workOrder.assetName || workOrder.assetId?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const workOrderGeneralAsset = useMemo(() => {
    return workOrder?.generalAssetId && !workOrder?.pmId
      ? {
          href: workOrder.generalAssetId?.toString(),
          text:
            workOrder.generalAssetName || workOrder.generalAssetId?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const workOrderInvoices = useMemo(() => {
    return workOrder?.invoices && workOrder?.invoices.length > 0
      ? {
          content: workOrder.invoices?.map(x => {
            return { href: x.id, text: x.id };
          }),
        }
      : undefined;
  }, [workOrder]);

  const workOrderProposals = useMemo(() => {
    return workOrder?.proposals && workOrder?.proposals.length > 0
      ? {
          content: workOrder.proposals?.map(x => {
            return { href: x.id, text: x.id };
          }),
        }
      : undefined;
  }, [workOrder]);

  const { data: priorityData, isSuccess: isSuccessPriority } =
    useGetPriorityListQuery(
      {},
      {
        skip: !isEditMode,
      },
    );

  useEffect(() => {
    if (isSuccessPriority && priorityData) {
      const priorityIdField = fancyFormElementsEdit?.fields?.find(
        field => field.fieldName === 'priorityId',
      );
      priorityIdField.readOnly = !hasEditPriorityOnWOPermission;

      const [newCreateFields, _newUpdateFields] = populateDropDownFields({
        responseData: priorityData,
        createFormFields: fancyFormElementsEdit?.fields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'priorityId',
      });

      setFormFields(newCreateFields);
    }
  }, [isSuccessPriority, priorityData, workOrder]);

  useEffect(() => {
    const assigneeUserIdField = fancyFormElementsEdit?.fields?.find(
      field => field.fieldName === 'assigneeUserId',
    );
    assigneeUserIdField.useQuery = useGetUsersByBranchIdQuery;
    assigneeUserIdField.queryParams = workOrder?.locationId || 0;
    assigneeUserIdField.shouldUseOriginalQueryParams = true;
    assigneeUserIdField.value =
      workOrder?.assigneeUser?.id && workOrder?.assigneeUser?.fullName
        ? {
            id: workOrder?.assigneeUser?.id,
            name: workOrder?.assigneeUser?.fullName,
          }
        : undefined;

    setFormFields(fancyFormElementsEdit.fields);
  }, [workOrder?.locationId, workOrder?.assigneeUser]);

  const workOrderRfps = useMemo(() => {
    return workOrder?.rfps && workOrder?.rfps.length > 0
      ? {
          content: workOrder.rfps?.map(x => {
            return { href: x.id, text: x.id };
          }),
        }
      : undefined;
  }, [workOrder]);

  const workOrderPm = useMemo(() => {
    return workOrder?.pmId
      ? {
          href: workOrder.pmId?.toString(),
          text: workOrder.pmName || workOrder.pmId?.toString(),
        }
      : undefined;
  }, [workOrder]);

  const renderInsightsButton = useMemo(() => {
    return (
      <ECInsightsButton
        onClick={() => {
          setDrawerType('insights');
        }}
        title={t('assetInsights.buttonTitle')}
        status={assetInsights?.bookValue}
        icon={<AutoAwesome />}
      />
    );
  }, [assetInsights]);

  const summaryData: SummaryFieldsData[] = useMemo(() => {
    const newSummaryData: SummaryFieldsData[] = [
      {
        id: 'status',
        label: 'WO Status',
        data: workOrder?.workflowStatus,
        type: SummaryFieldTypes.Status,
      },
      {
        id: 'location',
        label: 'Location',
        data: `${workOrder?.locationName ?? ''} ${workOrder?.storeNumber ?? ''}`,
        type: SummaryFieldTypes.Text,
      },
    ];
    const foundAsset = assets?.data?.some(
      asset => asset.id === workOrder?.assetId,
    );

    if (!workOrder?.pmId && foundAsset && assets && assets.data.length > 1) {
      newSummaryData.push({
        id: 'assetId',
        label: 'Asset Name',
        data: workOrderSummaryData?.assetId || workOrder?.assetId,
        type: SummaryFieldTypes.Select,
        defaultData: workOrder?.assetId,
        isEditable: workOrder?.mutableFields?.includes('assetId'),
        onChange: newValue =>
          handleChangeSummaryData('assetId')(newValue?.data),
        options: assets?.data?.map(asset => ({
          label: asset.generalAsset?.name || asset.name,
          data: asset.id,
        })),
        required: true,
        extraComponent:
          !isCreatedFromPM && isAsset ? renderInsightsButton : null,
      });
    } else {
      newSummaryData.push({
        id: 'assetId',
        label: 'Asset Name',
        data: workOrder?.assetName,
        type: SummaryFieldTypes.Text,
        extraComponent:
          !isCreatedFromPM && isAsset ? renderInsightsButton : null,
      });
    }
    newSummaryData.push(
      {
        id: 'serviceProvider',
        label: 'Service Provider',
        data: workOrder?.serviceProviderName || 'Unassigned',
        type: SummaryFieldTypes.Text,
        icon: <ContactInfoIcon fontSize="large" />,
        actionTitle: 'Contact Info',
        onIconClick: () => {
          setShowSPContactInfo(true);
        },
      },
      {
        id: 'priority',
        label: 'Priority',
        data: workOrder?.priorityName,
        type: SummaryFieldTypes.Priority,
      },
      {
        id: 'category',
        label: 'Category',
        data: workOrderSummaryData?.categoryId,
        type: SummaryFieldTypes.Select,
        isEditable: workOrder?.mutableFields?.includes('requestCategoryId'),
        onChange: optionData =>
          handleChangeSummaryData('categoryId')(optionData?.data),
        options: dropdownCategoryList,
        required: true,
      },
      workOrder?.pmId
        ? {
            id: 'problem',
            label: 'Problem',
            data: 'Scheduled Service',
            type: SummaryFieldTypes.Text,
          }
        : {
            id: 'problem',
            label: 'Problem',
            data: workOrderSummaryData?.problemId,
            type: SummaryFieldTypes.Select,
            isEditable: workOrder?.mutableFields?.includes('problemId'),
            onChange: optionData =>
              handleChangeSummaryData('problemId')(optionData?.data),
            options: uniqBy(
              compact([
                workOrder?.problem && workOrder.problemId
                  ? {
                      label: workOrder?.problem,
                      data: workOrder?.problemId,
                    }
                  : null,
                ...(problemsList?.data?.map(problem => ({
                  label: problem.name,
                  data: problem.id,
                })) || []),
              ]),
              'id',
            ),
            required: true,
          },
    );

    const coupaInvoice = workOrder?.invoices?.find(invoice => invoice.coupaId);
    if (coupaInvoice?.coupaPoUrl) {
      newSummaryData.push({
        id: 'coupaId',
        label: '',
        type: SummaryFieldTypes.CoupaCode,
        data: coupaInvoice?.coupaId,
        placeholder: 'Please log in to Coupa to view your invoice',
        href: coupaInvoice.coupaPoUrl || '',
      });
    } else {
      const failedCoupaInvoice = workOrder?.invoices?.find(
        invoice => invoice.retryCoupa,
      );
      if (failedCoupaInvoice) {
        newSummaryData.push({
          id: 'coupaId',
          label: '',
          type: SummaryFieldTypes.CoupaCode,
          data: workOrder?.requestId,
          placeholder: 'Please log in to Coupa to view your invoice',
          retryCoupa: true,
        });
      }
    }

    if (surveyFeedback?.score) {
      newSummaryData.push({
        id: 'spPerformanceScore',
        label: 'SP Performance Score',
        data: surveyFeedback?.score,
        type: SummaryFieldTypes.Rating,
      });
    }

    return newSummaryData;
  }, [
    workOrder,
    assets,
    workOrderSummaryData,
    dropdownCategoryList,
    problemsList,
    handleChangeSummaryData,
    userProfile,
    surveyFeedback,
    renderInsightsButton,
    isEditMode,
  ]);

  const closeReassignModal = useCallback(() => {
    setIsOpenReassignModal(false);
  }, []);

  const closeUnassignModal = useCallback(() => {
    setIsOpenUnassignModal(false);
  }, []);

  const closeCancelModal = useCallback(() => {
    setIsOpenCancelModal(false);
  }, []);

  const closeAcceptModal = useCallback(() => {
    setIsOpenAcceptModal(false);
  }, []);

  const closeReassignTechnicianModal = useCallback(() => {
    setIsOpenReassignTechnicianModal(false);
    setWoDataConfirmAssignTechnician(null);
    setAssignTechicianComment('');
  }, []);

  const additionalActions = useMemo(() => {
    let actions: any = [];
    if (isCreateInvoiceAllowed) {
      actions.push(addInvoice);
    }
    if (
      (workOrder?.statusGroupName &&
        (reassignWoStatus.includes(
          workOrder?.statusGroupName as WorkflowStatusGroupName,
        ) ||
          (workOrder?.spCode === SPType.INTERNAL_TECH &&
            internalTechAssignedWoStatus.includes(
              workOrder?.statusGroupName as WorkflowStatusGroupName,
            )))) ||
      (workOrder?.internal &&
        reassignInternalWoStatus.includes(
          workOrder?.statusGroupName as WorkflowStatusGroupName,
        ))
    ) {
      actions.push(
        <ECButton
          key={reassignAction.buttonLabel}
          onClick={() => setIsOpenReassignModal(true)}
          scopes={reassignAction.scopes}
        >
          {reassignAction.buttonLabel}
        </ECButton>,
      );
    }
    return actions.length > 0 ? actions : undefined;
  }, [
    workOrder?.workflowStatus,
    isCreateInvoiceAllowed,
    addInvoice,
    reassignAction,
    reassignWoStatus,
    workOrder?.spCode,
  ]);

  const linkedWOListField = useMemo(() => {
    return fancyFormElementsLinkedWO?.fields.find(
      field => field.fieldName === 'linkedWOListField',
    );
  }, [fancyFormElementsLinkedWO]);
  const [trigger, result] = useLazyGetPossibleWOLinksQuery();
  const { data: dataPossibleWOLinks, isSuccess: isSuccessDataPossibleWOLinks } =
    result;

  useEffect(() => {
    if (!isDetailsTab) {
      return;
    }

    trigger({
      woId: id ? Number(id) : 0,
      params: {
        sb: 'wrkordr.id',
        s: `*${searchWOs}*`,
        o: 'd',
        ob: '',
      },
    });
  }, [searchWOs]);

  const [searchOptionsState, setSearchOptionsState] = useState<
    ChipSearchOptions | undefined
  >();
  useEffect(() => {
    if (dataPossibleWOLinks && isSuccessDataPossibleWOLinks) {
      const newOptions =
        dataPossibleWOLinks?.data?.map(d => ({
          label: 'Work Order ID #' + d.id,
        })) || [];
      linkedWOListField.options = newOptions;
      linkedWOListField.optionValues =
        dataPossibleWOLinks?.data?.map(d => d.id) || [];
      const newSearchItem: ChipSearchOptions = {
        options: newOptions,
        searchString: searchWOs,
      };
      setSearchOptionsState(newSearchItem);
    }
  }, [dataPossibleWOLinks, isSuccessDataPossibleWOLinks]);

  const handleLinkedWOClick = () => {
    setDrawerType('linkedWO');
  };

  const [deleteLinkedWorkorders, isSuccess] =
    useDeleteLinkedWorkordersMutation();

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    href: null,
  });

  const handleLinkedWODeleteClick = async (href: any) => {
    setDeleteConfirmation({ open: true, href });
  };

  const handleDeleteConfirmed = async href => {
    await deleteLinkedWorkorders({
      workorderId: workOrder?.id,
      linkedWorkordersIds: [href],
    } as WOLinkedParamsMutate);
    setDeleteConfirmation({ open: false, href: null });
    refetch();
  };

  const useCreateLinkedWorkorders = () => {
    const [
      doCreateLinkedWorkorders,
      {
        data: createLinkedWorkordersData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        reset: resetCreateLinkedWorkorders,
      },
    ] = useCreateLinkedWorkordersMutation();

    const doCreate = async data => {
      const woIds = (data?.linkedWOListField
        ?.map(item => Number(item?.label?.split('#')?.[1]))
        .filter(Boolean) || []) as number[];
      doCreateLinkedWorkorders({
        workorderId: workOrder?.id,
        linkedWorkordersIds: woIds,
      } as WOLinkedParamsMutate);

      const setLocation = () => {
        window.location.href = `${match?.pathnameBase}/workorder/${workOrder?.id}`;
      };
      const debouncedSetLocation = debounce(
        setLocation,
        REDIRECT_DEBOUNCE_TIME,
      );
      debouncedSetLocation();
    };

    return [
      doCreate,
      {
        data: createLinkedWorkordersData,
        isError: isCreateError,
        error: createError,
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
      },
    ];
  };

  const debouncedHandleSearchWO = useRef(
    debounce(value => {
      setSearchWOs(value);
    }, SEARCH_DEBOUNCE_TIME),
  ).current;

  const handleSearchWO = value => {
    debouncedHandleSearchWO(value);
  };

  const handleConfirmSubmitComment = useCallback(() => {
    const updatedData = {
      ...submitData,
      noteToAssignee: comment,
    };
    doUpdateWorkOrder(updatedData);
    dispatch(commentApi.util.invalidateTags(['Comment']));
  }, [submitData, comment, doUpdateWorkOrder, dispatch]);

  const handleCloseCommentModal = () => {
    setComment('');
    setShowCommentModal(false);
  };

  const handleInventoryDrawerClose = useCallback(() => {
    handleClose();
    refetch();
  }, []);

  const [triggerComment, { data: comments }] = useLazyGetCommentListQuery();

  const handlePrintButtonClick = (e: any) => {
    setAnchorElPrint(e?.currentTarget);
    setOpenPrintActionPopover(true);
  };

  const handlePrintActionClick = (includeComments: boolean) => {
    setOpenPrintActionPopover(false);
    setAnchorElPrint(null);
    if (!includeComments) {
      const wo = {
        ...workOrder,
        assetImageURL: workOrderPm
          ? undefined
          : workOrder?.assetImageURL || assetProfileAttachment?.url,
      };
      const htmlContent = generateWODetailsHTMLContent(
        wo,
        assetObject,
        assetWarranty?.[0]?.status,
      );
      const newTab = window.open();
      if (newTab) {
        newTab.document.write(htmlContent);
        const debouncedPrint = debounce(() => {
          newTab.print();
        }, PRINT_DEBOUNCE_TIME);
        debouncedPrint();
      }
    }
    if (includeComments) {
      setShouldIncludeComments(true);
      triggerComment({
        requestId: String(workOrder?.requestId),
        module: 'workorder',
        t: 100,
      });
    }
  };

  useEffect(() => {
    if (shouldIncludeComments && comments) {
      setShouldIncludeComments(false);

      const commentList: any[] = cloneDeep(comments?.data || []);

      const wo = {
        ...workOrder,
        assetImageURL: workOrderPm
          ? undefined
          : workOrder?.assetImageURL || assetProfileAttachment?.url,
      };
      const htmlContent = generateWODetailsHTMLContent(
        wo,
        assetObject,
        assetWarranty?.[0]?.status,
        true,
        commentList,
      );
      const newTab = window.open();
      if (newTab) {
        newTab.document.write(htmlContent);
        const debouncedPrint = debounce(() => {
          newTab.print();
        }, PRINT_DEBOUNCE_TIME);
        debouncedPrint();
      }
    }
  }, [shouldIncludeComments, comments]);

  const additionalTabs = useMemo(() => {
    return assetObject?.refrigerantTrackingEnable === 1
      ? [
          {
            value: 'refrigerant',
            label: 'Refrigerant Tracking',
            scopes: [P.GetWorkOrder],
            content: <Outlet />,
            link: 'refrigerant',
            editable: false,
            visible: true,
          },
        ]
      : [];
  }, [assetObject?.refrigerantTrackingEnable]);

  const isCustomer = useCustomerUser();

  if (!isCustomer) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <ECWorkflowTemplate
        tabsAsLinks
        title={`${t('translation:workflow.sidebar.workOrder')} #${workOrder?.id ?? ''}`}
        summaryData={summaryData}
        workflowStatus={workOrder?.workflowStatus}
        workflowStatusGroupName={workOrder?.statusGroupName}
        useUpdateMutation={useUpdateWorkOrder}
        editConfig={fancyFormElementsEdit.config}
        editFields={formFields}
        detailsConfig={formElementsDetails.config}
        detailsFields={detailsFormFields}
        detailsData={workOrder}
        imgSrc={
          workOrderPm
            ? undefined
            : workOrder?.assetImageURL || assetProfileAttachment?.url
        }
        profileAttachment={assetProfileAttachment}
        beInEditMode={isEditing}
        moduleName={moduleName}
        moduleId={id}
        actions={workOrderNextStatusActions}
        onCopyClick={
          workOrder?.pmId || workOrder?.locationOnHold === 1
            ? undefined
            : handleCopyClick
        }
        onActionClick={doUpdateStatus}
        onChange={onChangeEditFields}
        onLinkedWOClick={handleLinkedWOClick}
        onLinkedWODeleteClick={handleLinkedWODeleteClick}
        onEditButtonClick={setIsEditMode}
        cancelEdit={cancelEdit}
        asset={workOrderAsset}
        generalAsset={workOrderGeneralAsset}
        invoices={workOrderInvoices}
        proposals={workOrderProposals}
        rfps={workOrderRfps}
        workOrder={id ? { href: id, text: id } : undefined}
        pm={workOrderPm}
        linkedWO={linkedWO}
        location={workOrderLocation}
        additionalActions={additionalActions}
        showAdditionalButtons={false}
        warrantyBadgeType={assetWarranty?.[0]?.status}
        showAddLinkedWOButton={true}
        onExitEditModeWithoutSave={initializeSummaryFields}
        isEditAllowed={
          hasEditWorkorderPermission && !!workOrder?.mutableFields?.length
        }
        submissionConfirmationModal={{
          title:
            'Do you want to set this Work Order to "Internally Managed? The Service Provider will no longer be able to make updates to this Work Order.',
          buttonText: 'Confirm',
          bodyText: '',
          isVisible: internalWorkOrder,
          onClose: () => {
            setInternalWorkOrder(false);
            setCancelEdit(true);
          },
        }}
        isLoadingActions={isLoadingAction}
        showPrintButton
        onPrintButtonClick={handlePrintButtonClick}
        isFeedbackAlreadySubmitted={isFeedbackAlreadySubmitted}
        additionalTabs={additionalTabs}
      />
      {isDetailsTab && workOrder && (
        <>
          {workOrder?.locationOnHold !== 1 && (
            <CopyWorkorder
              isOpen={drawerType === 'copy'}
              onClose={handleClose}
              existingData={existingDataCopyDrawer}
            />
          )}
          <AllocatedInventoryItems
            isOpen={drawerType === 'allocateInventoryItems'}
            onClose={handleInventoryDrawerClose}
            existingData={existingDataAllocatedInventoryItemsDrawer}
          />
          <AdjustAllocatedInventoryItems
            isOpen={drawerType === 'adjustAllocatedInventoryItems'}
            onClose={handleInventoryDrawerClose}
            existingData={existingDataAllocatedInventoryItemsDrawer}
            allocatedInventoryItems={{
              allocatedItems: workOrder?._allocatedInventory,
            }}
          />
          <ECDrawerDetails
            open={drawerType === 'linkedWO'}
            anchor="right"
            onClose={handleClose}
          >
            <ECPaper
              sx={{ height: '100%', paddingTop: StyleConstants.NAV_BAR_HEIGHT }}
              role="presentation"
            >
              <ECEasyFormCreate
                useCreateMutation={useCreateLinkedWorkorders}
                onClose={handleClose}
                formConfig={fancyFormElementsLinkedWO?.config}
                formFields={fancyFormElementsLinkedWO?.fields}
                existingData={dataPossibleWOLinks?.data}
                onChangeSearch={handleSearchWO}
                searchOptionsState={searchOptionsState}
              />
            </ECPaper>
          </ECDrawerDetails>
          <CreateInvoiceDrawer
            allocatedInventoryItems={workOrder?._allocatedInventory}
            isOpen={drawerType === 'invoice'}
            onClose={handleClose}
            existingData={{
              ...workOrder,
              requestId: workOrder.requestId, // not visible, but required for creation
              workorderId: workOrder.id,
              assetName: workOrder.assetName,
              problem: workOrder.problem,
              branchName: workOrder.locationName,
              customer: workOrder.companyName,
              nte: workOrder.nte,
              workflowStatusId: 1,
              workflowId: workOrder.workflowId,
              internal: workOrder.internal,
              assetId: workOrder.assetId,
              requestCategoryName: workOrder.category,
              requiredFields: workOrder.requiredFields,
              isActiveWarranty: ['active', 'partial'].includes(
                assetWarranty?.[0]?.status?.toLowerCase(),
              ),
            }}
          />
          <ECModal
            p={2}
            noPaddingBottom
            isOpen={isOpenReassignTechnicianModal}
            onClose={closeReassignTechnicianModal}
          >
            <ECBox height={200} overflow="auto">
              <ECWorkOrderChangeTechnicianCommentModalContent
                comment={assignTechicianComment}
                isLoading={isUpdateLoading}
                handleCloseModal={closeReassignTechnicianModal}
                handleChangeComment={setAssignTechicianComment}
                onConfirmSubmit={handleConfirmSubmitCommentAssignTechnician}
              />
            </ECBox>
          </ECModal>
          <ECModal
            isOpen={isOpenReassignModal}
            onClose={closeReassignModal}
            noPadding
          >
            <reassignAction.modalContent
              onClose={closeReassignModal}
              onCancel={closeReassignModal}
              module="workorder"
              selectedRows={[workOrder]}
              invalidateQuery={() => {
                dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
                dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
                dispatch(
                  workOrdersApi.util.invalidateTags(['WorkOrderStatusList']),
                );
                dispatch(
                  dashboardApi.util.invalidateTags([
                    'PendingWorkorders',
                    'UnassignedWorkorders',
                  ]),
                );
              }}
              additionalData={{
                tradeId: workOrder.tradeId,
                branchId: workOrder.locationId,
              }}
            />
          </ECModal>
          <ECModal
            isOpen={isOpenUnassignModal}
            onClose={closeUnassignModal}
            noPadding
          >
            <unassignAction.modalContent
              onClose={closeUnassignModal}
              onCancel={closeUnassignModal}
              module="workorder"
              selectedRows={[workOrder]}
              isLoadingCustomAction={isLoadingAction}
              customAction={(noteValue: string) => {
                doUpdateWorkOrderStatus({
                  id: workOrder?.id || 0,
                  ...statusData,
                  note: noteValue,
                });
              }}
              invalidateQuery={() => {
                dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
                dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
                dispatch(
                  workOrdersApi.util.invalidateTags(['WorkOrderStatusList']),
                );
                dispatch(
                  dashboardApi.util.invalidateTags([
                    'PendingWorkorders',
                    'UnassignedWorkorders',
                  ]),
                );
              }}
            />
          </ECModal>
          <ECModal
            isOpen={isOpenCancelModal}
            onClose={closeCancelModal}
            noPadding
          >
            <cancelAction.modalContent
              onClose={closeCancelModal}
              customAction={(noteValue: string) => {
                doUpdateWorkOrderStatus({
                  id: workOrder?.id || 0,
                  ...statusData,
                  note: noteValue,
                });
              }}
              isLoadingCustomAction={isLoadingAction}
              onCancel={closeCancelModal}
              module="workorder"
              selectedRows={[workOrder]}
              invalidateQuery={() => {
                dispatch(workOrdersApi.util.invalidateTags(['WorkOrderById']));
                dispatch(
                  workOrdersApi.util.invalidateTags(['WorkOrderStatusList']),
                );
                dispatch(moduleApi.util.invalidateTags(['ModuleDetails']));
                dispatch(
                  dashboardApi.util.invalidateTags([
                    'PendingWorkorders',
                    'UnassignedWorkorders',
                  ]),
                );
              }}
            />
          </ECModal>
          <ECModal
            isOpen={isOpenAcceptModal}
            onClose={closeAcceptModal}
            noPadding
          >
            <UpdateETAAction
              onClose={closeAcceptModal}
              onCancel={closeAcceptModal}
              customAction={() => {
                doUpdateWorkOrderStatus({
                  id: workOrder?.id || 0,
                  ...statusData,
                });
              }}
              selectedWO={workOrder}
            />
          </ECModal>
          <ECConfirmationDialog
            open={deleteConfirmation.open}
            title={'Delete Linked Work Order Confirmation'}
            text={'Are you sure you want to delete this linked work order?'}
            onClose={() => setDeleteConfirmation({ open: false, href: null })}
            acceptButtonTitle={'Delete'}
            acceptButtonColor={'error'}
            onCancelClick={() =>
              setDeleteConfirmation({ open: false, href: null })
            }
            onAcceptClick={() => handleDeleteConfirmed(deleteConfirmation.href)}
          />
          <ECModal
            isOpen={showSPContactInfo}
            onClose={() => setShowSPContactInfo(false)}
            noPadding
          >
            <ECBox
              bgcolor={theme => theme.palette.common.white}
              sx={{
                position: 'relative',
                padding: '20px',
                borderRadius: '8px',
              }}
            >
              <ECIconButton
                onClick={() => setShowSPContactInfo(false)}
                style={{ position: 'absolute', top: 10, right: 10 }}
              >
                <Close />
              </ECIconButton>
              <ECBox
                display="flex"
                flexDirection="column"
                sx={{
                  width: '500px',
                }}
                p={1}
              >
                <ECEasyForm
                  pattern="filled"
                  fullHeight={false}
                  config={{
                    variant: 'filled',
                    title: 'Service Provider Contact Info',
                    subtitle: '',
                    titleStyle: {
                      fontSize: '24px',
                      fontWeight: 500,
                    },
                    cols: 1,
                  }}
                  fields={[
                    {
                      type: FieldTypes.Text,
                      label: 'Company Name',
                      isNumeric: false,
                      fieldName: 'serviceProviderName',
                      value: workOrder?.serviceProviderName,
                      variant: 'standard',
                      overrideRowValue: true,
                      readOnly: true,
                    },
                    {
                      type: FieldTypes.Group,
                      fieldName: 'group',
                      groupColumns: 2,
                      subFields: [
                        {
                          type: FieldTypes.Phone,
                          label: 'Phone',
                          readOnly: true,
                          fieldName: 'serviceProviderPhone',
                          variant: 'standard',
                          overrideRowValue: true,
                          value: workOrder?.serviceProviderPhone,
                        },
                        {
                          type: FieldTypes.Text,
                          label: 'EXT',
                          readOnly: true,
                          fieldName: 'serviceProviderPhoneExt',
                          variant: 'standard',
                          overrideRowValue: true,
                          value: workOrder?.serviceProviderPhoneExt,
                        },
                        {
                          type: FieldTypes.Phone,
                          label: '24 hr Phone',
                          readOnly: true,
                          variant: 'standard',
                          overrideRowValue: true,
                          fieldName: 'serviceProviderPhone24',
                          value: workOrder?.serviceProviderPhone24,
                        },
                        {
                          type: FieldTypes.Text,
                          label: '24 hr Ext',
                          readOnly: true,
                          variant: 'standard',
                          overrideRowValue: true,
                          fieldName: 'serviceProviderPhone24Ext',
                          value: workOrder?.serviceProviderPhone24Ext,
                        },
                      ],
                    },
                    {
                      type: FieldTypes.Text,
                      label: 'Email',
                      placeholder: 'Email',
                      variant: 'standard',
                      overrideRowValue: true,
                      value: workOrder?.serviceProviderEmail,
                      readOnly: true,
                      fieldName: 'serviceProviderEmail',
                    },
                  ]}
                  isSendingData={false}
                  isLoadingForm={false}
                  showSaveButton={false}
                />
              </ECBox>
            </ECBox>
          </ECModal>
          <ECModal
            p={2}
            noPaddingBottom
            isOpen={showCommentModal}
            onClose={handleCloseCommentModal}
          >
            <ECBox height={200} overflow="auto">
              <ECWorkOrderChangeTechnicianCommentModalContent
                comment={comment}
                isLoading={isUpdateLoading}
                handleCloseModal={handleCloseCommentModal}
                handleChangeComment={setComment}
                onConfirmSubmit={handleConfirmSubmitComment}
              />
            </ECBox>
          </ECModal>
          {assetObject && !isLoadingAssetInsights && (
            <ECInsightsDrawer
              isOpen={drawerType === 'insights'}
              onClose={handleClose}
              insightsData={assetInsights || null}
              asset={assetObject}
            />
          )}
          {openPrintActionPopover && (
            <ECPrintCard
              open={openPrintActionPopover}
              handleClose={() => {
                setAnchorElPrint(null);
                setOpenPrintActionPopover(false);
              }}
              handlePrintActionClick={handlePrintActionClick}
              module={'workorder'}
              anchorEl={anchorElPrint}
            />
          )}
        </>
      )}
    </>
  );
}
