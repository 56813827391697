import { ECBox } from '../ECBox';
import { ECFilePicker, FilePickerTypes } from '../ECFilePicker';
import { AttachmentCategory, AttachmentList } from 'types/Attachment';
import {
  useCheckAllUplaodedRequiredDocsBySPAndCustomerQuery,
  useDeleteAttachmentMutation,
  useGetAttachmentDocumentTypesQuery,
  useGetAttachmentQuery,
  useLazyDownloadAttachmentQuery,
  useUpdateAttachmentMutation,
  useUploadAttachmentMutation,
} from 'services/attachmentApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';
import { useTranslation } from 'react-i18next';
import { ECModal } from '../ECModal';
import { Close, Delete, Download, Edit, FileCopy } from '@mui/icons-material';
import { ECIconButton } from '../ECIconButton';
import { ECImage } from '../ECImage';
import { ECTypography } from '../ECTypography';
import { ECToggleButton } from '../ECToggleButton';
import {
  ECEasyFormFieldType,
  ECFormControlLabel,
  ECFormGroup,
  ECFormLabel,
  FieldTypes,
} from '../ECForm';
import { EditForm2 } from './EditForm2';
import { ECRequiredDocument } from '../ECRequiredDocument';
import { useParams } from 'react-router';
import { useGetModuleDetailsByIdQuery } from 'services/moduleApi';
import _ from 'lodash';
import { useCustomerUser } from 'app/hooks';
import { requirePermissionWrapper } from 'app/hoc/require-permission';
import { FormHelperText } from '@mui/material';
import { PDFObject } from 'react-pdfobject';
import moment from 'moment';
import { ECSwitch } from '../ECSwitch';
import { ECAttachmentDeleteAlert } from '../ECAttachmentDeleteAlert';
import { useHasPermission } from 'app/hooks/hasPermission.use-case';
import { P } from 'types/Permission';

export interface AttachmentTabProps {
  label?: string;
  placeholder?: string;
  onChange?: any;
  helperText?: string | null;
  fieldName?: string;
  sx?: any;
  attachments?: AttachmentList;
  row?: any;
  moduleName?: string;
  moduleNameToFetchDetails?: string;
  ownModuleName?: string;
  idAttributeName?: string;
  isCustomerRequiredDocs?: boolean;
  isSpRequiredDocs?: boolean;
  isCustomerOwnRequiredDocs?: boolean;
  requiredDocsCustomerIdName?: string;
  requiredDocsSpIdName?: string;
  requiredDocSpIdFromProps?: string | number;
  confirmationDeleteMessage?: string;
  showDeleteSwitch?: boolean;
  showDeleteAlert?: boolean;
  deleteAlertTitle?: string;
  deleteAlertText?: string;
  isReadOnly?: boolean;
  canAddAttachment?: P;
  canEditAttachment?: P;
}

const AttachmentTabContent = ({
  label,
  placeholder,
  onChange,
  helperText,
  fieldName,
  moduleName: moduleNameFromProps,
  moduleNameToFetchDetails,
  ownModuleName,
  attachments: attachmentsFromProps,
  row,
  idAttributeName = 'id',
  isCustomerRequiredDocs = false,
  isSpRequiredDocs = false,
  isCustomerOwnRequiredDocs = false,
  requiredDocsCustomerIdName,
  requiredDocsSpIdName,
  requiredDocSpIdFromProps,
  confirmationDeleteMessage,
  showDeleteSwitch = false,
  showDeleteAlert = false,
  deleteAlertTitle,
  deleteAlertText,
  isReadOnly,
  canAddAttachment,
  canEditAttachment,
  ...props
}: AttachmentTabProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const isCustomer = useCustomerUser();
  const hasAddAttachmentsPermission =
    useHasPermission(canAddAttachment ? [canAddAttachment] : [])?.[0] ??
    undefined;
  const hasEditAttachmentsPermission =
    useHasPermission(canEditAttachment ? [canEditAttachment] : [])?.[0] ??
    undefined;

  const moduleName = useMemo(() => {
    return moduleNameFromProps ?? row?.moduleName ?? '';
  }, [moduleNameFromProps, row?.moduleName]);

  const { data: moduleData } = useGetModuleDetailsByIdQuery(
    {
      id: id || '',
      moduleName: `${
        isCustomer || moduleName === 'spcustomer' || moduleName === 'user'
          ? ''
          : 'sp/'
      }${moduleNameToFetchDetails || moduleName || ''}`,
    },
    { skip: !id },
  );

  const { data: attachments } = useGetAttachmentQuery(
    {
      module: moduleName ?? row?.moduleName ?? '',
      moduleId: _.get(moduleData || row, idAttributeName, ''),
      category: AttachmentCategory.All,
      isCustomerRequiredDoc: isCustomerRequiredDocs,
    },
    {
      skip:
        (!moduleName && !row?.moduleName) ||
        !_.get(moduleData || row, idAttributeName),
    },
  );

  const [selectedAttachmentId, setSelectedAttachmentId] = useState<
    number | null
  >(null);
  const selectedAttachment = useMemo(
    () =>
      attachments?.others?.find(
        attachment => attachment.id === selectedAttachmentId,
      ),
    [selectedAttachmentId, attachments?.others],
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldsValues, setFieldsValues] = useState<any>({});
  const [isUploadingReqDoc, setIsUploadingReqDoc] = useState(false);

  const [editingAttachment, setEditingAttachment] = useState<any>(null);
  const [deletingAttachmentId, setDeletingAttachmentId] = useState<any>(null);

  const handleFieldChange = (fieldName: string) => (value: any) => {
    setFieldsValues(prevValues => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    setFieldsValues(prev => ({
      fileName: selectedAttachment?.fileName,
      documentCoreType:
        (isUploadingReqDoc && prev.documentCoreType) ||
        selectedAttachment?.documentCoreType?.id ||
        (isEditMode && documentTypes?.find(docTp => docTp.default === 1)?.id) ||
        documentTypes?.[0]?.id ||
        '',
      description: selectedAttachment?.description,
      uploadedBy: selectedAttachment?.createdByUserEmail,
      uploadDate: moment(selectedAttachment?.createdAt)?.format('MM/DD/YYYY'),
      isRestricted: selectedAttachment?.isRestricted,
      originalName: selectedAttachment?.originalName?.split('.')[0],
      name: selectedAttachment?.originalName?.split('.')[0],
      fileType: selectedAttachment?.originalName?.split('.')?.pop(),
      expires: selectedAttachment?.expires,
      moduleId: selectedAttachment?.moduleId,
    }));
  }, [editingAttachment, isEditMode, isUploadingReqDoc]);

  useEffect(() => {
    if (!editingAttachment) {
      setEditingAttachment(selectedAttachment);
    }
  }, [selectedAttachment]);

  const dispatch = useDispatch();

  const [
    doUploadAttachment,
    {
      data: uploadAttachmentResponse,
      isError: isUploadAttachmentError,
      isSuccess: isUploadAttachmentSuccess,
      isLoading: isUploadAttachmentLoading,
      error: uploadAttachmentError,
      reset: resetUploadAttachment,
    },
  ] = useUploadAttachmentMutation();

  const [
    doDeleteAttachment,
    {
      data: deleteAttachmentResponse,
      isError: isDeleteAttachmentError,
      isSuccess: isDeleteAttachmentSuccess,
      reset: resetDeleteAttachment,
    },
  ] = useDeleteAttachmentMutation();

  const [
    doUpdateAttachment,
    {
      isError: isUpdateAttachmentError,
      isSuccess: isUpdateAttachmentSuccess,
      isLoading: isUpdateAttachmentLoading,
      error: updateAttachmentError,
      reset: resetUpdateAttachment,
    },
  ] = useUpdateAttachmentMutation();

  const [triggerDownload] = useLazyDownloadAttachmentQuery();

  const { data: documentTypes } = useGetAttachmentDocumentTypesQuery(
    ownModuleName || moduleName,
    { skip: !ownModuleName && !moduleName },
  );

  const { data: requiredDocs } =
    useCheckAllUplaodedRequiredDocsBySPAndCustomerQuery(
      isCustomerOwnRequiredDocs ||
        (!isCustomerRequiredDocs && !isSpRequiredDocs)
        ? { spId: '', customerId: '' }
        : {
            customerId:
              isCustomerRequiredDocs || isSpRequiredDocs
                ? _.get(
                    moduleData || row,
                    requiredDocsCustomerIdName || idAttributeName,
                    '',
                  )
                : '',

            spId:
              (requiredDocSpIdFromProps
                ? requiredDocSpIdFromProps
                : _.get(
                    moduleData || row,
                    requiredDocsSpIdName || idAttributeName,
                    '',
                  )) || '',
          },
      {
        skip:
          !(isCustomerRequiredDocs || isSpRequiredDocs
            ? _.get(
                moduleData || row,
                requiredDocsCustomerIdName || idAttributeName,
                '',
              )
            : '') ||
          !(requiredDocSpIdFromProps
            ? requiredDocSpIdFromProps
            : _.get(
                moduleData || row,
                requiredDocsSpIdName || idAttributeName,
                '',
              )),
      },
    );

  const isAssetDocumentTypeSelected = useMemo(() => {
    if (!fieldsValues.documentCoreType) {
      return selectedAttachment?.isRestricted;
    }
    const assetDocumentTypeIds = documentTypes
      ?.filter(type => type.private === 1)
      ?.map(assetDocumentType => assetDocumentType.id);

    return (
      assetDocumentTypeIds?.includes(fieldsValues.documentCoreType) ?? false
    );
  }, [documentTypes, fieldsValues, selectedAttachment?.isRestricted]);

  const isExpiresOnFieldVisible = useMemo(() => {
    // the expiresOn field is visible if the selected document type has expires === 1, this is set directly in the database.
    if (!fieldsValues.documentCoreType) {
      return selectedAttachment?.expiresOn;
    }
    const expiresOnDocumentTypeIds = documentTypes
      ?.filter(type => type.expires === 1)
      ?.map(expiresOnDocumentType => expiresOnDocumentType.id);

    return (
      expiresOnDocumentTypeIds?.includes(fieldsValues.documentCoreType) ?? false
    );
  }, [documentTypes, fieldsValues, selectedAttachment?.expiresOn]);

  const useUpdateAttachment = useCallback(() => {
    const doUpdate = async data => {
      const updatedData = {
        module: moduleName,
        moduleId: _.get(moduleData || row, idAttributeName, ''),
        // I'm checking selectedAttachment.moduleId because required docs use the req doc ID to update not the attach
        attachmentId: isCustomerOwnRequiredDocs
          ? selectedAttachment?.moduleId
          : data?.id ?? 0,
        isRestricted: isAssetDocumentTypeSelected
          ? data.isRestricted?.length
          : 0,
        description: data.description,
        documentCoreTypeId: data.documentCoreType,
        expiresOn: data?.expiresOn,
        isRequiredDoc: isCustomerOwnRequiredDocs,
        isReqDocExpirationDate:
          data.isReqDocExpirationDate && data.isReqDocExpirationDate.length
            ? true
            : false,
      };

      doUpdateAttachment(updatedData);
    };

    return [
      doUpdate,
      {
        isError: isUpdateAttachmentError,
        error: updateAttachmentError,
        isLoading: isUpdateAttachmentLoading,
        isSuccess: isUpdateAttachmentSuccess,
        reset: resetUpdateAttachment,
      },
    ];
  }, [
    idAttributeName,
    row,
    moduleData,
    moduleName,
    isUpdateAttachmentError,
    updateAttachmentError,
    isUpdateAttachmentLoading,
    isUpdateAttachmentSuccess,
    resetUpdateAttachment,
    doUpdateAttachment,
    isAssetDocumentTypeSelected,
    isCustomerOwnRequiredDocs,
    selectedAttachment?.moduleId,
  ]);

  const useUploadAttachment = useCallback(() => {
    const doUpload = async data => {
      const uploadData = {
        module: moduleName,
        thumbnailIndex: 0,
        id: _.get(moduleData || row, idAttributeName, ''),
        files: [editingAttachment.processedFile],
        description: data.description,
        isRestricted: isAssetDocumentTypeSelected
          ? data.isRestricted?.length
          : 0,
        documentCoreTypeId: data.documentCoreType,
        name: data.fileName,
        expiresOn: data.expiresOn,
        documentRequiredId:
          isSpRequiredDocs && (isCustomerOwnRequiredDocs || !isCustomer)
            ? requiredDocs?.find(
                rdoc =>
                  rdoc.documentCoreTypeId ===
                  (data.attachment
                    ? data.attachment?.documentCoreType?.id
                    : data.documentCoreType),
              )?.id
            : undefined,
        isCustomerOwnRequiredDocs: isCustomerOwnRequiredDocs,
        isReqDocExpirationDate:
          data.isReqDocExpirationDate && data.isReqDocExpirationDate.length
            ? true
            : false,
      };
      doUploadAttachment(uploadData);
    };

    return [
      doUpload,
      {
        isError: isUploadAttachmentError,
        isLoading: isUploadAttachmentLoading,
        isSuccess: isUploadAttachmentSuccess,
        error: uploadAttachmentError,
        reset: resetUploadAttachment,
      },
    ];
  }, [
    isUploadAttachmentError,
    uploadAttachmentError,
    isUploadAttachmentLoading,
    isUploadAttachmentSuccess,
    resetUploadAttachment,
    moduleName,
    doUploadAttachment,
    isAssetDocumentTypeSelected,
    editingAttachment,
    moduleData,
    requiredDocs,
  ]);

  const handleDelete = (attachmentId?: number) => {
    const id = attachmentId;
    const data = moduleData || row;
    if (!id || !data) return;
    doDeleteAttachment({
      module: moduleName,
      moduleId: _.get(data, idAttributeName, ''),
      attachmentId: id,
      isCustomerOwnRequiredDocs,
    });
  };

  const handleChange = (files: any) => {
    const newAddedFile = files.find(file => !file.id);
    setEditingAttachment(newAddedFile);
    setIsEditMode(true);
  };

  useEffect(() => {
    if (isDeleteAttachmentSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message:
            (deleteAttachmentResponse as any)?.message ??
            t('translation:pages.attachmentTab.deleteSuccess'),
        }),
      );
      handleCloseModal();
    } else if (isDeleteAttachmentError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            (deleteAttachmentResponse as any)?.message ??
            t('translation:pages.attachmentTab.deleteError'),
        }),
      );
    }

    resetUploadAttachment();
    resetDeleteAttachment();
  }, [isDeleteAttachmentSuccess, isDeleteAttachmentError, dispatch, t]);

  useEffect(() => {
    if (isUploadAttachmentSuccess) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message:
            (uploadAttachmentResponse as any)?.message ??
            (isCustomerRequiredDocs
              ? t('translation:pages.attachmentTab.reqDocUploadSuccess')
              : t('translation:pages.attachmentTab.uploadSuccess')),
        }),
      );
      handleCloseModal();
    } else if (isUploadAttachmentError) {
      dispatch(
        setSnackbar({
          severity: 'error',
          message:
            (uploadAttachmentResponse as any)?.message ??
            (uploadAttachmentError as any)?.data?.message ??
            t('translation:pages.attachmentTab.uploadError'),
        }),
      );
    }

    resetUploadAttachment();
    resetDeleteAttachment();
  }, [
    isUploadAttachmentSuccess,
    isUploadAttachmentError,
    dispatch,
    t,
    uploadAttachmentError,
  ]);

  const handleCloseModal = useCallback(() => {
    setSelectedAttachmentId(null);
    setIsEditMode(false);
    setIsUploadingReqDoc(false);
    setEditingAttachment(null);
  }, []);

  const handleDownloadAttachment = useCallback(() => {
    if (selectedAttachment?.id) {
      triggerDownload({
        module: isCustomerOwnRequiredDocs ? 'document-required' : moduleName,
        moduleId: selectedAttachment.id,
        category: AttachmentCategory.Photo,
        fileName:
          selectedAttachment.originalName || selectedAttachment.fileName,
      });
    }
  }, [
    triggerDownload,
    moduleName,
    selectedAttachment?.id,
    selectedAttachment?.fileName,
    isCustomerOwnRequiredDocs,
  ]);

  const handleDownloadExampleAttachment = useCallback(
    (fileName: string, id: number) => {
      triggerDownload({
        module: 'document-required',
        moduleId: id,
        category: AttachmentCategory.Document,
        fileName: fileName,
      });
    },
    [triggerDownload, row?.moduleName],
  );

  const handleUploadReqDocAttachment = async (e, docType) => {
    const rawFile = e.target.files[0];

    e.target.value = null;
    const url = URL.createObjectURL(rawFile);
    if (docType && rawFile) {
      setEditingAttachment({
        processedFile: rawFile,
        rawFile,
        url,
        preview: url,
        name: rawFile.name,
        originalName: rawFile.name,
        attachmentType: rawFile.type.includes('image')
          ? AttachmentCategory.Photo
          : AttachmentCategory.Document,
        fileType: rawFile.type?.split('/')[1],
      });
      setIsEditMode(true);
      setIsUploadingReqDoc(true);
      setFieldsValues({
        ...fieldsValues,
        documentCoreType: docType,
      });
    }
  };

  const toggleEditMode = () => setIsEditMode(prevIsEdit => !prevIsEdit);

  const imagesMemo = useMemo(() => {
    return attachments?.others?.map(attachment => ({
      id: attachment.id,
      preview: attachment.url,
      type: attachment.category,
      name: attachment.fileName,
      description: attachment.description,
      uploadedBy: attachment?.createdByUserEmail,
      uploadDate: moment(attachment?.createdAt)?.format('MM/DD/YYYY'),
      documentType: attachment?.documentCoreType?.name,
      fileType: attachment.fileType,
      attachmentType: attachment.attachmentType,
      expiresOn: attachment.expiresOn,
      originalName: attachment.originalName,
      documentRequiredId: attachment.documentRequiredId,
      moduleId: attachment.moduleId,
    }));
  }, [attachments]);

  const fieldsValueDocTypeIsReqDocAndExpires = useMemo(() => {
    return requiredDocs?.some(
      rdoc =>
        rdoc.attachment?.expires &&
        (rdoc.documentCoreTypeId === fieldsValues?.documentCoreType ||
          rdoc.documentCoreTypeId === editingAttachment?.documentCoreType),
    );
  }, [fieldsValues, editingAttachment, requiredDocs]);

  const fields = useMemo(() => {
    const uniqueDocumentTypes = _.uniqBy(documentTypes, 'code');
    return [
      {
        fieldName: 'fileName',
        type: FieldTypes.Text,
        label: 'File Name',
        placeholder: 'File Name',
        variant: 'standard',
        required: false,
        visible: false,
        readOnly: !isEditMode,
        value: fieldsValues?.fileName,
        onChange: (output: ECEasyFormFieldType[], value: string) => {
          handleFieldChange('fileName')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'Uploaded by',
        type: FieldTypes.Text,
        label: 'Uploaded by',
        placeholder: 'Uploaded by',
        variant: 'standard',
        visible: !!selectedAttachmentId,
        required: false,
        readOnly: true,
        value: fieldsValues?.uploadedBy,
        maxLength: 200,
        overrideRowValue: true,
      },
      {
        fieldName: 'Upload Date',
        type: FieldTypes.Text,
        label: 'Upload Date',
        placeholder: 'Upload Date',
        variant: 'standard',
        visible: !!selectedAttachmentId,
        required: false,
        readOnly: true,
        value: fieldsValues?.uploadDate,
        maxLength: 200,
        overrideRowValue: true,
      },
      {
        fieldName: 'documentCoreType',
        type: !isEditMode ? FieldTypes.Text : FieldTypes.Select,
        label: 'Document Type',
        placeholder: 'Document Type',
        variant: 'standard',
        required: true,
        options: uniqueDocumentTypes?.map(type => ({
          id: type?.id,
          label: type?.name,
        })),
        optionValues: uniqueDocumentTypes?.map(type => type?.id),
        readOnly: !isEditMode,
        value:
          fieldsValues?.documentCoreType ?? editingAttachment?.documentCoreType,
        onChange: (output: ECEasyFormFieldType[], value) => {
          handleFieldChange('documentCoreType')(value);
        },
        overrideRowValue: true,
        disabled: isUploadingReqDoc,
      },
      {
        fieldName: 'expiresOn',
        type: FieldTypes.DatePicker,
        label: 'Expiration Date',
        placeholder: 'Expiration Date',
        variant: 'standard',
        required: isSpRequiredDocs && fieldsValueDocTypeIsReqDocAndExpires,
        readOnly: !isEditMode,
        // TODO: It should be visible only if document type is "Worker's Comp COI", "Certificate of Insurance (COI)" or "Permit"
        // See https://ecotrak.atlassian.net/browse/DEV-10551
        visible:
          !isCustomerRequiredDocs &&
          ((isSpRequiredDocs && fieldsValueDocTypeIsReqDocAndExpires) ||
            (isExpiresOnFieldVisible && !fieldsValueDocTypeIsReqDocAndExpires)),
        value: fieldsValues?.expiresOn ?? editingAttachment?.expiresOn,
        onChange: (output: ECEasyFormFieldType[], value) => {
          handleFieldChange('expiresOn')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'description',
        type: FieldTypes.MultiLineText,
        label: 'Description',
        placeholder: 'Description',
        variant: 'standard',
        visible: true,
        required: false,
        readOnly: !isEditMode,
        value: fieldsValues?.description,
        maxLength: 200,
        onChange: (output: ECEasyFormFieldType[], value: string) => {
          handleFieldChange('description')(value);
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'isRestricted',
        type: FieldTypes.Checkbox,
        options: ['Restricted Document'],
        disabled: !isEditMode,
        visible:
          isAssetDocumentTypeSelected &&
          !isCustomerRequiredDocs &&
          !isSpRequiredDocs,
        value: fieldsValues.isRestricted ? ['Restricted Document'] : [],
        onChange: (output, value) => {
          handleFieldChange('isRestricted')(
            value?.indexOf('Restricted Document') > -1,
          );
        },
        overrideRowValue: true,
      },
      {
        fieldName: 'isReqDocExpirationDate',
        type: FieldTypes.Checkbox,
        options: ['Require Doc Expiration Date'],
        disabled: !isEditMode,
        visible: isCustomerRequiredDocs && isExpiresOnFieldVisible,
        value: fieldsValues.expires ? ['Require Doc Expiration Date'] : [],
        onChange: (output, value) => {
          handleFieldChange('expires')(
            value?.indexOf('Require Doc Expiration Date') > -1,
          );
        },
        overrideRowValue: true,
      },
    ];
  }, [
    isEditMode,
    fieldsValues,
    editingAttachment,
    isUploadingReqDoc,
    isAssetDocumentTypeSelected,
    isCustomerRequiredDocs,
    isSpRequiredDocs,
    isExpiresOnFieldVisible,
    fieldsValueDocTypeIsReqDocAndExpires,
    documentTypes,
  ]);

  const formConfig = useMemo(() => {
    return {
      cols: 1,
      submitTitle: 'Save',
      variant: '',
      title: '',
      subtitle: '',
      name: 'Attachment',
    };
  }, []);
  return (
    <ECBox p={3}>
      <ECAttachmentDeleteAlert
        open={!!deletingAttachmentId}
        onDelete={() => handleDelete(deletingAttachmentId)}
        title={deleteAlertTitle!}
        text={deleteAlertText!}
        handleCloseModal={() => setDeletingAttachmentId(null)}
      />
      {(moduleName === 'serviceprovider' ||
        moduleName === 'customer' ||
        moduleName === 'spcustomer') && (
        <ECBox>
          {isSpRequiredDocs && moduleName !== 'serviceprovider' && (
            <ECBox mb={2}>
              <ECTypography variant="h6" fontWeight="450" mb={1}>
                Required Documents
              </ECTypography>
              <FormHelperText
                sx={{
                  fontSize: '14px',
                  color: 'text.primary',
                }}
              >
                {moduleData?.name || 'Customer'} requires the following
                documents. If you have any additional documents you would like
                to share, please upload below.
              </FormHelperText>
            </ECBox>
          )}
          {requiredDocs?.map(item => (
            <ECRequiredDocument
              attachmentInfo={item}
              handleDownloadExampleAttachment={handleDownloadExampleAttachment}
              isCustomer={isCustomer}
              handleUploadAttachment={handleUploadReqDocAttachment}
            />
          ))}
        </ECBox>
      )}
      <ECBox display="flex">
        <ECFilePicker
          label="Add attachments here"
          fieldName={fieldName}
          moduleName={moduleName}
          type={FilePickerTypes.Photo}
          onChange={handleChange}
          onDelete={handleDelete}
          isOnChangeError={isUploadAttachmentError || isDeleteAttachmentError}
          isOnChangeSuccess={
            isUploadAttachmentSuccess || isDeleteAttachmentSuccess
          }
          isDownloadEnabled
          isDeleteEnabled
          isDeletePreviewEnabled={false}
          images={imagesMemo}
          confirmationDeleteMessage={confirmationDeleteMessage}
          showDeleteAlert={showDeleteAlert}
          deleteAlertTitle={deleteAlertTitle}
          deleteAlertText={deleteAlertText}
          onAttachmentClick={id => () => setSelectedAttachmentId(id)}
          isCustomerOwnRequiredDocs={isCustomerOwnRequiredDocs}
          isReadOnly={isReadOnly}
          {...(!_.isNil(canAddAttachment) && { hasAddAttachmentsPermission })}
        />

        <ECModal
          isOpen={editingAttachment}
          onClose={handleCloseModal}
          sx={{
            maxWidth: '90vw',
            maxHeight: '90vh',
            width: '75vw',
            height: '90vh',
            overflow: 'hidden',
          }}
        >
          <ECBox display="flex" justifyContent="space-between" height="100%">
            <ECBox
              display="flex"
              sx={{
                width: 'calc(100% - 350px)',
                maxHeight: '100%',
                height: '100%',
              }}
              alignItems="center"
            >
              {editingAttachment &&
                editingAttachment?.attachmentType ===
                  AttachmentCategory.Document && (
                  <ECBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap={2}
                    sx={{
                      height: '100%',
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    {
                      // check if its PDF to show the PDF viewer
                      editingAttachment.fileName
                        ?.split('.')
                        ?.pop()
                        ?.toLowerCase() === 'pdf' ||
                      editingAttachment.originalName
                        ?.split('.')
                        ?.pop()
                        ?.toLowerCase() === 'pdf' ? (
                        <>
                          <ECTypography
                            textAlign="center"
                            variant="body1"
                            fontWeight="600"
                          >
                            {editingAttachment.originalName}
                          </ECTypography>
                          <PDFObject
                            url={editingAttachment?.url + '#toolbar=0'}
                            containerProps={{
                              style: {
                                width: '100%',
                                height: 'calc(100% - 40px)',
                              },
                            }}
                            pdfOpenParams={{
                              zoom: 'scale,left,top',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FileCopy
                            sx={theme => ({
                              color: theme.palette.action.active,
                            })}
                          />

                          <ECTypography textAlign="center" variant="body1">
                            {editingAttachment.originalName?.split('.')[0]}
                          </ECTypography>

                          <ECTypography textAlign="center" variant="body2">
                            {editingAttachment.originalName?.split('.')?.pop()}
                          </ECTypography>
                        </>
                      )
                    }
                  </ECBox>
                )}
              {editingAttachment &&
                editingAttachment?.attachmentType ===
                  AttachmentCategory.Photo && (
                  <ECImage
                    sx={{
                      maxHeight: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    alt="attachment"
                    src={editingAttachment?.url}
                  />
                )}
            </ECBox>

            <ECBox
              sx={{
                width: '313px',
                maxHeight: '90vh',
                height: '90vh',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <ECBox display="flex" justifyContent="flex-end" p={2} gap={1}>
                {selectedAttachmentId &&
                  selectedAttachment?.canEdit &&
                  !isReadOnly &&
                  !_.isNil(hasEditAttachmentsPermission) &&
                  hasEditAttachmentsPermission && (
                    <ECToggleButton
                      value="edit"
                      selected={isEditMode}
                      onChange={toggleEditMode}
                    >
                      <Edit />
                    </ECToggleButton>
                  )}
                {selectedAttachmentId && isEditMode && !isReadOnly && (
                  <ECIconButton
                    size="medium"
                    withBorder
                    onClick={() =>
                      setDeletingAttachmentId(
                        isCustomerOwnRequiredDocs
                          ? selectedAttachment?.moduleId
                          : selectedAttachment?.id,
                      )
                    }
                    type="error"
                  >
                    <Delete />
                  </ECIconButton>
                )}

                {!isEditMode && (
                  <ECIconButton
                    size="medium"
                    withBorder
                    onClick={handleDownloadAttachment}
                  >
                    <Download
                      sx={theme => ({ color: theme.palette.grey[600] })}
                    />
                  </ECIconButton>
                )}

                <ECIconButton
                  size="medium"
                  withBorder
                  onClick={handleCloseModal}
                >
                  <Close sx={theme => ({ color: theme.palette.grey[600] })} />
                </ECIconButton>
              </ECBox>

              <ECBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '4vh',
                }}
              >
                {showDeleteSwitch && selectedAttachmentId ? (
                  <ECBox sx={{ paddingLeft: '24px' }}>
                    <ECFormLabel>
                      <ECTypography variant="caption">State</ECTypography>
                    </ECFormLabel>
                    <ECFormGroup>
                      <ECFormControlLabel
                        value="end"
                        control={
                          <ECSwitch
                            id={'state'}
                            name={'state'}
                            checked
                            onChange={e => {
                              setDeletingAttachmentId(
                                isCustomerOwnRequiredDocs
                                  ? selectedAttachment?.moduleId
                                  : selectedAttachment?.id,
                              );
                            }}
                          />
                        }
                        label="Active"
                        labelPlacement="end"
                      />
                    </ECFormGroup>
                  </ECBox>
                ) : null}
                <ECBox>
                  <EditForm2
                    row={editingAttachment}
                    formConfig={formConfig}
                    formFields={fields}
                    detailsConfig={formConfig}
                    detailsFields={fields}
                    useUpdateMutation={
                      selectedAttachmentId
                        ? useUpdateAttachment
                        : useUploadAttachment
                    }
                    onClose={handleCloseModal}
                    stickyFooter={false}
                    hideToolBar
                    showEditButton={!!selectedAttachmentId}
                    onlyEdit={isEditMode}
                  />
                </ECBox>
              </ECBox>
            </ECBox>
          </ECBox>
        </ECModal>
      </ECBox>
    </ECBox>
  );
};

export const AttachmentTab =
  requirePermissionWrapper<AttachmentTabProps>(AttachmentTabContent);
