import { Method } from '../../../types/BaseType';
import _ from 'lodash';

export const processAssetBody = (bodyToRender, method) => {
  if (method === Method.PUT) {
    const customOrder = ['note'];
    const updatedBody = { ...bodyToRender };

    const reorderedObject = {};

    customOrder.forEach(key => {
      if (updatedBody.hasOwnProperty(key)) {
        reorderedObject[key] = updatedBody[key];
      }
    });

    Object.keys(updatedBody).forEach(key => {
      if (!reorderedObject.hasOwnProperty(key)) {
        reorderedObject[key] = updatedBody[key];
      }
    });

    return reorderedObject;
  }
  return bodyToRender;
};
