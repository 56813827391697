import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme, useMediaQuery } from '@mui/material';
import { ECBox, ECButton, ECSignUpWizardTemplate } from 'app/components';
import { ECStepsContainer } from 'app/components/ECSteps';
import { Step2 } from './step-2';
import {
  ArrowBack,
  ArrowBackIosOutlined,
  ArrowBackRounded,
} from '@mui/icons-material';

export function SignUpWizardPage() {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));
  const [currentStep, setCurrentStep] = useState(2);

  const nextStep = () => setCurrentStep(prev => prev + 1);
  const previousStep = () => {
    if (currentStep > 1) setCurrentStep(prev => prev - 1);
  };

  const renderTabs = useMemo(
    () => [
      {
        value: 1,
        label: '1',
        subLabel: 'Step 1',
        subLabelBold: true,
        content: <div>Step 1</div>,
      },
      {
        value: 2,
        label: '2',
        subLabel: 'Company Info',
        subLabelBold: true,
        content: <Step2 onSubmit={nextStep} />,
      },
      {
        value: 3,
        label: '3',
        subLabel: 'Step 3',
        subLabelBold: true,
        content: <div>Step 3</div>,
      },
      {
        value: 4,
        label: '4',
        subLabel: 'Step 4',
        subLabelBold: true,
        content: <div>Step 4</div>,
      },
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Sign In Page</title>
        <meta name="description" content="Welcome to Ecotrak" />
      </Helmet>
      <ECSignUpWizardTemplate>
        <ECStepsContainer
          showBackButton
          onClickBackButton={previousStep}
          stepsContainerProps={{
            paddingLeft: isMobileSize ? 0 : 18,
            paddingTop: isMobileSize ? 10 : 4,
            paddingRight: isMobileSize ? 4 : 33,
          }}
          tabs={renderTabs}
          currentStep={currentStep}
          onChangeStep={setCurrentStep}
        />
      </ECSignUpWizardTemplate>
    </>
  );
}
