import { useEffect, useRef, useState } from 'react';
import { ECTypography } from '../ECTypography';

export const ECAnimationText = ({ animationText, ...props }) => {
  const [text, setText] = useState<string>('');
  const isAnimating = useRef(false);
  const animateText = () => {
    if (isAnimating.current) return;
    isAnimating.current = true;
    setText('');
    let index = 0;
    const typingInterval = setInterval(() => {
      setText(animationText.substring(0, index));
      if (index === animationText.length - 1) {
        setInterval(() => clearInterval(typingInterval), 100);
        isAnimating.current = false;
      }
      index++;
    }, 50);
  };
  useEffect(() => {
    animateText();
  }, []);

  return <ECTypography {...props}>{text}</ECTypography>;
};
