import {
  AssetUpdateForm,
  AssetCreateForm,
  AssetCondition,
  AssetFlat,
  AssetDetails,
  AssetQueryParams,
  ServiceHistoryQueryParams,
  CopyAssetsToLocationParams,
  AssetInsightsResponse,
  AssetInsightsParams,
  AssetCircuitUpdate,
} from 'types/Asset';
import { BaseType, DataType } from 'types/BaseType';
import { emptyApi } from './emptyApi';
import daysPassedSince from 'utils/datediff';
import moment from 'moment';

const apiWithTag = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'Asset',
    'AssetList',
    'History',
    'AssetServiceHistory',
    'TotalSpent',
    'AssetOpenWorkOrders',
    'Locations Asset Avt',
    'Avt',
    'AssetCircuitDetails',
    'AssetCircuitsList',
  ],
});

export const assetApi = apiWithTag.injectEndpoints({
  endpoints: builder => ({
    getAssetsList: builder.query<BaseType<AssetFlat[]>, AssetQueryParams>({
      query: params => {
        const { o, ob, ...restParams } = params;

        const defaultParams = {
          ...restParams,
          o: o !== undefined && o !== '' ? o : 'a',
          ob: ob !== undefined && ob !== '' ? ob : 'asst.id',
        };

        return {
          url: 'asset',
          // dont invert the order because we want to override limitByUserBranch in case its sent
          params: { limitByUserBranch: true, ...defaultParams },
        };
      },
      transformResponse: (response: BaseType<AssetFlat[]>) => {
        const dataWithModuleName = response.data.map(row => {
          return {
            ...row,
            moduleName: 'asset',
            estimatedUsefulLifeText: row.estimatedUsefulLife
              ? `${row.estimatedUsefulLife} ${
                  row.eulPeriodCode === 'Y' ? 'Years' : 'Months'
                }`
              : '',
          };
        });

        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['AssetList'],
    }),
    getServiceRequestAssetsList: builder.query<
      BaseType<AssetFlat[]>,
      AssetQueryParams
    >({
      query: params => {
        if (!params.branchId) {
          return {
            url: '',
          };
        }
        return {
          url: 'asset/service-request',
          params,
        };
      },
      transformResponse: (response: BaseType<AssetFlat[]>) => {
        const dataWithModuleName = response.data.map(row => {
          return {
            ...row,
            moduleName: 'asset',
            estimatedUsefulLifeText: row.estimatedUsefulLife
              ? `${row.estimatedUsefulLife} ${
                  row.eulPeriodCode === 'Y' ? 'Years' : 'Months'
                }`
              : '',
            isGeneralAsset: row?.isGeneralAsset ? +row?.isGeneralAsset : 0,
          };
        });
        return {
          ...response,
          data: dataWithModuleName,
        };
      },
      providesTags: ['AssetList'],
    }),
    getAssetOpenWorkOrdersList: builder.query<
      any,
      { assetId: number; generalAssetId?: number; branchId: number }
    >({
      query: params => {
        const { assetId, generalAssetId, branchId } = params;
        return {
          url: `asset/${assetId}/open-work-orders/${generalAssetId ? 'general' : 'managed'}/branch/${branchId}`,
        };
      },
      transformResponse: (response: any) => {
        return response.map(row => {
          return {
            ...row,
            ageOfWorkOrder: daysPassedSince(row.createdAt),
            flags: row.flags?.map(flag => {
              return {
                ...flag,
                name: flag.label,
              };
            }),
          };
        });
      },
      providesTags: ['AssetOpenWorkOrders'],
    }),
    createAsset: builder.mutation<AssetDetails, AssetCreateForm>({
      query: body => {
        const {
          assetType,
          area,
          name,
          description,
          serialNumber,
          modelNumber,
          qrCode,
          installationDate,
          purchaseDate,
          branch,
          condition,
          manufacture,
          priority,
          nte,
          tagId,
          warrantyNte,
          originalValue,
          replacementCost,
          estimatedUsefulLife,
          eulPeriodCode,
          warrantyStartDateComponent,
          flags,
          files,
          defaultSpExpirationDate,
          spWarrantyCompany,
        } = body;

        const formData = new FormData();

        formData.append('assetTypeId', assetType.id.toString());
        formData.append('name', name);
        if (description) {
          formData.append('description', description);
        }
        if (area?.id && area.id > 0) {
          formData.append('areaId', area?.id?.toString() || '');
        }
        formData.append('branchId', branch?.id?.toString() || '');

        if (serialNumber) {
          formData.append('serialNumber', serialNumber);
        }
        formData.append('assetConditionId', condition.id.toString());
        if (modelNumber) {
          formData.append('modelNumber', modelNumber);
        }
        if (tagId) {
          formData.append('tagId', tagId);
        }
        if (defaultSpExpirationDate) {
          formData.append(
            'defaultSpExpirationDate',
            defaultSpExpirationDate?.toISOString?.() ?? defaultSpExpirationDate,
          );
        }
        if (spWarrantyCompany?.id && spWarrantyCompany.id !== -1) {
          formData.append(
            'defaultSpId',
            spWarrantyCompany?.id?.toString() || '',
          );
        }
        if (manufacture?.id && manufacture.id > 0) {
          formData.append('manufactureId', manufacture.id.toString());
        }
        if (qrCode) {
          formData.append('qrCode', qrCode?.toString());
        }
        if (installationDate) {
          formData.append(
            'installationDate',
            installationDate?.toISOString?.() ?? installationDate,
          );
        }
        formData.append('priorityId', priority.id.toString());
        if (purchaseDate) {
          formData.append(
            'purchaseDate',
            purchaseDate?.toISOString?.() ?? purchaseDate,
          );
        }
        formData.append('nte', nte.toString());
        formData.append('warrantyNte', warrantyNte.toString());
        if (originalValue) {
          formData.append('originalValue', originalValue?.toString());
        }
        if (replacementCost) {
          formData.append('replacementCost', replacementCost?.toString());
        }
        formData.append('estimatedUsefulLife', estimatedUsefulLife.toString());
        formData.append('eulPeriodCode', eulPeriodCode.toString());

        formData.append(
          'warrantyStartDate',
          warrantyStartDateComponent?.value?.toISOString?.() ??
            warrantyStartDateComponent?.value,
        );
        if (flags?.length) {
          formData.append('flagsIds', flags?.map(flag => flag.id).join(','));
        }
        formData.append(
          'warrantyStartOption',
          warrantyStartDateComponent?.fieldName,
        );

        const nonProcessedFiles: string[] = [];
        files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          } else {
            nonProcessedFiles.push(file.id);
          }
        });
        if (nonProcessedFiles.length > 0) {
          formData.append('attachmentsToCopyIds', nonProcessedFiles.join(', '));
        }
        if (
          assetType?.refrigerantTracking &&
          body?.refrigerantTracking?.refrigerantTrackingEnable
        ) {
          formData.append('refrigerantTrackingEnable', '1');
          formData.append(
            'refrigerantEPAId',
            body?.refrigerantTracking?.epaApplianceType?.id || '',
          );
          formData.append(
            'refrigerantInitalSpId',
            body?.refrigerantTracking?.initialChargeServiceProvider?.id || '',
          );
          if (body?.refrigerantTracking?.circuits?.length) {
            body?.refrigerantTracking?.circuits?.forEach(
              (circuit: any, index) => {
                formData.append(
                  `circuits[${index}][name]`,
                  circuit.circuitName || '',
                );
                formData.append(
                  `circuits[${index}][chargePounds]`,
                  circuit.chargelbs?.toString() || '',
                );
                formData.append(
                  `circuits[${index}][chargeOunces]`,
                  circuit.chargeoz?.toString() || '',
                );
                formData.append(
                  `circuits[${index}][refrigerantTypeId]`,
                  circuit.refrigerantType?.id?.toString() || '',
                );
                formData.append(
                  `circuits[${index}][refrigerantMethodId]`,
                  circuit.method?.id?.toString() || '',
                );
              },
            );
          }
        }

        return {
          url: 'asset',
          method: 'post',
          body: formData,
        };
      },
      transformResponse: (response: AssetDetails) => {
        return {
          ...response,
          moduleName: 'asset',
        };
      },
      invalidatesTags: ['AssetList', 'History', 'TotalSpent'],
    }),
    updateAsset: builder.mutation<void, AssetUpdateForm>({
      query: asset => {
        const {
          id,
          assetType,
          area,
          modelNumber,
          installationDate,
          purchaseDate,
          branch,
          condition,
          manufacture,
          priority,
          warrantyStartDateComponent,
          flags,
          defaultSpExpirationDate,
          spWarrantyCompany,
          qrCode,
        } = asset;
        delete asset['netBookValue'];
        delete asset['ageOfAsset'];
        delete asset['estimatedReplacementDate'];
        delete asset['storeOpenDate'];

        const transformedData = {
          ...asset,
          assetTypeId: assetType.id,
          areaId: area?.id ?? null,
          branchId: branch?.id,
          assetConditionId: condition.id,
          qrCode: qrCode ? qrCode : null,
          modelNumber: modelNumber,
          manufactureId: manufacture?.id === -1 ? null : manufacture?.id,
          defaultSpExpirationDate:
            defaultSpExpirationDate?.toISOString?.() ?? defaultSpExpirationDate,
          defaultSpId: spWarrantyCompany?.id || null,
          installationDate: installationDate,
          priorityId: priority.id,
          purchaseDate,
          warrantyStartDate:
            (warrantyStartDateComponent?.value &&
              moment(warrantyStartDateComponent?.value)?.format(
                'YYYY-MM-DD',
              )) ||
            null,
          warrantyStartOption: warrantyStartDateComponent?.fieldName,
        };

        for (const key in transformedData) {
          if (
            typeof transformedData[key] === 'object' &&
            transformedData[key] !== null
          ) {
            delete transformedData[key];
          }
        }
        transformedData['flagsIds'] = flags?.map(flag => flag.id);

        return {
          url: `asset/${id}`,
          method: 'put',
          body: transformedData,
        };
      },
      invalidatesTags: ['Asset', 'History'],
    }),
    replaceAsset: builder.mutation<AssetDetails, AssetUpdateForm>({
      query: asset => {
        const {
          id,
          assetType,
          areaId,
          name,
          description,
          serialNumber,
          modelNumber,
          qrCode,
          installationDate,
          purchaseDate,
          branch,
          condition,
          manufacture,
          priority,
          nte,
          tagId,
          warrantyNte,
          originalValue,
          replacementCost,
          estimatedUsefulLife,
          eulPeriodCode,
          warrantyStartDateComponent,
          flags,
          files,
          defaultSpExpirationDate,
          spWarrantyCompany,
          migrateQRCode,
        } = asset;

        const formData = new FormData();
        formData.append('assetTypeId', assetType.id.toString());
        formData.append('name', name);

        if (migrateQRCode) formData.append('migrateQRCode', '1');

        if (description) {
          formData.append('description', description);
        }
        if (areaId && areaId > 0) {
          formData.append('areaId', areaId.toString());
        }

        formData.append('branchId', branch?.id?.toString() || '');
        if (serialNumber) {
          formData.append('serialNumber', serialNumber);
        }
        formData.append('assetConditionId', condition.id.toString());
        if (modelNumber) {
          formData.append('modelNumber', modelNumber);
        }
        if (tagId) {
          formData.append('tagId', tagId);
        }
        if (defaultSpExpirationDate) {
          formData.append(
            'defaultSpExpirationDate',
            defaultSpExpirationDate?.toISOString?.() ?? defaultSpExpirationDate,
          );
        }
        if (spWarrantyCompany?.id && spWarrantyCompany?.id !== -1) {
          formData.append(
            'defaultSpId',
            spWarrantyCompany?.id?.toString() || '',
          );
        }
        if (manufacture?.id && manufacture.id > 0) {
          formData.append('manufactureId', manufacture.id.toString());
        }
        if (qrCode) {
          formData.append('qrCode', qrCode?.toString());
        }
        formData.append(
          'installationDate',
          installationDate?.toISOString?.() ?? installationDate,
        );
        formData.append('priorityId', priority.id.toString());
        formData.append(
          'purchaseDate',
          purchaseDate?.toISOString?.() ?? purchaseDate,
        );
        formData.append('nte', nte.toString());
        formData.append('warrantyNte', warrantyNte.toString());
        if (originalValue) {
          formData.append('originalValue', originalValue?.toString());
        }
        if (replacementCost) {
          formData.append('replacementCost', replacementCost?.toString());
        }
        formData.append('estimatedUsefulLife', estimatedUsefulLife.toString());
        formData.append('eulPeriodCode', eulPeriodCode.toString());

        formData.append(
          'warrantyStartDate',
          warrantyStartDateComponent?.value?.toISOString?.() ??
            warrantyStartDateComponent?.value,
        );
        if (flags?.length) {
          formData.append('flagsIds', flags?.map(flag => flag.id).join(','));
        }
        formData.append(
          'warrantyStartOption',
          warrantyStartDateComponent?.fieldName,
        );
        const nonProcessedFiles: string[] = [];
        files?.forEach(file => {
          if (file.processedFile) {
            formData.append('files', file.processedFile);
          } else {
            nonProcessedFiles.push(file.id);
          }
        });
        if (nonProcessedFiles.length > 0) {
          formData.append('attachmentsToCopyIds', nonProcessedFiles.join(', '));
        }

        return {
          url: `asset/${id}/replace`,
          method: 'put',
          body: formData,
        };
      },
      transformResponse: (response: AssetDetails) => {
        return {
          ...response,
          moduleName: 'asset',
        };
      },
    }),
    getAssetById: builder.query<AssetDetails & DataType, AssetFlat | number>({
      query: data => {
        const id = typeof data === 'number' ? data : data?.id;
        return {
          url: `asset/${id}`,
        };
      },
      transformResponse: (response: AssetDetails) => {
        return {
          ...response,
          moduleName: 'asset',
          estimatedUsefulLifeText: response.estimatedUsefulLife
            ? `${response.estimatedUsefulLife} ${
                response.eulPeriodCode === 'Y' ? 'Years' : 'Months'
              }`
            : '',
        };
      },
      providesTags: ['Asset'],
    }),
    getAssetConditionList: builder.query<AssetCondition[], void>({
      query: () => ({
        url: 'lookup/assetcondition',
      }),
    }),
    getAssetsByAreaId: builder.query<BaseType<AssetFlat[]>, AssetQueryParams>({
      query: ({ areaId, ...params }) => {
        return {
          url: `area/${areaId}/assets`,
          params,
        };
      },
    }),
    getAssetsByAreaIdAndBranchId: builder.query<
      BaseType<AssetFlat[]>,
      AssetQueryParams
    >({
      query: ({ areaId, branchId, ...params }) => {
        return {
          url: `branch/${branchId}/${areaId}/assets`,
          params,
        };
      },
    }),
    getAssetWarranty: builder.query<any, number | null>({
      query: id => {
        if (!id) {
          return {
            url: '',
          };
        }
        return {
          url: `asset/warranty/tags?ids=${id}`,
        };
      },
    }),

    getAssetServiceHistoryList: builder.query<
      BaseType<AssetFlat[]>,
      ServiceHistoryQueryParams
    >({
      query: ({ id, ...params }) => {
        return {
          url: `asset/${id}/service-history`,
          params: params?.queryParams,
        };
      },
      providesTags: ['AssetServiceHistory'],
    }),
    getTotalSpent: builder.query<any, number[]>({
      query: ids => {
        return {
          url: `asset/total-spent`,
          params: { ids },
        };
      },
      providesTags: ['TotalSpent'],
    }),
    copyAssetsToLocation: builder.mutation<void, CopyAssetsToLocationParams>({
      query: body => {
        return {
          url: 'asset/copy',
          method: 'post',
          body,
        };
      },
    }),
    getAssetsByBranchIdAndCustomerIdForSP: builder.query<
      any,
      { branchId: number; customerId: number }
    >({
      query: params => {
        return {
          url: 'sp/asset',
          params,
        };
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(row => ({
            ...row,
            isGeneralAsset: row.isGeneralAsset === '1',
          })),
        };
      },
    }),
    getAndCalculateAssetInsights: builder.query<
      AssetInsightsResponse,
      { id: number; params?: AssetInsightsParams }
    >({
      query: ({ id, params }) => {
        let url = `asset/${id}/insights`;
        if (params) {
          const queryString = Object.entries(params)
            .filter(([key, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          if (queryString) {
            url += `?${queryString}`;
          }
        }
        return url;
      },
    }),
    getAssetCircuitDetails: builder.query<any, { id: number; params?: any }>({
      query: ({ id }) => {
        return {
          url: `asset/${id}/circuit`,
        };
      },
      providesTags: ['AssetCircuitDetails'],
    }),
    getAssetCircuitsList: builder.query<any, { id: number; params?: any }>({
      query: ({ id }) => {
        return {
          url: `asset/${id}/circuits`,
        };
      },
      providesTags: ['AssetCircuitsList'],
    }),
    updateAssetCircuits: builder.mutation<any, AssetCircuitUpdate>({
      query: body => {
        return {
          url: `asset/${body.assetId}/circuit`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['AssetCircuitsList', 'AssetCircuitDetails'],
    }),
  }),
});

export const {
  useGetAssetsListQuery,
  useLazyGetAssetsListQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useGetAssetConditionListQuery,
  useGetAssetByIdQuery,
  useLazyGetAssetByIdQuery,
  useGetAssetsByAreaIdQuery,
  useLazyGetAssetsByAreaIdQuery,
  useGetAssetsByAreaIdAndBranchIdQuery,
  useLazyGetAssetsByAreaIdAndBranchIdQuery,
  useGetAssetWarrantyQuery,
  useLazyGetAssetServiceHistoryListQuery,
  useReplaceAssetMutation,
  useLazyGetTotalSpentQuery,
  useGetServiceRequestAssetsListQuery,
  useLazyGetServiceRequestAssetsListQuery,
  useGetAssetOpenWorkOrdersListQuery,
  useLazyGetAssetOpenWorkOrdersListQuery,
  useCopyAssetsToLocationMutation,
  useGetAssetsByBranchIdAndCustomerIdForSPQuery,
  useLazyGetAssetsByBranchIdAndCustomerIdForSPQuery,
  useGetAndCalculateAssetInsightsQuery,
  useLazyGetAndCalculateAssetInsightsQuery,
  useGetAssetCircuitDetailsQuery,
  useLazyGetAssetCircuitDetailsQuery,
  useGetAssetCircuitsListQuery,
  useLazyGetAssetCircuitsListQuery,
  useUpdateAssetCircuitsMutation,
} = assetApi;
