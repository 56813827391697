import { ECBox } from 'app/components';
import { ECCircularProgress } from 'app/components/ECCircularProgress';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { useOutletContext, useParams } from 'react-router';
import {
  useGetBranchByIdQuery,
  useUpdateBranchMutation,
} from 'services/branchApi';

const formElementsOfLeaseDetails = require('./fancy_form_config_lease_details.json');
const formElementsOfLeaseEdit = require('./fancy_form_config_lease_edit.json');

export function LeasePage() {
  const props: any = useOutletContext();
  const { id } = useParams();

  const isLeaseTab =
    !window.location.href.includes('attachments') &&
    !window.location.href.includes('activity') &&
    !window.location.href.includes('details');

  const { data: branch } = useGetBranchByIdQuery(
    { id: Number(id) },
    { skip: !id || !isLeaseTab },
  );

  const [doUpdate, { data, isError, error, isSuccess, reset, isLoading }] =
    useUpdateBranchMutation();

  const updateLocationLease = useCallback(() => {
    const doUpdateBranch = async data => {
      if (
        data?.leaseOption1PeriodCode === -1 ||
        _.isNil(data?.leaseOption1PeriodCode) ||
        data?.leaseOption1Value === '0' ||
        data?.leaseOption1Value === ''
      ) {
        data.leaseOption1PeriodCode = null;
        data.leaseOption1Value = null;
      }
      if (
        data?.leaseOption2PeriodCode === -1 ||
        _.isNil(data?.leaseOption2PeriodCode) ||
        data?.leaseOption2Value === '0' ||
        data?.leaseOption2Value === ''
      ) {
        data.leaseOption2PeriodCode = null;
        data.leaseOption2Value = null;
      }
      doUpdate?.(data);
    };
    return [
      doUpdateBranch,
      { data, isError, error, isSuccess, reset, isLoading },
    ];
  }, [data, isError, error, isSuccess, reset, isLoading, doUpdate]);
  return branch ? (
    <EditForm2
      row={branch}
      showTitle={false}
      formConfig={formElementsOfLeaseEdit.config}
      formFields={formElementsOfLeaseEdit.fields}
      detailsConfig={formElementsOfLeaseDetails.config}
      detailsFields={formElementsOfLeaseDetails.fields}
      useUpdateMutation={updateLocationLease}
      hideToolBar
      checkEqualFields={false}
      showEditButton={props?.onlyEdit}
      onlyEdit={props?.onlyEdit}
      onClose={() => {
        props?.onClose();
      }}
      {...props}
    />
  ) : (
    <ECBox display="flex" justifyContent="center">
      <ECCircularProgress size={50} sx={{ marginTop: '100px' }} />
    </ECBox>
  );
}
