import { ECAdvancedFilter } from './ECAdvancedFilter';
import { ECSimpleSearch } from './ECSimpleSearch';

interface ECTableSearchFiltersProps {
  cols?: any[];
  hiddenCols?: any[];
  showSaveFilterButton?: boolean;
  allSavedFilters?: any[];
  isAdvancedFilter?: boolean;
  isSendingRequest?: boolean;
  hideSaveFilterButton?: boolean;
}

export const ECTableSearchFilters = ({
  cols,
  showSaveFilterButton,
  allSavedFilters,
  hiddenCols,
  isAdvancedFilter,
  isSendingRequest,
  hideSaveFilterButton,
}: ECTableSearchFiltersProps) => {
  return (
    <>
      {isAdvancedFilter ? (
        <ECAdvancedFilter
          cols={cols}
          showSaveFilterButton={showSaveFilterButton}
          allSavedFilters={allSavedFilters}
          hiddenCols={hiddenCols}
          isSendingRequest={isSendingRequest}
          hideSaveFilterButton={hideSaveFilterButton}
        />
      ) : (
        <ECSimpleSearch
          cols={cols}
          hiddenCols={hiddenCols}
          isSendingRequest={isSendingRequest}
        />
      )}
    </>
  );
};
