import { useTranslation } from 'react-i18next';
import { ECBox } from '../ECBox';
import { ECButton } from '../ECButton';
import { ECCardActionArea, ECCardActions, ECCardContent } from '../ECCard';
import { ECDivider } from '../ECDivider';
import { ECPaper } from '../ECPaper';
import { ECTypography } from '../ECTypography';
import { ECCircularProgress } from '../ECCircularProgress';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface ECSPCardProps {
  id: number;
  name?: string;
  customersCount?: number;
  workOrdersCount?: number;
  workOrdersCompletedInArea?: number;
  spEmail?: string;
  isSelected?: boolean;
  onCardClick: (id: number) => () => void;
  onInviteClick: (data: {
    spEmail: string;
    spName: string;
    spId: number;
  }) => void;
  isLoadingInvite?: boolean;
  tradesSummary?: string;
  selfPerform?: number;
}

export const ECSPCard = ({
  id,
  name,
  customersCount,
  workOrdersCount,
  workOrdersCompletedInArea,
  isSelected,
  spEmail,
  onCardClick,
  onInviteClick,
  isLoadingInvite,
  tradesSummary,
  selfPerform,
}: ECSPCardProps) => {
  const { t } = useTranslation();

  const SPCardItem = (name: string, value: string, icon?: any, sx?: object) => (
    <ECBox display="flex" mt={1}>
      {!!icon && icon}
      <ECTypography variant="body1" fontWeight={200}>
        {name}
      </ECTypography>
      <ECTypography variant="body1" sx={{ ...sx }}>
        &nbsp;{value}
      </ECTypography>
    </ECBox>
  );

  return (
    <ECPaper
      key={id}
      sx={theme => ({
        bgcolor: theme.palette.common.white,
        border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.other.outlineBorder}`,
      })}
    >
      <ECCardActionArea onClick={onCardClick(id)} sx={{ px: 2 }}>
        <ECCardContent>
          <ECBox display="flex" flexDirection="column">
            <ECTypography variant="h5" mb={2}>
              {name}
            </ECTypography>

            {SPCardItem(
              t('translation:spDirectory.results.trades'),
              tradesSummary || '',
              undefined,
              {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
              },
            )}

            {SPCardItem(
              t('translation:spDirectory.results.customers'),
              customersCount?.toString() || '',
            )}
            {SPCardItem(
              t('translation:spDirectory.results.workOrdersCompleted'),
              workOrdersCount?.toString() || '',
            )}
            {SPCardItem(
              t('translation:spDirectory.results.completedInSearchedArea'),
              workOrdersCompletedInArea?.toString() || '',
            )}

            <ECDivider sx={{ mt: 1 }} />
          </ECBox>
        </ECCardContent>
      </ECCardActionArea>

      <ECCardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ECBox width="50%" display="flex" px={2}>
          <ECButton
            fullWidth
            variant="outlined"
            onClick={() =>
              onInviteClick({
                spEmail: spEmail || '',
                spName: name || '',
                spId: id,
              })
            }
            disabled={isLoadingInvite}
          >
            {isLoadingInvite ? (
              <ECCircularProgress size={'1rem'} />
            ) : (
              t('translation:spDirectory.results.invite')
            )}
          </ECButton>
        </ECBox>

        <ECBox ml={1} display="flex" alignItems="center">
          <ECTypography variant="body1" mr={2}>
            {t('translation:spDirectory.results.selfPerforming')}
          </ECTypography>
          {selfPerform === 1 ? (
            <CheckCircle color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </ECBox>
      </ECCardActions>
    </ECPaper>
  );
};
