import { ECAlert, ECBox, ECImage } from 'app/components';
import appStoreButton from './assets/app-store.svg';
import googlePlayButton from './assets/google-play.svg';
import { Link } from '@mui/material';

interface ECMobileDownloadButtonsProps {
  showAlert?: boolean;
}

export const ECMobileDownloadButtons = ({
  showAlert,
}: ECMobileDownloadButtonsProps) => {
  return (
    <ECAlert
      icon={false}
      severity="info"
      sx={{
        color: 'black',
        fontSize: '15px',
        backgroundColor: showAlert ? undefined : 'transparent',
      }}
    >
      To view your New Work Order and continue using Ecotrak, please download
      the Ecotrak app or log into your account on our website at ecotrak.com
      from a computer.
      <ECBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop={showAlert ? '20px' : undefined}
      >
        <Link href="https://apps.apple.com/us/app/ecotrak/id6450703616" mr={4}>
          <img src={appStoreButton} alt="AppStore Download" />
        </Link>
        <Link href="https://play.google.com/store/apps/details?id=com.ecotrak.etm2&hl=en_US">
          <img src={googlePlayButton} alt="GooglePlay Download" />
        </Link>
      </ECBox>
    </ECAlert>
  );
};
