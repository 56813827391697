import { ECAlert, ECBox, ECGrid, ECPaper, ECTypography } from 'app/components';
import { ECEasyFormCreate } from 'app/components/ECDynamicForm';
import { ECEasyFormFieldType } from 'app/components/ECForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { themes } from 'styles/theme/themes';
import _ from 'lodash';
import {
  useAdjustWOAllocatedInventoryItemsMutation,
  useGetInventoryItemsListQuery,
} from 'services/inventoryApi';
import { InventoryItemShape } from 'types/Inventory';
import { currencyFormatter } from 'utils/strings/currency-formatter';

const fancyFormElements = require('./fancy_form_config_allocate_inventory.json');

interface AllocatedInventoryItemsProps {
  isOpen: boolean;
  onClose: () => void;
  existingData?: any;
  allocatedInventoryItems?: { allocatedItems: InventoryItemShape[] };
}

export const AdjustAllocatedInventoryItems = ({
  isOpen,
  existingData,
  onClose,
  allocatedInventoryItems,
}: AllocatedInventoryItemsProps) => {
  const [formFields, setFormFields] = useState<ECEasyFormFieldType[]>(
    fancyFormElements.fields,
  );
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [totalCost, setTotalCost] = useState(0);
  const [exceeded, setExceeded] = useState<boolean | 0>(false);

  const {
    data: dataInventoryItems,
    isLoading: isLoadingInventoryItems,
    isSuccess: isSuccessInventoryItems,
  } = useGetInventoryItemsListQuery({ st: 1, t: 10000 }, { skip: !isOpen });

  const inventoryItemsListOptions: any[] = useMemo(() => {
    if (isSuccessInventoryItems && dataInventoryItems && isOpen) {
      return dataInventoryItems.data
        ?.filter(
          inventoryItem => inventoryItem?.inventoryItemStorage?.length > 0,
        )
        ?.map(inventoryItem => {
          const newStorageLocations = allocatedInventoryItems?.allocatedItems
            ?.length
            ? inventoryItem.inventoryItemStorage.map(storage => {
                const allocatedStorage =
                  allocatedInventoryItems?.allocatedItems.find(
                    i =>
                      i.inventoryStorageId === storage.inventoryStorageId &&
                      i.inventoryId === inventoryItem.id,
                  );
                if (allocatedStorage) {
                  return {
                    ...storage,
                    count: allocatedStorage.count,
                  };
                }
                return storage;
              })
            : inventoryItem.inventoryItemStorage;
          const inventoryItemTotalQuantityOnHand =
            inventoryItem.inventoryItemStorage.reduce(
              (acc, storage) => acc + storage.count,
              0,
            ) ?? 0;
          return {
            id: inventoryItem.id,
            label: inventoryItem.name,
            subLabel: `Quantity on Hand: ${inventoryItemTotalQuantityOnHand}`,
            count: inventoryItemTotalQuantityOnHand,
            subLabelColor:
              inventoryItemTotalQuantityOnHand === 0 ? '#d50000' : '#000000',
            name: inventoryItem.name,
            storageLocations: newStorageLocations,
          };
        });
    }
    return [];
  }, [isSuccessInventoryItems, dataInventoryItems, isOpen]);

  const parseAllocatedInventoryItems = useCallback(() => {
    if (!allocatedInventoryItems) return [];

    return allocatedInventoryItems?.allocatedItems?.reduce((acc: any, item) => {
      let doesInventoryItemExist = false;
      const inventoryItemFromList = inventoryItemsListOptions.find(
        inventoryItem => inventoryItem.id === item.inventoryId,
      );

      const storageFromList =
        inventoryItemFromList &&
        inventoryItemFromList.storageLocations.find(
          storage => storage.inventoryStorageId === item.inventoryStorageId,
        );
      const storageData = {
        id: item.inventoryStorageId,
        ...(storageFromList && storageFromList),
        avgPrice: item.price,
        inventoryStorage: { name: item.storageLocation },
        inventoryStorageId: item.inventoryStorageId,
        measurementUnit: { symbol: item.unitSymbol },
      };

      acc = acc.map(i => {
        if (i.id === item.inventoryId) {
          doesInventoryItemExist = true;
          return {
            ...i,
            selectedLocations: i.selectedLocations.concat({
              ...storageData,
              quantity: item.count,
              measurementUnit: { symbol: item.unitSymbol },
            }),
          };
        }

        return i;
      });
      if (!doesInventoryItemExist)
        acc.push({
          id: item.inventoryId,
          name: item.name,
          label: item.name,
          selectedLocations: [
            {
              ...storageData,
              quantity: item.count,
              measurementUnit: { symbol: item.unitSymbol },
            },
          ],
          storageLocations: inventoryItemFromList?.storageLocations ?? [],
          showEmptyRow: false,
        });

      return acc;
    }, []);
  }, [allocatedInventoryItems, inventoryItemsListOptions]);

  useEffect(() => {
    if (dataInventoryItems && isSuccessInventoryItems && isOpen) {
      const updatedFormFields = [...formFields];
      const inventoryItemsField = updatedFormFields.find(
        field => field.fieldName === 'inventoryItems',
      );

      if (inventoryItemsField && allocatedInventoryItems) {
        inventoryItemsField.required = false;
        inventoryItemsField.allocatedInventoryItems =
          parseAllocatedInventoryItems();
      }
      setFormFields(updatedFormFields);
    }
  }, [
    isSuccessInventoryItems,
    dataInventoryItems,
    inventoryItemsListOptions,
    existingData,
    allocatedInventoryItems,
    isOpen,
  ]);

  const [
    doAdjustAllocatedInventoryItem,
    {
      data: adjustAllocatedInventoryData,
      isError: isAdjustAllocatedItemsError,
      error: adjustAllocatedItemError,
      isLoading: isAdjustAllocatedLoading,
      isSuccess: isAdjustAllocatedSuccess,
      reset: resetAdjustAllocatedInventory,
    },
  ] = useAdjustWOAllocatedInventoryItemsMutation();

  const useAdjustAllocatedItemsMutation = useCallback(() => {
    const doAdjustItems = async data => {
      delete data.undefined;
      const transformedData: any[] =
        data?.inventoryItems?.flatMap(item =>
          item.selectedLocations.map(location => ({
            inventoryItemId: item?.id,
            inventoryStorageId: location.inventoryStorageId,
            quantity: parseFloat(location.quantity),
            price: parseFloat(location.avgPrice),
          })),
        ) || [];
      doAdjustAllocatedInventoryItem({
        workOrderId: existingData?.workOrderId,
        allocateInventory: transformedData,
      });
    };

    return [
      doAdjustItems,
      {
        data: adjustAllocatedInventoryData,
        isError: isAdjustAllocatedItemsError,
        error: adjustAllocatedItemError,
        isLoading: isAdjustAllocatedLoading,
        isSuccess: isAdjustAllocatedSuccess,
      },
    ];
  }, [
    adjustAllocatedInventoryData,
    isAdjustAllocatedItemsError,
    adjustAllocatedItemError,
    isAdjustAllocatedLoading,
    isAdjustAllocatedSuccess,
  ]);

  useEffect(() => {
    if (!isOpen && fancyFormElements) {
      setFormFields(
        fancyFormElements.fields.map(field => ({
          ...field,
          value: null,
        })),
      );
      resetAdjustAllocatedInventory();
    }
  }, [isOpen]);

  const totalLabelInfo = useMemo(() => {
    if (isOpen) {
      return `(Subtotal for Repair: ${currencyFormatter.format(existingData?.invoiceTotal || 0)} + Subtotal for Inventory:  ${currencyFormatter.format(totalCost - existingData?.invoiceTotal || 0)})`;
    }
  }, [totalCost, existingData?.invoiceTotal, isOpen]);

  const nteSaveAlertObject = useMemo(
    () => (
      <ECGrid container alignItems="center">
        <ECGrid
          item
          xs={12}
          p={2}
          bgcolor={theme => theme.palette.background.default}
        >
          <ECBox
            display="flex"
            flexDirection="column"
            borderRadius={2}
            border={1}
            overflow="hidden"
            borderColor={theme =>
              exceeded
                ? theme.palette.error.outlinedRestingBackground
                : theme.palette.divider
            }
          >
            <ECBox
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
              borderBottom={1}
              borderColor={theme =>
                exceeded
                  ? theme.palette.error.outlinedRestingBackground
                  : theme.palette.divider
              }
            >
              <ECBox display="flex" alignItems="center" columnGap={0.5}>
                <ECTypography
                  color={theme => theme.palette.text.secondary}
                  variant="h6"
                >
                  {exceeded
                    ? 'Total must be less than or equal to NTE'
                    : 'Total'}
                </ECTypography>
                {!exceeded && (
                  <ECTypography
                    color={theme => theme.palette.text.secondary}
                    variant="body1"
                  >
                    {totalLabelInfo}
                  </ECTypography>
                )}
              </ECBox>
              <ECTypography
                color={theme => theme.palette.text.secondary}
                variant="h4"
                id="ec-costtable-total"
              >
                {currencyFormatter.format(totalCost || 0)}
              </ECTypography>
            </ECBox>
            <ECBox
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
              bgcolor={theme =>
                exceeded
                  ? theme.palette.error.outlinedRestingBackground
                  : theme.palette.action.selected
              }
            >
              <ECTypography
                color={theme => theme.palette.text.secondary}
                variant="body2"
              >
                NTE
              </ECTypography>

              <ECTypography
                variant="body2"
                color={theme => theme.palette.text.secondary}
              >
                {currencyFormatter.format(existingData?.nte || 0)}
              </ECTypography>
            </ECBox>
          </ECBox>
        </ECGrid>
        {exceeded && (
          <ECGrid
            item
            xs={12}
            bgcolor={theme => theme.palette.background.default}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <ECAlert
              severity="error"
              variant="outlined"
              sx={{ width: '90%', margin: 1, marginTop: -1 }}
            >
              The Allocation can not be saved because it is above the NTE
            </ECAlert>
          </ECGrid>
        )}
      </ECGrid>
    ),
    [totalCost, existingData?.nte, exceeded],
  );

  const handleOutputChange = (
    output: ECEasyFormFieldType[],
    fieldName?: string,
  ) => {
    if (fieldName === 'inventoryItems') {
      const inventoryItemField = output.find(
        field => field.fieldName === 'inventoryItems',
      );
      const totalCostofItems =
        inventoryItemField?.value?.reduce((total, item) => {
          if (item.selectedLocations) {
            return (
              total +
              item.selectedLocations.reduce((subtotal, location) => {
                return subtotal + location.quantity * Number(location.avgPrice);
              }, 0)
            );
          }
          return total;
        }, 0) + existingData?.invoiceTotal || 0;
      const isExceed = existingData?.nte && existingData.nte < totalCostofItems;
      setDisableSaveButton(isExceed);
      setTotalCost(totalCostofItems);
      setExceeded(isExceed ?? false);
    }
  };

  return (
    <ECDrawerDetails open={isOpen} anchor="right" onClose={onClose}>
      <ECPaper sx={{ height: '100%', paddingTop: '80px' }} role="presentation">
        <ECEasyFormCreate
          useCreateMutation={useAdjustAllocatedItemsMutation}
          formConfig={fancyFormElements.config}
          formFields={formFields}
          onClose={onClose}
          isLoading={isLoadingInventoryItems}
          drawerTitleBarBGColor={themes.light.palette.other.divider}
          saveButtonDisabled={disableSaveButton}
          nteSaveAlertObject={nteSaveAlertObject}
          onChange={handleOutputChange}
          noTopMargin
        />
      </ECPaper>
    </ECDrawerDetails>
  );
};
