import { useCallback, useEffect, useState } from 'react';
import {
  ECBox,
  ECButton,
  ECEasyFormCreate,
  ECEasyFormFieldType,
} from 'app/components';
import moment from 'moment';
import _ from 'lodash';
import { useMediaQuery, useTheme } from '@mui/material';

interface Step2Props {
  existingData?: any;
  onClose?: () => void;
  onSubmit?: (data: any) => void;
}

const fancyFormElementsCreate = require('./fancy_form_config_step2_create.json');

export const Step2 = ({ existingData, onClose, onSubmit }: Step2Props) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const handleExistingData = useCallback(
    (field: ECEasyFormFieldType) => {
      if (!field) return;

      if (field.type === 'group') {
        return {
          ...field,
          subFields: field?.subFields?.map(handleExistingData),
        };
      }
      let fieldValue = _.get(existingData, field.fieldName);
      if (
        field.fieldName === 'leaseOption1PeriodCode' ||
        field.fieldName === 'leaseOption2PeriodCode'
      ) {
        fieldValue = fieldValue?.id;
      }
      return {
        ...field,
        value: fieldValue || '',
      };
    },
    [existingData],
  );

  const [formFields, setFormFields] = useState<any[]>(
    () => fancyFormElementsCreate.fields?.map(handleExistingData) || [],
  );

  useEffect(() => {
    setFormFields(
      fancyFormElementsCreate.fields?.map(handleExistingData) || [],
    );
  }, [existingData, handleExistingData]);

  const submit = useCallback(() => {
    const doSubmit = async data => {
      onSubmit?.({ ...existingData, ...data });
    };
    return [doSubmit, {}];
  }, [onSubmit]);

  return (
    <ECBox
      paddingInline={isMobileSize ? 0 : `15%`}
      alignItems={'center'}
      justifyContent={'center'}
      display={'flex'}
      flexDirection={'column'}
    >
      <ECEasyFormCreate
        useCreateMutation={submit}
        onClose={onClose}
        formConfig={fancyFormElementsCreate.config}
        formFields={formFields}
        existingData={existingData}
        showSaveButton={false}
      />
      <ECButton
        variant="contained"
        sx={{ width: '400px', marginTop: isMobileSize ? '0' : '40px' }}
      >
        Next
      </ECButton>
    </ECBox>
  );
};
