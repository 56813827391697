const flatAllFormFields = (
  fields,
  path: string[] = [],
): { key: string; field: any }[] => {
  let allFields: any = [];
  for (const field of fields) {
    if (field.subFields) {
      allFields = [
        ...allFields,
        ...flatAllFormFields(
          field.subFields,
          field.groupName || field.fieldName
            ? [...path, field.groupName || field.fieldName]
            : path,
        ),
      ];
    } else if (field.type !== 'section') {
      allFields.push({
        key: path.join('.') + (path.length > 0 ? '.' : '') + field.fieldName,
        field,
      });
    }
  }
  return allFields;
};
export const flattenFormFields = editFields => {
  const fields = flatAllFormFields(editFields);
  return {
    fields,
    getField: (key: string) => {
      return fields.find(f => f.key === key)?.field as any;
    },
  };
};

export const convertJsonToFormData = (
  json,
  formData = new FormData(),
  parentKey = '',
) => {
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      const value = json[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        // If the value is an array, iterate over it
        value.forEach((item, index) => {
          if (
            typeof item === 'object' &&
            item !== null &&
            value !== undefined
          ) {
            // If the item is an object, recursively call the function
            convertJsonToFormData(item, formData, `${formKey}[${index}]`);
          } else {
            // Otherwise, append the item directly
            formData.append(`${formKey}[${index}]`, item);
          }
        });
      } else if (
        typeof value === 'object' &&
        value !== null &&
        value !== undefined
      ) {
        // If the value is an object, recursively call the function
        convertJsonToFormData(value, formData, formKey);
      } else {
        // Otherwise, append the value directly
        if (value !== null && value !== undefined) {
          formData.append(formKey, value);
        }
      }
    }
  }
  return formData;
};
