import { Checkbox, ListItemText, Stack } from '@mui/material';
import { ECTypography } from '../ECTypography';
import { setActiveFilter } from 'store/slice/page';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { ECMenuItem } from '../ECSelect';
import { ECTextField } from '../ECTextField';
import { ECBox } from '../ECBox';
import { ECAutocompleteStyled } from '../ECAutocomplete';
import { ECPopover } from '../ECPopover';
import { ArrowDropDown } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { ECPopper } from '../ECPopper';
import { ECChip } from '../ECChip';
import { ECIconButton } from '../ECIconButton';
import { useGetBranchTypesQuery } from 'services/branchApi';
import { ECOnlyMeFilter } from './ECOnlyMeFilter';

export const ECConceptsFilter = () => {
  const dispatch = useDispatch();

  const { activeFilter: activeFilterFromSelector } = useSelector(
    (state: RootState) => state.page.filter,
  );

  const activeFilter = useMemo(() => {
    return activeFilterFromSelector?.[window.location.href?.split('?')?.[0]];
  }, [window.location.href, activeFilterFromSelector]);

  const [anchorEl, setAnchorElConcepts] = useState<HTMLDivElement | null>(null);
  const [options, setOptions] = useState<any>([]);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const idPopover = useMemo(() => {
    return open ? 'popover' : undefined;
  }, [open]);

  const inputRef = useRef(null);

  const handleClickConcept = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>) => {
      setAnchorElConcepts(inputRef?.current);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorElConcepts(null);
    if (activeFilter?.concepts?.length === 0) {
      dispatch(
        setActiveFilter({
          concepts: options?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllConcepts: true,
        }),
      );
    }
  }, [activeFilter?.concepts, options]);

  const { data: concepts, isFetching } = useGetBranchTypesQuery({
    t: 0,
    ob: 'brnchctgry.name',
    st: 1,
  });

  useEffect(() => {
    const conceptsData = concepts?.data;
    if (conceptsData && !isFetching) {
      setOptions(prev => {
        return _.uniqBy(
          [
            ...prev,
            ...(conceptsData.map(concept => ({
              id: concept?.id,
              label: concept?.name,
              fieldName: concept?.name,
            })) || []),
          ],
          'id',
        );
      });
      if (activeFilter?.concepts?.length === 0 || !activeFilter?.concepts) {
        dispatch(
          setActiveFilter({
            concepts: conceptsData.map(concept => ({
              id: concept?.id,
              name: concept?.name,
            })),
            isSelectAllConcepts: true,
          }),
        );
      }
    }
  }, [concepts, isFetching]);

  const optionsWithAll = useMemo(() => {
    return [
      {
        label: 'all',
        fieldName: 'all',
        id: 0,
      },
      ...(options || []),
    ];
  }, [options]);

  const inputChip = useMemo(() => {
    if (
      !activeFilter?.concepts?.length ||
      activeFilter?.concepts?.length === options?.length
    ) {
      return (
        <ECChip
          sx={{ width: '48px', height: '24px' }}
          label="All"
          color="Light Grey"
        />
      );
    }

    return (
      <ECChip
        sx={{ width: '48px', height: '24px' }}
        label={activeFilter?.concepts?.length}
        color="Dark Blue"
      />
    );
  }, [activeFilter?.concepts, options]);

  const handleChangeConcepts = useCallback(
    (event, value, reason) => {
      if (value[value?.length - 1]?.id === -1) {
        return;
      }

      if (value[value?.length - 1]?.fieldName === 'all') {
        dispatch(
          setActiveFilter({
            concepts:
              activeFilter?.concepts?.length === options?.length
                ? []
                : options?.map(option => ({
                    id: option.id,
                    name: option.fieldName || option.name,
                  })),
            isSelectAllConcepts: !(
              activeFilter?.concepts?.length === options?.length
            ),
          }),
        );
        return;
      }
      dispatch(
        setActiveFilter({
          concepts: value?.map(v => ({
            id: v.id,
            name: v.fieldName || v.name,
          })),
          isSelectAllConcepts: value?.length === options?.length,
        }),
      );
    },
    [optionsWithAll, activeFilter?.concepts, options],
  );

  const handleChangeKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const renderPopper = useCallback(props => {
    return (
      <ECPopper
        {...props}
        sx={{
          transform: 'translate3d(8px, 53px, 0px) !important',
          minWidth: 'fit-content !important',
          mt: '8px !important',
          '& .MuiPopover-paper': {
            boxShadow: 'none',
          },
          '.MuiAutocomplete-listbox': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation1': {
            boxShadow: 'none',
          },
          '.MuiPaper-elevation': {
            boxShadow: 'none',
          },
        }}
      />
    );
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="200px"
      height="40px"
      border="1px solid #E0E0E0"
      borderRadius="4px"
      display="flex"
      justifyContent="space-around"
    >
      {optionsWithAll && (
        <>
          <ECTypography
            color={theme => theme.palette.text.secondary}
            variant="caption"
          >
            Concepts:
          </ECTypography>
          <ECBox
            display="flex"
            alignItems="center"
            onClick={handleClickConcept}
            ref={inputRef}
          >
            {inputChip}
            <ECIconButton
              type="default"
              size="small"
              noPadding
              sx={{ marginLeft: '5px' }}
            >
              <ArrowDropDown />
            </ECIconButton>
          </ECBox>
          <ECPopover
            id={idPopover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 35,
              horizontal: 100,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              bgcolor: 'transparent',
              '& .MuiPopover-paper': {
                minWidth: '350px',
                height: '544px',
              },
            }}
          >
            <ECBox display="flex" bgcolor="white !important" width="350px">
              <ECAutocompleteStyled
                multiple
                freeSolo
                open
                disablePortal
                sx={{
                  width: '350px',
                  '&.MuiAutocomplete-popper': {
                    transform: 'translate3d(8px, 53px, 0px) !important',
                  },
                }}
                options={optionsWithAll}
                value={activeFilter?.concepts || []}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                ListboxProps={{
                  sx: {
                    maxHeight: '470px',
                  },
                }}
                disableCloseOnSelect
                onChange={handleChangeConcepts}
                renderInput={params => (
                  <ECBox display="flex" padding={1} bgcolor="white">
                    <ECTextField
                      {...params}
                      placeholder="Search"
                      sx={{
                        minWidth: 'fit-content !important',
                        padding: '0px !important',
                        '.MuiFilledInput-root': {
                          paddingRight: '0px !important',
                        },
                      }}
                      hiddenLabel
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <></>,
                      }}
                      onKeyDown={handleChangeKeyDown}
                    />
                  </ECBox>
                )}
                renderTags={() => {
                  return '';
                }}
                PopperComponent={renderPopper}
                renderOption={(props, option, state) => {
                  if (option?.fieldName === 'all') {
                    return (
                      <ECMenuItem
                        {...props}
                        key={option?.id || option}
                        id={option?.fieldName || option}
                      >
                        <ECTypography
                          variant="body1"
                          color={theme => theme.palette.primary.main}
                        >
                          {activeFilter?.concepts?.length === options?.length
                            ? 'Deselect All'
                            : 'Select All'}
                        </ECTypography>
                      </ECMenuItem>
                    );
                  }

                  return (
                    <ECMenuItem
                      {...props}
                      data-name={
                        option?.id === optionsWithAll?.[0]?.id ? 'first' : ''
                      }
                      sx={{ width: '100%', height: '54px' }}
                      key={option?.id || option}
                      id={option?.fieldName || option}
                      disabled={option?.disabled}
                    >
                      <Checkbox
                        sx={{ p: 0, m: 0, mr: 1 }}
                        checked={state.selected}
                      />
                      <ListItemText
                        sx={{
                          '& span': {
                            maxWidth: '170px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                        primary={option?.label}
                      />
                      <ECOnlyMeFilter
                        activeFilter={{
                          concepts: [
                            {
                              id: option?.id,
                              name: option?.fieldName || option?.name,
                            },
                          ],
                          isSelectAllConcepts:
                            activeFilter?.concepts?.length === 1,
                        }}
                        closePopover={() => {
                          handleClose();
                        }}
                      />
                    </ECMenuItem>
                  );
                }}
              />
            </ECBox>
          </ECPopover>
        </>
      )}
    </Stack>
  );
};
