import { ECBox, ECButton } from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditForm2 } from 'app/components/ECDynamicPageTemplate/EditForm2';
import { Edit } from '@mui/icons-material';
import { useGetCalculationMethodsQuery } from 'services/lookupApi';
import { populateDropDownFields } from 'utils/pageUtils';
import { useUpdateWORefrigerantTrackingMutation } from 'services/workOrdersApi';
import { WorkorderRefrigerationTracking } from 'types/WorkOrders';
import moment from 'moment';
import _ from 'lodash';

export interface RTCardProps {
  woId: string;
  showEditButton?: boolean;
  initialData?: WorkorderRefrigerationTracking;
}
const formElementsOfRTDetails = require('./fancy_form_config_RT_details.json');
const formElementsOfRTEdit = require('./fancy_form_config_RT_edit.json');

export const RTCard = ({
  woId,
  showEditButton = true,
  initialData,
}: RTCardProps) => {
  const [formElementsOfRTDetailsFields, setFormElementsOfRTDetailsFields] =
    useState<any[]>(formElementsOfRTDetails?.fields);
  const [updateRTMode, setUpdateRTMode] = useState(false);
  const {
    data: calculationMethodsData,
    isSuccess: isSuccessCalculationMethodData,
  } = useGetCalculationMethodsQuery();

  useEffect(() => {
    if (isSuccessCalculationMethodData && calculationMethodsData) {
      const initialFields = formElementsOfRTEdit?.fields?.find(
        field => field.groupName === 'initialFields',
      )?.subFields;
      const finalFields = formElementsOfRTEdit?.fields?.find(
        field => field.groupName === 'finalFields',
      )?.subFields;

      populateDropDownFields({
        responseData: calculationMethodsData,
        createFormFields: initialFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'initialVerification.id',
      });
      const initialVerificationMethodField = initialFields?.find(
        field => field.fieldName === 'initialVerification.id',
      );
      if (initialVerificationMethodField) {
        initialVerificationMethodField.value =
          initialData?.initialVerification?.id;
      }

      populateDropDownFields({
        responseData: calculationMethodsData,
        createFormFields: finalFields,
        dataOptionsFieldName: 'name',
        dataValuesFieldName: 'id',
        formFieldName: 'finalVerification.id',
      });
      const finalVerificationMethodField = finalFields?.find(
        field => field.fieldName === 'finalVerification.id',
      );
      if (finalVerificationMethodField) {
        finalVerificationMethodField.value = initialData?.finalVerification?.id;
      }
    }
  }, [
    isSuccessCalculationMethodData,
    calculationMethodsData,
    formElementsOfRTEdit,
    initialData,
  ]);

  const [
    doUpdateWORefrigerantTracking,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      reset,
    },
  ] = useUpdateWORefrigerantTrackingMutation();

  const newData = useMemo(() => {
    const refrigerantUsedOnWorkOrder = 1;
    return {
      ...initialData,
      refrigerantUsedOnWorkOrder,
    };
  }, [initialData]);

  useEffect(() => {
    const initialFields = formElementsOfRTEdit?.fields?.find(
      field => field.groupName === 'initialFields',
    )?.subFields;
    const finalFields = formElementsOfRTEdit?.fields?.find(
      field => field.groupName === 'finalFields',
    )?.subFields;
    const initialVerificationDateField = initialFields?.find(
      field => field.fieldName === 'initialVerification.date',
    );
    const today = moment().format('YYYY-MM-DD');
    if (initialVerificationDateField) {
      initialVerificationDateField.minDate = today;
    }
    const finalVerificationDateField = finalFields?.find(
      field => field.fieldName === 'finalVerification.date',
    );
    if (finalVerificationDateField) {
      finalVerificationDateField.minDate = today;
    }
  }, [initialData, formElementsOfRTEdit]);

  useEffect(() => {
    const circutListField = formElementsOfRTDetailsFields.find(
      field => field.fieldName === 'circuits',
    );
    if (circutListField) {
      circutListField.circuits = initialData?.circuits;
      circutListField.editMode = false;
    }
    setFormElementsOfRTDetailsFields([...formElementsOfRTDetailsFields]);
  }, [initialData, updateRTMode, formElementsOfRTDetails, isUpdateSuccess]);

  useEffect(() => {
    if (updateRTMode) {
      const circuitListField = formElementsOfRTEdit?.fields?.find(
        field => field.fieldName === 'circuits',
      );

      if (circuitListField) {
        circuitListField.circuits = initialData?.circuits?.map(circuit => {
          const match = circuit?.charge?.match(/(\d+)\s*lbs\s*(\d+)\s*oz/);
          const chargelbs = match?.[1] ? parseInt(match[1], 10) : '';
          const chargeoz = match?.[2] ? parseInt(match[2], 10) : '';

          return {
            ...circuit,
            repairOnThisCircuit: !!circuit.repaired,
            chargelbs,
            chargeoz,
          };
        });

        circuitListField.editMode = true;
      }
    }
  }, [updateRTMode, initialData, formElementsOfRTEdit, isUpdateSuccess]);

  const useUpdate = useCallback(() => {
    const doUpdate = async data => {
      const dataCircuits = data?.circuits
        ?.filter(circuit => circuit?.repairOnThisCircuit)
        .map(({ id, chargelbs, chargeoz }) => ({
          id,
          ...(!_.isNil(chargelbs) && { chargePounds: Number(chargelbs) }),
          ...(!_.isNil(chargeoz) && { chargeOunces: Number(chargeoz) }),
          repaired: 1,
        }));
      delete data?.refrigerantUsedOnWorkOrder;

      doUpdateWORefrigerantTracking({
        ...data,
        id: woId,
        circuits: dataCircuits,
      });
    };

    return [
      doUpdate,
      {
        isError: isUpdateError,
        error: updateError,
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        reset,
      },
    ];
  }, [
    initialData,
    isUpdateError,
    updateError,
    isUpdateLoading,
    isUpdateSuccess,
    reset,
    doUpdateWORefrigerantTracking,
  ]);

  return (
    <ECBox p={1} position="relative" sx={{ marginTop: '10px' }}>
      <ECBox display="flex" justifyContent="space-between">
        <EditForm2
          row={newData}
          showTitle={false}
          detailsConfig={formElementsOfRTDetails.config}
          detailsFields={formElementsOfRTDetailsFields}
          formConfig={formElementsOfRTEdit.config}
          formFields={formElementsOfRTEdit.fields}
          hideToolBar
          checkEqualFields={false}
          showEditButton={false}
          onlyEdit={updateRTMode}
          onClose={() => setUpdateRTMode(false)}
          useUpdateMutation={useUpdate}
        />
      </ECBox>
      {showEditButton && (
        <ECBox position="absolute" top={0} right={0} zIndex={1}>
          <ECButton
            variant="contained"
            startIcon={<Edit />}
            onClick={() => setUpdateRTMode(true)}
            disabled={updateRTMode}
            sx={{
              marginRight: 2,
              height: '42px',
              width: '300px',
              marginTop: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            Update Refrigerant Details
          </ECButton>
        </ECBox>
      )}
    </ECBox>
  );
};
