import {
  ECButton,
  ECEasyFormCreate,
  ECEasyFormFieldType,
  ECPaper,
} from 'app/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { P } from 'types/Permission';
import { ECDrawerDetails } from 'app/components/ECDrawerDetails';
import { useUpdateAssetCircuitsMutation } from 'services/assetApi';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'store/slice/page';

interface OnHoldModalButtonProps {
  assetId: number;
  branchId: number;
  assetTypeId;
  onSave?: () => void;
  disabled?: boolean;
  value?: any;
}

export function EditRTDrawerButton({
  onSave,
  disabled,
  assetId,
  branchId,
  assetTypeId,
  value,
}: OnHoldModalButtonProps) {
  const dispatch = useDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fields = useMemo(() => {
    return [
      {
        type: 'refrigerant_tracking',
        label: 'Refrigerant Tracking',
        fieldName: 'refrigerantTracking',
        value: 1,
        defaultValue: value,
        trackingSPParams: {
          dontShowCheckBoxAndAlert: true,
          branchId,
          assetTypeId,
        },
      },
    ];
  }, [branchId, assetTypeId, value]);

  const handleOpenDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const [
    doUpdateCircuit,
    {
      isLoading: isLoadingUpdateCircuit,
      isSuccess: isSuccessUpdateCircuit,
      isError: isErrorUpdateCircuit,
      error: errorUpdateCircuit,
      data: updatedCircuitData,
      reset: resetUpdateCircuit,
    },
  ] = useUpdateAssetCircuitsMutation();

  const useUpdateCircuit = useCallback(() => {
    const doUpdate = async data => {
      const { epaApplianceType, circuits, initialChargeServiceProvider } =
        data.refrigerantTracking;

      doUpdateCircuit({
        assetId: assetId,
        refrigerantEPAId: epaApplianceType?.id,
        refrigerantInitalSpId: initialChargeServiceProvider?.id,
        // deleteds are the difference between the original circuits and the new circuits
        deletes: value.circuits
          ?.filter(circuit => !circuits?.some(c => c.id === circuit.id))
          ?.map(circuit => circuit.id),
        changes: circuits?.map(circuit => ({
          id: circuit.id || undefined,
          name: circuit.circuitName,
          chargePounds: Number(circuit.chargelbs),
          chargeOunces: Number(circuit.chargeoz),
          refrigerantTypeId: circuit.refrigerantType?.id,
          refrigerantMethodId: circuit.method?.id,
          annualLeakRate: 1,
        })),
      });
    };
    return [
      doUpdate,
      {
        data: updatedCircuitData,
        isError: isErrorUpdateCircuit,
        error: errorUpdateCircuit,
        isLoading: isLoadingUpdateCircuit,
        isSuccess: isSuccessUpdateCircuit,
        reset: resetUpdateCircuit,
      },
    ];
  }, [
    assetId,
    doUpdateCircuit,
    updatedCircuitData,
    isErrorUpdateCircuit,
    errorUpdateCircuit,
    isLoadingUpdateCircuit,
    isSuccessUpdateCircuit,
    resetUpdateCircuit,
    value.circuits,
  ]);

  useEffect(() => {
    if (isSuccessUpdateCircuit) {
      dispatch(
        setSnackbar({
          severity: 'success',
          message: 'Succesfully Updated',
        }),
      );
    }
  }, [isSuccessUpdateCircuit]);

  return (
    <>
      <ECButton
        variant="outlined"
        startIcon={<Edit />}
        scopes={[P.EditBranch]}
        onClick={handleOpenDrawer}
        //isLoading={isLoadingUpdateOnHold}
        disabled={disabled}
        sx={{
          marginRight: 1,
          height: '42px',
        }}
      >
        Edit Refrigerant Details
      </ECButton>
      {isDrawerOpen && (
        <ECDrawerDetails
          open={isDrawerOpen}
          anchor="right"
          onClose={handleCloseDrawer}
        >
          <ECPaper
            sx={{ height: '100%', paddingTop: '80px' }}
            role="presentation"
          >
            <ECEasyFormCreate
              useCreateMutation={useUpdateCircuit}
              showSuccessSnackBar={false}
              formConfig={{
                variant: 'simple',
                title: 'Edit Refrigerant Details',
                cols: 1,
                submitTitle: 'Save',
              }}
              formFields={fields as ECEasyFormFieldType[]}
              onClose={handleCloseDrawer}
              noTopMargin
            />
          </ECPaper>
        </ECDrawerDetails>
      )}
    </>
  );
}
